import React from "react";
import { NavLink } from "react-router-dom";
const AdminNavItem = ({ data }) => {
  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#7efff5" : "#ffffff",
      borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };

  return (
    <>
      {data && (
        <div className="w-full mb-4">
          <div className="w-full flex items-center justify-start mb-3">
            <span className="block text-lg md:hidden lg:block text-slate-400">
              {data.heading}
            </span>
          </div>
          {/* for sub menu */}
          <div className={`mt-2  whitespace-pre`}>
            {data?.subNav?.map((item, i) => {
              return (
                <li
                  className="list-none ml-2 mb-2 group transition text-white hover:text-[#7efff5]"
                  key={i}
                >
                  <NavLink
                    style={activeNavLink}
                    to={item?.link}
                    className="py-1 flex gap-2 md:justify-center lg:justify-start items-center"
                  >
                    <span className="p-1 border border-transparent rounded-full transition-all duration-[0.5s] group-hover:text-[#7efff5] group-hover:rounded-full group-hover:border-[#7efff5]">
                      <item.Icon />
                    </span>
                    <span className="block text-[0.9rem] md:hidden lg:block group-hover:text-[#7efff5]">
                      {item.title}
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default AdminNavItem;
