import React from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";

const EducationListField = ({ inputs, setInputs }) => {
  const handleChange = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index][e.target.name] = e.target.value;
    setInputs(newInputs);
  };
  const addInput = () => {
    setInputs([...inputs, { educationlavel: "", result: "" }]);
  };
  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-y-4">
        {inputs.map((input, index) => (
          <div
            className="flex flex-col gap-y-4 md:flex-row  w-full gap-x-4"
            key={index}
          >
            <div className="w-full text-sm  px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300">
              <select
                name="educationlavel"
                value={input.educationlavel}
                onChange={(e) => handleChange(e, index)}
                className="w-full outline-none"
              >
                <option value="">Education Lavel</option>
                <option value="SSC">SSC</option>
                <option value="HSC">HSC</option>
                <option value="Diploma">Diploma</option>
                <option value="BSC/Honors">BSC/Honors</option>
                <option value="Masters">Masters</option>
                <option value="PhD/Doctor Of Philosophy">
                  PhD/Doctor Of Philosophy
                </option>
              </select>
            </div>
            <input
              type="number"
              placeholder="Result"
              name="result"
              min={0}
              step="any"
              value={input.result}
              onChange={(e) => handleChange(e, index)}
              className="w-full outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300"
            />
            <div className="p-2 border w-fit rounded-full bg-gray-200 cursor-pointer">
              <AiOutlineDelete
                size={23}
                className="text-center flex justify-center items-center text-red-500 "
                onClick={() => removeInput(index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex gap-x-4 mt-2">
        <div className="p-1 border w-fit rounded-full bg-gray-200 cursor-pointer">
          <AiOutlinePlus
            size={25}
            className="text-center flex justify-center items-center text-green-500 "
            onClick={addInput}
          />
        </div>
        {/* <input
          className=" text-center flex justify-center items-center text-white px-4 border hover:border-primaryColor rounded-full transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
          type="button"
          onClick={handleSubmit}
          value="Confirm"
        /> */}
      </div>
    </div>
  );
};

export default EducationListField;
