export const aboutDropDownData = [
  {
    itemname: "Web and Mobile App Development",
    itemlink: "/services#Web_and_Mobile_App_Development",
    itemlinksec: "#Web_and_Mobile_App_Development",
    // itemId: "/#webmobile",
  },
  {
    itemname: "Business Management Solution",
    itemlink: "/services#Business_Management_Solution",
    itemlinksec: "#Business_Management_Solution",
  },
  {
    itemname: "Digital Marketing Solution",
    itemlink: "/services#Digital_Marketing_Solution",
    itemlinksec: "#Digital_Marketing_Solution",
  },
  {
    itemname: "E-commerce Solution",
    itemlink: "/services#E_commerce_Solution",
    itemlinksec: "#E_commerce_Solution",
  },
  {
    itemname: "Affiliate Marketing Solution",
    itemlink: "/services#Affiliate_Marketing_Solution",
    itemlinksec: "#Affiliate_Marketing_Solution",
  },
  {
    itemname: "Ad Network Solution",
    itemlink: "/services#Ad_Network_Solution",
    itemlinksec: "#Ad_Network_Solution",
  },
];
