import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};

export const toggleSlice = createSlice({
  name: "toggleSlice",
  initialState,
  reducers: {
    buttonToggle: (state) => {
      state.show = !state.show;
    },
    showExamSlotForm: (state) => {
      state.show = true;
    },
    hideExamSlotForm: (state) => {
      state.show = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { buttonToggle, showExamSlotForm, hideExamSlotForm } =
  toggleSlice.actions;

export default toggleSlice.reducer;
