import * as Yup from "yup";
const urlRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const phoneRegex = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
export const createJobPostSchema = Yup.object({
  jobpostname: Yup.string().required("Please enter job post name"),
});

export const createBrandSchema = Yup.object({
  brandname: Yup.string().required("Please enter brand name"),
  homeurl: Yup.string()
    .matches(urlRegex, "Invalid Home Url")
    .required("Please enter your home url"),
  photourl: Yup.string().matches(urlRegex, "Invalid Photo Url"),
});

export const createContactSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  phone: Yup.string()
    .matches(phoneRegex, "Invalid Phone Number")
    .required("Please enter your phone number"),
  email: Yup.string().email().required("Please enter your email"),
  description: Yup.string().required("Please enter your description"),
});

export const createTeamSchema = Yup.object({
  name: Yup.string().required("Please enter team member name"),
  designation: Yup.string().required("Please enter team member designation"),
  photo: Yup.string().required("Please select team member photo"),
});

export const applyJobSchema = Yup.object({
  name: Yup.string().required("Enter your name"),
  email: Yup.string().email().required("Enter your email"),
  phone: Yup.string()
    .matches(phoneRegex, "Invalid Phone Number")
    .required("Enter your phone number"),
  gender: Yup.string().required("Enter your gender"),
  age: Yup.number().required("Enter your age"),
  skills: Yup.string().required("Enter your skills"),
  reasonOfJoin: Yup.string().required("Enter the reason of join with us"),
  reasonOfChoosingYou: Yup.string().required(
    "Enter the things that sets you apart from other"
  ),
});

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Email is Required"),
  password: Yup.string().required("Password is Required"),
});

export const examSlotBookFormValidation = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  description: Yup.string().required("Description is required"),
});
export const submitTaskSchema = Yup.object({
  submitionLink: Yup.string().required("Github Link is Required!"),
});
export const updateProfileSchema = Yup.object({
  phone: Yup.string().required("Phone Number is Required!"),
  password: Yup.string().required("Password is Required!"),
});
