import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../features/api/apiSlice";
import authReducer from "../features/auth/authSlice";
import calenderReducer from "../features/calender/calenderSlice";
import menuReducer from "../features/menu/menuSlice";
import modalReducer from "../features/modal/modalSlice";
import timerReducer from "../features/timer/timerSlice";
import toggleReducer from "../features/toggle/toggleSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    timer: timerReducer,
    menu: menuReducer,
    modal: modalReducer,
    auth: authReducer,
    calender: calenderReducer,
    toggle: toggleReducer,
  },
  middleware: (gDM) => gDM().concat(apiSlice.middleware),
  // devTools: false,
});
setupListeners(store.dispatch);
