import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsImage } from "react-icons/bs";
import { MdOutlineEditNote } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import image_placeholder from "../../Assets/images/image_placeholder.png";
import DatePICK from "../../Components/Admin/DatePICK";
import Radiobutton from "../../Components/Admin/jobposts/Radiobutton";
import SalaryRadio from "../../Components/Admin/jobposts/SalaryRadio";
import { updateJobpostAPI } from "../../api/jobpostAPI";

const UpdateJobPosts = () => {
  const { access_token: token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [base64, setBase64] = useState("");
  const { state } = useLocation();
  const jobcategories = [
    { category: "Business Development", subcategory: ["Business Development"] },
    {
      category: "Tech Department",
      subcategory: [
        "Web Development",
        "App Development",
        "UI UX",
        "DevOps",
        "Data Analytics",
      ],
    },
    {
      category: "Marketing Department",
      subcategory: [
        "Design",
        "Branding",
        "Sales",
        "Social Media",
        "Digital Marketing",
        "Content",
      ],
    },
  ];
  //   converting image url to base64
  useEffect(() => {
    const convertedBase64Imge = async () => {
      const response = await fetch(state?.photo?.url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
      };
      reader.readAsDataURL(blob);
    };
    convertedBase64Imge();
  }, []);

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPhoto(reader.result);
      };
      reader.onerror = (error) => {
        console.log(error);
      };
    }
  };
  const [check, setCheck] = useState(
    state?.vaccency === "NA" ? "NA" : "Number"
  );
  const [checkSalary, setCheckSalary] = useState(
    state?.salary === "Negotiable" ? "Negotiable" : "Number"
  );
  const [jobcategory, setJobCategory] = useState(state?.jobcategory);
  const [jobsubcategory, setJobSubCategory] = useState(state?.jobsubcategory);
  const [jobpostname, setJobpostname] = useState(state?._id);
  const [jobdescription, setJobdescription] = useState(state?.jobdescription);
  const [jobqualification, setJobqualification] = useState(
    state?.jobqualification
  );
  const [startDate, setStartDate] = useState(new Date(state?.Deadline));
  const [photo, setPhoto] = useState(state?.photo?.url);
  const [employeestatus, setEmployeeStatus] = useState(state?.employeestatus);
  const [vaccency, setVaccency] = useState(
    state?.vaccency === "NA" ? "NA" : Number(state?.vaccency)
  );
  const [salary, setSalary] = useState(
    state?.salary === "Negotiable" ? "Negotiable" : Number(state?.salary)
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !jobpostname ||
      !jobdescription ||
      !jobqualification ||
      !startDate ||
      !jobcategory ||
      !jobsubcategory ||
      !photo ||
      !employeestatus ||
      !vaccency ||
      !salary
    ) {
      toast.error("Please fill the form");
    } else {
      const res = await updateJobpostAPI(
        state?.jobId,
        {
          jobpostname,
          jobdescription,
          jobqualification,
          jobcategory,
          jobsubcategory,
          deadline: startDate,
          photo,
          employeestatus,
          vaccency,
          salary,
          base64,
        },
        token
      ); // api call
      if (res && res.status === 200) {
        toast.success(res.data.message);
        navigate("/admin/job-list");
      }
    }
  };
  return (
    <div className="md:container w-full flex flex-col bg-white rounded-lg py-4 justify-center items-center gap-6 ">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <MdOutlineEditNote /> Update Job Post
        </h1>
      </div>
      <div className="container w-full mx-auto">
        <form
          onSubmit={handleSubmit}
          className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 gap-6"
        >
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Job Post Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Job Post Name"
              onChange={(e) => setJobpostname(e.target.value)}
              value={jobpostname}
              className="w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border "
            />
          </div>
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Job Category <span className="text-red-600">*</span>
            </label>
            <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border">
              <select
                name="jobcategory"
                onChange={(e) => {
                  setJobCategory(e.target.value);
                  // setJobSubCategory(jobcategories[e.target.value])
                }}
                value={jobcategory}
                className="w-full outline-none "
              >
                {jobcategories &&
                  jobcategories?.map((item, index) => (
                    <option key={index} value={item?.category}>
                      {item?.category}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {jobcategory === "Business Development" && (
            <div className="w-full mx-auto md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                Job Sub Category <span className="text-red-600">*</span>
              </label>
              <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border ">
                <select
                  name="jobsubcategory"
                  onChange={(e) => setJobSubCategory(e.target.value)}
                  value={jobsubcategory}
                  className="w-full outline-none "
                >
                  {jobcategories[0]?.subcategory &&
                    jobcategories[0]?.subcategory?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          {jobcategory === "Tech Department" && (
            <div className="w-full mx-auto md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                Job Sub Category <span className="text-red-600">*</span>
              </label>
              <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border ">
                <select
                  name="jobsubcategory"
                  onChange={(e) => setJobSubCategory(e.target.value)}
                  value={jobsubcategory}
                  className="w-full outline-none "
                >
                  {jobcategories[1]?.subcategory &&
                    jobcategories[1]?.subcategory?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          {jobcategory === "Marketing Department" && (
            <div className="w-full mx-auto md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                Job Sub Category <span className="text-red-600">*</span>
              </label>
              <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border">
                <select
                  name="jobsubcategory"
                  onChange={(e) => setJobSubCategory(e.target.value)}
                  value={jobsubcategory}
                  className="w-full outline-none "
                >
                  {jobcategories[2]?.subcategory &&
                    jobcategories[2]?.subcategory?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Deadline Date <span className="text-red-600">*</span>
            </label>
            <DatePICK
              startDate={startDate}
              setStartDate={setStartDate}
              styleClass={
                "w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border cursor-pointer"
              }
            />
          </div>
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Vacancies <span className="text-red-600">*</span>
            </label>
            <div className="w-full flex gap-x-2 outline-none px-[0.8rem] py-[0.5rem] bg-white rounded-md border">
              <Radiobutton
                check={check}
                setCheck={setCheck}
                setVaccency={setVaccency}
              />
              {check === "Number" && (
                <input
                  value={vaccency}
                  className="w-[20%] outline-none border border-primaryColor rounded-sm"
                  onChange={(e) => setVaccency(e.target.value)}
                  type="number"
                />
              )}
            </div>
          </div>
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Salary <span className="text-red-600">*</span>
            </label>
            <div className="w-full flex gap-x-2 outline-none px-[0.8rem] py-[0.5rem] bg-white rounded-md border">
              <SalaryRadio
                check={checkSalary}
                setCheck={setCheckSalary}
                setSalary={setSalary}
              />
              {checkSalary === "Number" && (
                <input
                  value={salary}
                  className="w-[20%] outline-none border border-primaryColor rounded-sm"
                  onChange={(e) => setSalary(e.target.value)}
                  type="number"
                />
              )}
            </div>
          </div>
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Job Post Description <span className="text-red-600">*</span>
            </label>
            <ReactQuill
              theme="snow"
              className="w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border bg-white"
              onChange={(e) => setJobdescription(e)}
              value={jobdescription}
            />
          </div>
          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Job Qualification<span className="text-red-600">*</span>
            </label>
            <ReactQuill
              theme="snow"
              className="w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border bg-white"
              onChange={(e) => setJobqualification(e)}
              value={jobqualification}
            />
          </div>
          <div className="mx-auto w-full md:mx-0 ">
            <label className="text-[1rem] font-medium text-gray-700 ">
              Job Post Photo <span className="text-red-600">*</span>
            </label>
            <div
              className="text-gray-900
placeholder-transparent rounded-md
 placeholder:text-3xl flex items-center gap-x-4"
            >
              <label
                className="w-full bg-white md:w-[80%] border text-sm whitespace-nowrap outline-none px-[0.8rem] py-[0.5rem] rounded-md border-gray-300"
                htmlFor="photo"
              >
                <BsImage className="text-secondary text-lg inline-block mr-1 text-prborder-primaryColor" />
                Choose Image
              </label>
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  convertToBase64(e.target.files[0]);
                }}
                id="photo"
              />
              <div className="">
                <img
                  className="w-10 h-10 rounded-full object-contain"
                  src={photo || image_placeholder}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="w-full mx-auto md:mx-0">
            <label className="text-[1rem] font-medium" htmlFor="">
              Employee Status <span className="text-red-600">*</span>
            </label>
            <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border">
              <select
                name="employeestatus"
                onChange={(e) => setEmployeeStatus(e.target.value)}
                value={employeestatus}
                className="w-full outline-none "
              >
                <option value="">Choose Employee Status</option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
                <option value="Internship">Internship</option>
                <option value="Paid Internship">Paid Internship</option>
              </select>
            </div>
          </div>

          <div className="w-full mx-auto md:mx-0 flex justify-start items-end">
            <button
              type="submit"
              className="w-[30%] text-center flex justify-center items-center text-white px-[0.8rem] py-[0.5rem] border hover:border-primaryColor rounded-md transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateJobPosts;
