import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsListCheck, BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Error from "../../error/Error";
import {
  useDeleteBrandMutation,
  useGetAllBrandsQuery,
} from "../../features/brand/brandApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const AllBrands = () => {
  const { access_token } = useSelector((state) => state.auth);
  const {
    isLoading: isLoadingBrand,
    isSuccess: isSuccessBrand,
    isError: isErrorBrand,
    data: dataBrand,
  } = useGetAllBrandsQuery({ access_token }) || {};
  const [
    deleteBrand,
    { isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteBrandMutation() || {};
  let content = null;
  if (isLoadingBrand && !isErrorBrand) {
    content = (
      <>
        <tbody>
          <tr>
            <td colSpan={3}>Loading..</td>
          </tr>
        </tbody>
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length !== 0) {
    content = (
      <>
        <tbody>
          {dataBrand?.brands?.map((item, index) => (
            <tr key={index} className="border-t border-gray-300">
              <td className="text-sm min-w-[200px] w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div className="flex items-center gap-x-3">
                  <img
                    src={item?.photourl ? item?.photourl : item?.photo?.url}
                    alt=""
                    className="w-10 h-10 rounded-full object-contain"
                  />

                  <p className="text-sm text-primaryColor font-semibold">
                    {item.brandname}
                  </p>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                {item.homeurl}
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div className="flex items-center gap-x-2 text-xl">
                  <BsTrash
                    title="Delete"
                    onClick={() => handleDelete(item._id)}
                    className="text-red-400 cursor-pointer"
                    size={16}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    );
  }
  if (isErrorBrand && !isSuccessBrand) {
    content = (
      <>
        <tbody>
          <tr>
            <td colSpan={3}>
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length === 0) {
    content = (
      <>
        <tbody>
          <tr>
            <td colSpan={3}>
              <NoDataFound />
            </td>
          </tr>
        </tbody>
      </>
    );
  }
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteBrand({ access_token, id });
      }
    });
  };

  // success message
  useEffect(() => {
    if (isSuccessDelete && !isErrorDelete) {
      toast.success("Brand Deleted Successfully!");
    }
  }, [isSuccessDelete, isErrorDelete]);

  // error message
  useEffect(() => {
    if (isErrorDelete && !isSuccessDelete) {
      toast.error(errorDelete?.data?.message || "Brand Deletion Failed!");
    }
  }, [isSuccessDelete, isErrorDelete, errorDelete?.data?.message]);
  return (
    <div className="container w-full mx-auto bg-white py-4 rounded-lg">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2  items-center">
          <BsListCheck className="-mb-1" /> Brands
        </h1>
      </div>
      <div className="md:container flex flex-col gap-y-2">
        <div className={` flex flex-col gap-4 mt-4 `}>
          <div className="w-full h-full overflow-x-auto ">
            <table
              className="auto whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
            >
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Brand Name
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Home Url
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Action
                  </th>
                </tr>
              </thead>
              {content}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBrands;
