import React from "react";
import { HiArrowRightOnRectangle } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import masterkeylogo from "../../Assets/images/masterkeylogo1.jpg";
import { adminNavbar } from "../../Data/data";
import { userLoggedOut } from "../../features/auth/authSlice";
import AdminNavItem from "./AdminNavItem";
const AdminNavbar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLoggedOut());
    localStorage.removeItem("auth");
  };

  return (
    <div className="h-full flex flex-col gap-y-4 items-center justify-start ">
      <div className="w-full flex gap-6 items-center justify-center transition group ">
        <NavLink
          to="/"
          className="flex gap-2 items-center ring ring-secondary rounded-full"
        >
          <img
            className="w-24 h-24 bg-white object-cover rounded-full"
            src={masterkeylogo}
            alt="Logo"
          />
        </NavLink>
      </div>
      <p className="text-white ">Masterkey Technologies Ltd.</p>
      <p className="text-sm -mt-5 text-gray-400">career@masterkey.tech</p>
      {adminNavbar?.map((item, i) => (
        <AdminNavItem data={item} key={i} />
      ))}
      <div
        onClick={handleLogout}
        className="w-full cursor-pointer flex gap-2 pb-4 justify-start md:justify-center lg:justify-start  items-center transition group text-white"
      >
        Logout <HiArrowRightOnRectangle />
      </div>
    </div>
  );
};

export default AdminNavbar;
