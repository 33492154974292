import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import bgImg from "../Assets/images/4.png";
import JoinUsBanner from "../Components/Banner/JoinUsBanner";
import Footer from "../Components/Footer/Footer";
import AllJobs from "../Components/JobPosts/AllJobs";
import Helmet from "react-helmet";
const JoinUsPage = () => {
  return (
    <>
      <Helmet>
        <title>Join Us - Masterkey Technologies Ltd.</title>
      </Helmet>
      <div
        className=" pb-28"
        style={{
          background: `url(${bgImg})`,
          backgroundSize: "cover",
        }}
      >
        <NavBar />
        <JoinUsBanner />
      </div>
      <div className="w-full" id={"join_us"}>
        <AllJobs />
      </div>

      <Footer />
    </>
  );
};

export default JoinUsPage;
