import moment from "moment";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BsGlobeAmericas } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useGetExamTimeSlotCandidateQuery } from "../../features/exam/examTimeSlotApiSlice";
import Calendar from "../Calender/Calender";
import ExamSlotBookForm from "../ExamSlotBook/ExamSlotBookForm";
import EmptyTimeSlot from "./EmptyTimeSlot";
const ExamTimeSlot = ({ jobId, jobpostname }) => {
  const { show } = useSelector((state) => state.toggle);
  const { access_token, user } = useSelector((state) => state.auth);
  const { duration } = useSelector((state) => state.calender);
  const response =
    useGetExamTimeSlotCandidateQuery(
      { access_token, jobId },
      { skip: !jobId }
    ) || {};

  const { data: dataTimeSlot } = response;

  return (
    <>
      {dataTimeSlot?.slot?.length !== 0 ? (
        <div className="w-full md:w-[90%] mx-auto rounded-sm border mb-4">
          <div className="w-full flex justify-between p-4">
            <div className="space-y-1">
              <h1 className="text-sm md:text-base font-semibold text-gray-500 flex items-center gap-1">
                <AiOutlineUser className="text-lg" /> {user?.name}
              </h1>
              <h4 className="text-sm md:text-lg flex items-center gap-1 font-medium text-gray-400">
                <IoTimeOutline />
                Exam Duration:{" "}
                <span className="text-blue-400">{duration} </span>min
              </h4>
            </div>
            <div className="space-y-1">
              <h1 className="text-xs md:text-base font-semibold text-gray-500">
                Time Zone
              </h1>
              <h4 className="text-xs md:text-base flex items-center gap-1 font-semibold text-gray-400">
                <BsGlobeAmericas /> Asia/Dhaka
              </h4>
            </div>
          </div>
          <div>
            <div className="w-full bg-gray-500 flex justify-between p-4">
              <h1 className="text-sm md:text-xl font-semibold text-gray-50">
                Select Date and Time
              </h1>

              <h1 className="text-xs md:text-base font-semibold text-gray-50">
                {moment().format("LL")}
              </h1>
            </div>
            {!show && <Calendar response={response} />}
            {show && <ExamSlotBookForm />}
          </div>
        </div>
      ) : (
        <EmptyTimeSlot jobpostname={jobpostname} />
      )}
    </>
  );
};

export default ExamTimeSlot;
