import React from "react";
import { BiTask } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa";
import { MdFilterList, MdOutlinePendingActions } from "react-icons/md";
import Error from "../../error/Error";
const ApplicationStepper = ({ response }) => {
  const {
    data: dataStatus,
    isSuccess: isSuccessStatus,
    isLoading: isLoadingStatus,
    isError: isErrorStatus,
  } = response;

  let content = null;
  // handle loading case
  if (isLoadingStatus && !isSuccessStatus) {
    content = (
      <>
        <ol className="flex flex-row items-center w-full h-0.5 justify-between bg-gray-100 animate-pulse my-6 md:my-10">
          <li
            className={`flex items-center justify-center bg-gray-200 animate-pulse rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <span className="w-8 h-6 bg-gray-200 animate-pulse"></span>
              <span className="w-16 h-2 bg-gray-200 animate-pulse"></span>
            </span>
          </li>
          <li
            className={`flex items-center justify-center bg-gray-200 animate-pulse rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <span className="w-8 h-6 bg-gray-200 animate-pulse"></span>
              <span className="w-16 h-2 bg-gray-200 animate-pulse"></span>
            </span>
          </li>
          <li
            className={`flex items-center justify-center bg-gray-200 animate-pulse rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <span className="w-8 h-6 bg-gray-200 animate-pulse"></span>
              <span className="w-16 h-2 bg-gray-200 animate-pulse"></span>
            </span>
          </li>
          <li
            className={`flex items-center justify-center bg-gray-200 animate-pulse rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <span className="w-8 h-6 bg-gray-200 animate-pulse"></span>
              <span className="w-16 h-2 bg-gray-200 animate-pulse"></span>
            </span>
          </li>
        </ol>
      </>
    );
  }
  // handle success case
  if (!isLoadingStatus && isSuccessStatus && !isErrorStatus) {
    content = (
      <>
        <ol className="flex flex-row items-center w-full h-0.5 justify-between bg-gray-100 my-6 md:my-10">
          <li
            className={`flex items-center justify-center ${
              dataStatus?.jobDetails[0]?.cvShortList ||
              dataStatus?.taskSubmited ||
              dataStatus?.jobDetails[0]?.interviewCall ||
              dataStatus?.jobDetails?.length !== 0
                ? "bg-green-500"
                : "bg-red-100"
            } rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            {dataStatus?.jobDetails[0]?.cvShortList ||
            dataStatus?.taskSubmited ||
            dataStatus?.jobDetails[0]?.interviewCall ||
            dataStatus?.jobDetails?.length !== 0 ? (
              <svg
                aria-hidden="true"
                className={`w-3 h-3 ${
                  dataStatus?.jobDetails[0]?.cvShortList ||
                  dataStatus?.taskSubmited ||
                  dataStatus?.jobDetails[0]?.interviewCall ||
                  dataStatus?.jobDetails?.length !== 0
                    ? "text-white"
                    : "text-gray-600"
                } md:w-4 md:h-4`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <span
                className={`font-medium bg-secondary w-2 h-2 rounded-full`}
              ></span>
            )}
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <MdOutlinePendingActions className="text-lg md:text-2xl text-gray-600" />
              <p className="text-[10px] md:text-xs text-gray-500 font-medium whitespace-pre">
                Applied
              </p>
            </span>
          </li>
          <li
            className={`flex items-center justify-center ${
              dataStatus?.jobDetails[0]?.cvShortList ||
              dataStatus?.taskSubmited ||
              dataStatus?.jobDetails[0]?.interviewCall
                ? "bg-green-500"
                : "bg-red-100"
            } rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0  border-4 border-white relative`}
          >
            {dataStatus?.jobDetails[0]?.cvShortList ||
            dataStatus?.taskSubmited ||
            dataStatus?.jobDetails[0]?.interviewCall ? (
              <svg
                aria-hidden="true"
                className={`w-3 h-3 ${
                  dataStatus?.jobDetails[0]?.cvShortList ||
                  dataStatus?.taskSubmited ||
                  dataStatus?.jobDetails[0]?.interviewCall
                    ? "text-white"
                    : "text-gray-600"
                } md:w-4 md:h-4 `}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <span
                className={`font-medium bg-secondary w-2 h-2 rounded-full`}
              ></span>
            )}
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <MdFilterList className="text-lg md:text-2xl text-gray-600" />
              <p className="text-[10px] md:text-xs text-gray-500 font-medium whitespace-pre">
                Shortlisted
              </p>
            </span>
          </li>
          <li
            className={`flex items-center justify-center ${
              dataStatus?.taskSubmited ||
              dataStatus?.jobDetails[0]?.interviewCall
                ? "bg-green-500"
                : "bg-red-100"
            } rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0 border-4 border-white relative`}
          >
            {dataStatus?.taskSubmited ||
            dataStatus?.jobDetails[0]?.interviewCall ? (
              <svg
                aria-hidden="true"
                className={`w-3 h-3 ${
                  dataStatus?.taskSubmited ||
                  dataStatus?.jobDetails[0]?.interviewCall
                    ? "text-white"
                    : "text-gray-600"
                } md:w-4 md:h-4 `}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <span
                className={`font-medium bg-secondary w-2 h-2 rounded-full`}
              ></span>
            )}
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <BiTask className="text-lg md:text-2xl text-gray-600" />
              <p className="text-[10px] md:text-xs text-gray-500 font-medium  whitespace-pre">
                Assesment
              </p>
            </span>
          </li>
          <li
            className={`flex items-center justify-center ${
              dataStatus?.jobDetails[0]?.interviewCall
                ? "bg-green-500"
                : "bg-red-100"
            } rounded-full w-8 md:w-10 h-8 md:h-10 shrink-0 border-4 border-white relative`}
          >
            {dataStatus?.jobDetails[0]?.interviewCall ? (
              <svg
                aria-hidden="true"
                className={`w-3 h-3 ${
                  dataStatus?.jobDetails[0]?.interviewCall
                    ? "text-white"
                    : "text-gray-600"
                } md:w-4 md:h-4 `}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <span
                className={`font-medium bg-secondary w-2 h-2 rounded-full`}
              ></span>
            )}
            <span className="absolute top-[130%] flex flex-col items-center gap-2 bg-white">
              <FaUserTie className="md:text-2xl text-gray-600" />
              <p className="text-[10px] md:text-xs text-gray-500 font-medium  whitespace-pre">
                Viva
              </p>
            </span>
          </li>
        </ol>
      </>
    );
  }
  // // handle error case
  if (!isLoadingStatus && isErrorStatus) {
    content = (
      <>
        <Error />
      </>
    );
  }

  return (
    <div className="w-full md:w-[90%] mx-auto flex flex-col justify-items-stretch my-16">
      {content}
    </div>
  );
};

export default ApplicationStepper;
