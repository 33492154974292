import React from "react";
import { useSelector } from "react-redux";

const EmptyTimeSlot = ({ jobpostname }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-full md:w-[90%] mx-auto rounded-sm border mb-4">
      <div className="p-4 space-y-2">
        <h1 className="text-base md:text-lg font-normal text-gray-500">
          Hey! {user?.name}
        </h1>
        <h2 className="text-sm md:text-base font-normal text-gray-400">
          Your jobpost name{" "}
          <span className="text-secondary">{jobpostname}</span>
        </h2>
        <h2 className="text-sm md:text-base font-normal text-gray-400">
          Sorry, There is no exam time slot available right now!
        </h2>
      </div>
      <div></div>
    </div>
  );
};

export default EmptyTimeSlot;
