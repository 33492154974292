import moment from "moment";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsHourglassSplit } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { TfiTime } from "react-icons/tfi";
import { getExamFormattedTime } from "../utils/getExamFormattedTime";
const TimeSlotModal = ({ showSlot, setSlotSlow, data }) => {
  return (
    <div
      className={`w-full h-full fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[1000] ${
        showSlot ? "flex" : "hidden"
      } justify-center items-center`}
    >
      <div className="w-[80vw] sm:w-[60vw] md:w-[35vw] h-[50vh] bg-white relative overflow-auto scrollbar-hide flex flex-col items-center gap-4 z-[30000]">
        {data !== undefined ? (
          <div className="w-full h-fit max-h-[calc(100vh-264px)] overflow-auto scrollbar-hide">
            <table className="whitespace-nowrap w-full bg-white h-full border-collapse border rounded transition duration-700">
              <thead className="">
                <tr className="sticky -top-1 z-10">
                  <th
                    colSpan={2}
                    className="bg-gray-100 text-xs gap-x-0 text-gray-400 font-medium text-left border"
                  >
                    <div className="w-full h-8 bg-gray-500 text-white sticky top-0 left-0">
                      <span
                        onClick={() => setSlotSlow(false)}
                        className="absolute top-2 right-2 cursor-pointer"
                      >
                        <AiOutlineClose />
                      </span>

                      <h1 className="text-lg font-medium text-white text-center">
                        Exam Time Slots
                      </h1>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.slotDate?.map((item, curindex) => (
                  <tr
                    key={curindex}
                    className="border-t border-b border-gray-300 "
                  >
                    <td className="text-xs w-fit px-3 py-2 gap-x-0 text-gray-500 text-center border">
                      <p className="text-xs flex items-center gap-x-1 font-semibold my-1">
                        <SlCalender /> Start Date:{" "}
                        <span className="font-normal">
                          {moment(item?.examStartSlotDate).format("YYYY-MM-DD")}
                        </span>
                      </p>
                      <p className="text-xs flex items-center gap-x-1 font-semibold my-1">
                        <TfiTime />
                        Start Time:{" "}
                        <span className="font-normal">
                          {moment(item?.examStartSlotDate).format("LT")}
                        </span>
                      </p>
                      <p className="text-xs flex items-center gap-1 font-semibold my-1">
                        <BsHourglassSplit /> Duration:{" "}
                        <span className="font-normal">
                          {getExamFormattedTime(
                            item?.examStartSlotDate,
                            item?.examEndSlotDate
                          )}
                        </span>
                      </p>
                    </td>
                    <td className="text-xs w-fit px-3 py-1 gap-x-0 text-gray-500 text-center border">
                      <p className="text-xs flex items-center gap-x-1 font-semibold my-1">
                        <SlCalender />
                        End Date:{" "}
                        <span className="font-normal">
                          {moment(item?.examEndSlotDate).format("YYYY-MM-DD")}
                        </span>
                      </p>
                      <p className="text-xs flex items-center gap-x-1 font-semibold my-1">
                        <TfiTime /> End Time:{" "}
                        <span className="font-normal">
                          {moment(item?.examEndSlotDate).format("LT")}
                        </span>
                      </p>
                      <p className="text-xs flex items-center gap-x-1 font-semibold my-1">
                        <TfiTime /> Slot Number{" "}
                        <span className="font-normal">{item?.slotNumber}</span>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <h1 className="text-sm font-normal text-gray-500">
              No Time Slot Yet!
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeSlotModal;
