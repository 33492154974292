import React from "react";
import { BsArrowRight } from "react-icons/bs";
import gifBanner from "../../Assets/images/gifs/Join us.gif";
const JoinUsBanner = () => {
  return (
    <div className=" container ">
      <div className="flex items-center justify-between mt-3 flex-wrap-reverse md:flex-nowrap gap-2 gap-y-5">
        <div className=" space-y-8 w-full  md:basis-1/2 mt-6 mx-auto">
          <h1 className=" text-2xl md:text-4xl  lg:text-5xl font-semibold text-textColor tracking-wide">
            Join Our Team
          </h1>
          <p className="  text-left ">
            Join our team of dedicated professionals and make a difference. We
            are always looking for passionate individuals to help us deliver the
            best solutions. If you're looking for a dynamic work environment and
            the opportunity to grow, we want to hear from you. Apply today and
            become part of a team committed to excellence.
          </p>
          <div className=" flex items-center gap-6 ">
            <p
              // to={"/applicant/login"}
              className=" flex items-center gap-2 text-textColor font-medium text-base"
            >
              Access Your Portal
              <span>
                <BsArrowRight />
              </span>{" "}
            </p>
            {/* <Link
              to={"/applicant/login"}
              className="whitespace-pre flex items-center gap-2 bg-secondary py-2 px-2 text-sm md:text-base md:px-4 text-white rounded-md
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l"
            >
              Access Your Portal
              <BsArrowRight />
            </Link> */}
          </div>
        </div>
        <div className=" w-full  md:basis-1/2 px-3 mt-4">
          <img className=" w-full" src={gifBanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default JoinUsBanner;
