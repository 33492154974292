import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import nojob from "../../Assets/images/gifs/No_vacancies.gif";
import { getAllJobsAPI } from "../../api/jobpostAPI";
import JobPostLoading from "../Loading/JobPostLoading";
import Accordion from "./Accordion";

const AllJobs = () => {
  const [jobs, setAllJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  useEffect(() => {
    const getAllJobs = async () => {
      setLoading(true);
      const res = await getAllJobsAPI(); //api call
      if (res && res.status === 200) {
        setAllJobs([...res?.data?.jobposts]);
        setLoading(false);
      }
    };
    getAllJobs();
  }, []);
  return (
    <div className="container w-full mx-auto pt-10 px-2 md:px-10">
      <div className="text-center">
        <p className="text-secondary uppercase text-sm">Open Positions</p>
        <h1 className="text-xl md:text-3xl font-semibold pt-2 text-gray-600">
          Diversified Job Openings To Apply
        </h1>
      </div>
      <div className="container w-full my-10 shadow-lg rounded-xl md:p-5 py-3">
        {loading && (
          <div>
            <div className="h-64 w-full bg-white shadow-[0px_4px_4px_#00000040] my-4 rounded-md">
              <Skeleton count={1} height={"100%"}></Skeleton>
            </div>
            {Array(4)
              ?.fill()
              ?.map((item, i) => (
                <JobPostLoading key={i} />
              ))}
          </div>
        )}

        {jobs && jobs?.length !== 0 ? (
          jobs?.map((job, index) => (
            <div key={index}>
              <Accordion
                job={job}
                index={index}
                active={active}
                setActive={setActive}
              />
            </div>
          ))
        ) : (
          <div className="w-72 container">
            <img src={nojob} alt="" className="w-full h-full object-cover" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllJobs;
