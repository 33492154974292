import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import gifBanner from "../../Assets/images/gifs/home.gif";

const Banner = () => {
  return (
    <div className=" container ">
      <div className="flex items-center justify-between mt-5 flex-wrap-reverse md:flex-nowrap gap-2 gap-y-5">
        <div className=" space-y-8 w-full  md:basis-[70%] mx-auto">
          <p className=" text-secondary -mb-3">
            Paving the way through disruptive technologies
          </p>
          <h1 className=" text-2xl md:text-4xl  lg:text-5xl font-semibold text-textColor tracking-wide">
            Revolutionizing Your <br />{" "}
            <span className=" text-secondary ">Business Growth</span>{" "}
          </h1>
          <p className="  text-left break-words w-[80%]">
            Our cutting-edge technology is designed to empower companies to
            unlock their full potential and stay ahead of the curve. We are
            revolutionizing the tech-business ecosystem.
          </p>
          <div className=" flex items-center gap-2  md:gap-6 ">
            <Link
              to="/services"
              className=" whitespace-pre bg-secondary py-2 px-2 text-sm md:text-base md:px-4 text-white rounded-md
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l"
            >
              Services We Provide
            </Link>
            <Link
              to={"/about"}
              className=" whitespace-pre flex items-center gap-1 md:gap-2 text-textColor font-medium text-sm md:text-base"
            >
              Learn More{" "}
              <span>
                <BsArrowRight />
              </span>{" "}
            </Link>
          </div>
        </div>

        <div className=" w-full  md:basis-[40%] px-3 mt-4">
          <img className="w-[450px]" src={gifBanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
