import asse32 from "../Assets/images/servicesicons/Asset 32.svg";
import asse31 from "../Assets/images/servicesicons/Asset 31.svg";
import asse30 from "../Assets/images/servicesicons/Asset 30.svg";
export const ecommerceData = [
  {
    img: asse32,
    title: "E-commerce Website/App",
    description:
      "Create a beautiful online store/e-commerce app or website and start selling online. We have more than 5 years of experience in developing e-commerce businesses. We can help you to set up your online footprint and create an e-commerce platform for your business.",
  },
  {
    img: asse31,
    title: "Multi Store Ecommerce",
    description:
      "A multi-store eCommerce platform allows you to launch and manage multiple stores from a single platform. enterprise-level companies can offer a unique shopping experience even while handling multiple brands and not compromising on centralized services for their unique customers.",
  },
  {
    img: asse30,
    title: "Marketplacet",
    description:
      "If you are thinking of creating and maintaining your marketplace we can help you to accomplish your business. We have a proven track record of building and maintaining Marketplace. Also, our industry experts can audit your existing platform and help to grow your business.",
  },
];
