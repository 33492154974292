import React, { useEffect, useState } from "react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { TfiReload } from "react-icons/tfi";
import { useSelector } from "react-redux";
import {
  getAllApplicantsAPI,
  getAllCategoriesAPI,
} from "../../../api/applyJobAPI";
import Loading from "../../Loading/Loading";
import Application from "./Application";

const ApplicationTab = () => {
  const [totalApplication, setTotalApplication] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState("");
  const { access_token: token } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(0);
  const [tab1, setTab1] = useState(null);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubCategory] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [reload, setReload] = useState(0);
  const [applications, setAllApplications] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      const res = await getAllCategoriesAPI(token); //api call
      if (res && res.status === 200) {
        setCategoriesData([{ _id: "All Department" }, ...res?.data?.resData]);
      }
    };
    getAllCategories();
  }, []);

  useEffect(() => {
    const getAllApplicants = async () => {
      setLoading(true);
      const res = await getAllApplicantsAPI(token, category, subcategory, page); //api call
      if (res && res.status === 200) {
        setLoading(false);
        setTotalApplication(res?.data?.resData[0]?.total[0]?.count);
        if (page === 1) {
          setAllApplications([...res?.data?.resData[0]?.data]);
        } else {
          setAllApplications([...applications, ...res?.data?.resData[0]?.data]);
        }
      }
    };
    getAllApplicants();
  }, [reload, category, subcategory, page]);

  // search functionality
  // useEffect(() => {
  //   if (search) {
  //     navigate(`/admin/application?search=${search}`, { state: search });
  //   }
  // }, [search]);

  // infinity scrolling
  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     setPage((prev) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <>
      {loading && <Loading loading={true} />}

      {/* <Search search={search} setSearch={setSearch} /> */}
      <div className=" md:container custom-font">
        <div className="flex justify-center items-center ">
          <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
            <HiOutlineClipboardDocumentList /> Applications
          </h1>
        </div>
        <div
          className={` relative md:container h-20 flex mt-2 items-start gap-5 overflow-x-auto overflow-scroll scroll-smooth 
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
      scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1 py-2 mb-2`}
        >
          {categoriesData &&
            categoriesData?.map((category, index) => (
              <div
                key={index}
                className={`px-4 whitespace-pre flex w-42 flex-col items-start text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === index ? "text-secondary" : "text-textColor"
          }`}
                onClick={() => {
                  setTab(index);
                  setCategory(index === 0 ? null : category?._id);
                  setPage(1);
                }}
              >
                {category?._id}

                {tab === index && (
                  <div
                    className={`absolute top-[30%] left-[4%] flex gap-4 flex-wrap mt-4`}
                  >
                    {category?.subcategories?.map((subcategory, i) => (
                      <div
                        className={`px-2 border rounded-full  ${
                          tab1 === i ? "text-secondary" : "text-textColor"
                        }`}
                        key={i}
                        onClick={() => {
                          setTab1(i);
                          setSubCategory(subcategory?.jobsubcategory);
                        }}
                      >
                        {subcategory?.jobsubcategory}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>

        {/* tab content */}
        <div className="transition-all duration-500">
          {categoriesData &&
            categoriesData?.length !== 0 &&
            categoriesData?.map((data, index) => (
              <div key={index}>
                <Application
                  tab={tab}
                  data={applications}
                  reload={reload}
                  setReload={setReload}
                  index={index}
                  loading={loading}
                />
              </div>
            ))}
          {applications?.length >= 10 &&
            applications?.length !== totalApplication && (
              <div
                className="w-full flex justify-center items-center"
                onClick={() => setPage(page + 1)}
              >
                <span
                  className={` cursor-pointer text-sm px-2 py-1 rounded-full bg-gray-500 text-white flex items-center gap-x-2 w-fit text-center`}
                >
                  <TfiReload /> Load More
                </span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ApplicationTab;
