import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const PrivateRoute = ({ children, allowedRoles, path }) => {
  const { user } = useSelector((state) => state.auth);
  const isLoggedIn = useAuth();
  return isLoggedIn && allowedRoles.includes(user?.role) ? (
    children
  ) : !allowedRoles.includes(user?.role) ? (
    <Navigate to={"/"} />
  ) : (
    <Navigate to={path} />
  );
};

export default PrivateRoute;

// import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = ({ auth, authUser, allowedRoles, path }) => {
//   return auth && allowedRoles?.includes(authUser?.role) ? (
//     <Outlet />
//   ) : (
//     <Navigate to={path} />
//   );
// };

// export default PrivateRoute;
