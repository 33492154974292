import React from "react";

import { HashLink } from "react-router-hash-link";

const MobileDropDown = ({ dropDownMenuData }) => {
  const currentPath = window.location.pathname;
  return (
    <div
      className={` w-full   text-left  
          transition-all duration-1000 `}
    >
      <ul
        className="w-full   text-textColor font-semibold text-base flex flex-col 
            transition-all duration-1000   mt-2 list-none"
      >
        {dropDownMenuData &&
          dropDownMenuData.map((item, i) => (
            <li
              className="  hover:text-secondary transition-all duration-300 
                  w-fit whitespace-pre text-sm   font-normal text-gray-600 "
              key={i}
            >
              {currentPath === "/services" ? (
                <HashLink
                  to={`${item.itemlinksec}`}
                  className=" flex items-center  justify-between cursor-pointer"
                  spy="true"
                  smooth={true}
                  offset={1}
                  duration={500}
                >
                  {item.itemname}
                </HashLink>
              ) : (
                <HashLink
                  to={item.itemlink}
                  className=" flex items-center  justify-between cursor-pointer"
                  spy="true"
                  smooth={true}
                  offset={2}
                  duration={500}
                >
                  {item.itemname}
                </HashLink>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MobileDropDown;
