import axios from "axios";
import { toast } from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;

// creating a brand
export const createContactAPI = async (data) => {
  try {
    return await axios.post(`${baseURL}/api/v1/createContact`, data);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// creating a brand
export const getAllContactsAPI = async (token) => {
  try {
    return await axios.get(`${baseURL}/api/v1/getallContacts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
