import moment from "moment";
import React from "react";
import { CiShoppingTag } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";

const JobCard = ({ data, index }) => {
  return (
    <div
      className="w-full group shadow-md rounded-md  bg-no-repeat object-contain bg-cover   py-1"
      // style={{
      //   backgroundImage: `url(${data?.jobDetails[0]?.photo?.url})`,
      // }}
    >
      <div className=" m-2  p-3 rounded-md h-40 relative">
        <div className="flex justify-between pb-2 ">
          <p className=" text-gray-500 bg-white rounded-full  py-1 flex justify-center items-center text-xs">
            Applied at
            <span className="text-gray-400 ml-1">
              {moment(data?.createdAt).format("ll")}
            </span>
          </p>
          <div>
            <CiShoppingTag className="text-2xl text-secondary" />
          </div>
        </div>
        <div>
          <p className="text-lg text-gray-500 font-semibold">
            {data?.jobpostname}
          </p>
          <p className="text-xs text-gray-400 ">{data?.jobcategory}</p>
        </div>
        <div className=" flex items-center justify-between mt-8">
          <p className="text-xs text-gray-400">
            {data?.jobDetails[0]?.employeestatus} <span className=""></span>
          </p>
          <Link
            to={`/candidate-dashboard/job-status/${data?._id}`}
            // className="absolute right-2 bottom-2"
          >
            <button className="text-xs py-2 px-3 bg-secondary text-white rounded-full flex items-center gap-1.5 whitespace-pre">
              <p>See Details</p> <HiArrowLongRight />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
