import React from "react";
import MyJobApplication from "../../Components/AppliedJobPost/MyJobApplication";
const JobStatus = () => {
  return (
    <div>
      <MyJobApplication />
    </div>
  );
};

export default JobStatus;
