import { apiSlice } from "../api/apiSlice";

export const contactApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteContact: builder.mutation({
      query: (data) => {
        const { access_token, id } = data;
        return {
          url: `/api/v1/delete-contact/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const { useDeleteContactMutation } = contactApiSlice;
