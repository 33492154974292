import moment from "moment/moment";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { GoLinkExternal } from "react-icons/go";
import { MdOutlineContentCopy } from "react-icons/md";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ApplicationView from "../../Components/Admin/popup/ApplicationView";
import Error from "../../error/Error";
import { useTaskSubmiteduserQuery } from "../../features/exam/examApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const TaskSubmited = () => {
  const [query] = useSearchParams();
  const jobName = query.get("jobName");
  const jobId = query.get("jobId");
  const { access_token } = useSelector((state) => state.auth);
  const [taskData, setTaskData] = useState({});
  const [dataExamUser, setDataExamUser] = useState({});
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(0);

  const {
    data: dataUsersTask,
    isLoading,
    isSuccess,
    isError,
  } = useTaskSubmiteduserQuery(
    { access_token, jobName, jobId },
    { refetchOnReconnect: true }
  );

  const handleShow = async (item) => {
    let viewStatus = true;
    setDataExamUser({ ...item });
    setShow(true);
    // const res = await changeVisitAPI(item._id, access_token, { viewStatus }); // api call
  };
  let content = null;
  if (isLoading && !isError) {
    content = (
      <>
        <tbody>
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className="flex items-center gap-x-2">
                    <div role="status" class="max-w-sm animate-pulse">
                      <svg
                        class="text-gray-200 w-14 h-14 dark:text-gray-700"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-primaryColor font-medium flex items-center gap-1">
                        <div role="status" class="max-w-sm animate-pulse">
                          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                        </div>
                      </p>
                      <p className="text-primaryColor font-medium flex items-center gap-1">
                        <div role="status" class="max-w-sm animate-pulse">
                          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                        </div>
                      </p>
                    </div>
                  </div>
                </td>
                <td className="hidden md:block text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  if (isSuccess && !isError && dataUsersTask?.taskuser?.task?.length !== 0) {
    content = (
      <>
        <tbody>
          {dataUsersTask?.taskuser?.task &&
            dataUsersTask?.taskuser?.task?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-sm min-w-[250px] w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className=" flex  gap-x-2">
                    <img
                      src={item?.userDetails[0]?.photo?.url}
                      className="w-10 h-10 rounded-full ring ring-green-500 object-contain"
                      alt=""
                    />
                    <div className=" flex flex-col gap-2 ">
                      <div className=" ">
                        <p className=" text-primaryColor font-medium flex items-center gap-1">
                          {item?.submitedUserEmail}
                        </p>
                        <p className="text-xs">{item?.jobName}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  {moment(item?.createdAt).format("LLL")}
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-center">
                  <span className="flex items-center gap-4">
                    <a
                      href={item?.submitionLink}
                      title="Go to the link"
                      target="_blank"
                      className=""
                      rel="noreferrer"
                    >
                      <GoLinkExternal className="text-lg text-blue-500" />
                    </a>
                    <MdOutlineContentCopy
                      title="Copy Link"
                      className="text-lg text-blue-500 cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.submitionLink);
                        toast.success("Link Copied!");
                      }}
                    />
                  </span>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className="flex items-center gap-x-2 text-xl">
                    {item?.viewStatus ? (
                      <BsEyeSlash
                        onClick={() => {
                          setDataExamUser({
                            ...item?.userDetails[0],
                          });
                          setShow(true);
                          setTaskData({ ...item });
                        }}
                        title="View"
                        className={` cursor-pointer ${
                          item.viewStatus ? "text-gray-400" : "text-green-500"
                        }`}
                        size={20}
                      />
                    ) : (
                      <BsEye
                        onClick={() => {
                          handleShow(item?.userDetails[0]);
                          setTaskData({ ...item });
                        }}
                        title="View"
                        className="text-green-500 cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  if (isSuccess && !isError && dataUsersTask?.taskuser?.task?.length === 0) {
    content = (
      <tbody>
        <tr>
          <td colSpan={4}>
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }
  if (!isSuccess && isError) {
    content = (
      <>
        <tbody>
          <tr>
            <td colSpan={4}>
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }
  return (
    <>
      <div className="w-full mx-auto bg-white pb-4 rounded-lg">
        <div className="md:container flex flex-col gap-y-2">
          <div className={` flex flex-col gap-4 mt-4`}>
            <div className="w-full h-full overflow-x-auto ">
              <table
                className=" whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
              >
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Applicant
                    </th>
                    <th className=" text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Submission Time
                    </th>
                    <th className=" text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Submission Link
                    </th>
                    <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      View
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
              {show && (
                <ApplicationView
                  application={dataExamUser}
                  taskData={taskData}
                  show={show}
                  setShow={setShow}
                  reload={reload}
                  setReload={setReload}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskSubmited;
