import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { aboutDropDownData } from "../../Data/AboutDropdownItem";
import AffiliateMarketing from "./AffiliateMarketing";
import BussinessManage from "./BussinessManage";
import DigitalMarketing from "./DigitalMarketing";
import ECommerce from "./ECommerce";
import Network from "./Network";
import WebMobile from "./WebMobile";

const ServiceProvider = () => {
  const currentPath = window.location.pathname;
  console.log(currentPath);
  const location=useLocation()
  console.log(location)
  
  const [tab, setTab] = useState(1);

  useEffect(() => {
    if (location?.hash === "#Web_and_Mobile_App_Development") {
      setTab(1)
    }
    if (location?.hash === "#Business_Management_Solution") {
      setTab(2);
    }
    if (location?.hash === "#Digital_Marketing_Solution") {
      setTab(3);
    }
    if (location?.hash === "#E_commerce_Solution") {
      setTab(4);
    }
    if (location?.hash === "#Affiliate_Marketing_Solution") {
      setTab(5);
    }
    if (location?.hash === "#Ad_Network_Solution") {
      setTab(6);
    }
    
  }, [location?.hash])
  
  return (
    <div className=" container my-10" id="servicesId">
      <div className="text-center">
        <p className="text-secondary uppercase text-sm">Services We Provide</p>
        <h1 className="text-xl md:text-3xl font-semibold pt-2 text-gray-600 capitalize">
          Offering a wide range of Professional services
        </h1>
      </div>
      <div className=" container my-12 flex items-center gap-5 overflow-x-auto">
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 1 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(1)}
          id="Web_and_Mobile_App_Development"
        >
          Web and Mobile App Development
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 1 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 2 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(2)}
          id="Business_Management_Solution"
        >
          Business Management Solution
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 2 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 3 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(3)}
          id="Digital_Marketing_Solution"
        >
          Digital Marketing Solution
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 3 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 4 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(4)}
          id="E_commerce_Solution"
        >
          E-commerce Solution
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 4 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 5 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(5)}
          id="Affiliate_Marketing_Solution"
        >
          Affiliate Marketing Solution
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 5 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
        <div
          className={`px-4 py-2   text-sm  cursor-pointer break-words  text-center
          transition-all duration-500  ${
            tab === 6 ? "text-secondary " : "text-textColor"
          }`}
          onClick={() => setTab(6)}
          id="Ad_Network_Solution"
        >
          Ad Network Solution
          <hr
            className={` border-b-2 w-[30%] mx-auto mt-2 transition-all duration-500  ${
              tab === 6 ? "border-secondary" : "border-white"
            }`}
          />
        </div>
      </div>

      {/* tab content */}
      <div className="my-5  container transition-all duration-500">
        <WebMobile tab={tab} hash={location?.hash} />
        <BussinessManage tab={tab} hash={location?.hash} />
        <DigitalMarketing tab={tab} hash={location?.hash} />
        <ECommerce tab={tab} hash={location?.hash} />
        <AffiliateMarketing tab={tab} hash={location?.hash} />
        <Network tab={tab} hash={location?.hash} />
      </div>
    </div>
  );
};

export default ServiceProvider;
