import React from "react";
import bgImg from "../Assets/images/4.png";
import AboutBanner from "../Components/Banner/AboutBanner";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About - Masterkey Technologies Ltd.</title>
      </Helmet>
      <div
        className="pb-28"
        style={{
          background: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavBar />
        <AboutBanner></AboutBanner>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default About;
