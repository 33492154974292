import moment from "moment/moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { IoIosArrowDown } from "react-icons/io";

import { Link } from "react-router-dom";

const Accordion = ({ job, index, active, setActive }) => {
  return (
    <div className=" border border-gray-300 rounded-xl py-6 px-1 md:px-6  my-4 relative">
      <div className="  flex gap-2 md:gap-4 justify-between items-center bg-bgColor p-2 py-3 rounded-md ">
        <div className="  flex gap-4 items-center">
          <Link
            to={`/apply/job/${job?._id}`}
            state={job}
            className="text-center text-sm md:text-base flex justify-center items-center text-white px-[0.8rem] py-[0.5rem] 
            border hover:border-primaryColor rounded-md transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
          >
            Apply
          </Link>
          <h3 className="text-base md:text-[1.2rem] text-primaryColor font-semibold">
            {job?.jobpostname}
          </h3>
        </div>
        <IoIosArrowDown
          onClick={() => setActive(index)}
          className={`${
            active === index ? "rotate-180" : ""
          } inline-block text-primaryColor animate-pulse text-xl md:text-[1.8rem] cursor-pointer`}
        />
      </div>

      <div
        className={`${
          active === index ? "opacity-100 flex" : "opacity-0 hidden"
        } p-4 justify-between mt-4 transition-all duration-[0.5s] ease-in-out`}
      >
        <div className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <div className=" mx-auto w-full md:mx-0 ">
            <div className="mb-4">
              <img
                className="w-full h-full rounded-lg object-cover"
                src={job?.photo?.url}
                alt=""
              />
            </div>
            <div className="mb-4">
              <h1 className="text-lg text-primaryColor font-semibold">
                Deadline
              </h1>
              <p className="text-lightText">
                {moment(job?.deadline).format("LL")}
              </p>
            </div>
            <div>
              <h1 className="text-lg text-primaryColor font-semibold">
                Qualifications
              </h1>
              <div className="  px-1 md:px-6 my-3 text-lightText">
                {ReactHtmlParser(job?.jobqualification)}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-x-4 md:justify-between">
              <div className="mt-4">
                <h1 className="text-lg text-primaryColor font-semibold">
                  Employee Status
                </h1>
                <p className="text-lightText text-sm">{job?.employeestatus}</p>
              </div>
              <div className="mt-4">
                <h1 className="text-lg text-primaryColor font-semibold">
                  Salary
                </h1>
                <p className="text-lightText text-sm">
                  {job?.salary !== "Negotiable" && "BDT:"} {job?.salary}
                </p>
              </div>
              <div className="mt-4">
                <h1 className="text-lg text-primaryColor font-semibold">
                  Vacancy
                </h1>
                <p className="text-lightText text-sm">{job?.vaccency}</p>
              </div>
            </div>
          </div>
          <div className="mx-auto w-full md:mx-0 ">
            <div>
              <h1 className="text-lg text-primaryColor font-semibold">
                Job Description
              </h1>
              <div className=" px-1 md:px-6 my-3 text-lightText">
                {ReactHtmlParser(job?.jobdescription)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
