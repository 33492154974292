import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { aboutDropDownData } from "../../Data/AboutDropdownItem";
import { careerDropDrownData } from "../../Data/CareerData";
import MobileDropDown from "./MobileDropDown";
const MobileNavBar = ({ data }) => {
  const { open, setOpen } = data;

  const [opensub, setOpensub] = useState(false);
  const [opensubName, setOpensubName] = useState("");
  return (
    <div className=" relative md:hidden flex items-center justify-between gap-4 px-2 text-secondary ">
      <div className="">
        <HashLink
          to={"/join-us#joinusId"}
          className=" text-sm whitespace-pre px-3 md:px-5 py-2 bg-primaryColor 
            text-white rounded-full hover:bg-transparent hover:text-primaryColor
             border-2  border-primaryColor transition-all duration-300"
        >
          Apply Now
        </HashLink>
      </div>
      <div>
        <button
          className="  text-xl text-active md:hidden "
          onClick={() => setOpen(!open)}
        >
          {open ? <RxCross2 size={30} /> : <AiOutlineMenu size={30} />}
        </button>
      </div>

      {/* mobile ul li link */}

      <div
        className={` w-[350px]  bg-white drop-shadow-lg rounded-md text-left  absolute top-[4rem] 
        -right-4 px-4  py-3 ${
          open ? "block" : "hidden"
        } transition-all duration-1000`}
      >
        <ul
          className="w-full text-textColor font-semibold text-base md:flex  
          items-center gap-8  transition-all duration-1000 space-y-5  mt-4  px-5 list-none"
        >
          <li className="hover:text-secondary transition-all duration-300 ">
            <Link to={"/"}>Home</Link>
          </li>
          <li className="hover:text-secondary transition-all duration-300">
            <Link to={"/about"} className=" flex items-center  justify-between">
              About
              {/* <span>
                <FiChevronDown size={20} />
              </span> */}
            </Link>
          </li>
          <li className="hover:text-secondary transition-all duration-300 relative">
            <button
              className="w-full flex items-center justify-between gap-10"
              onClick={() => {
                setOpensub(!opensub);
                setOpensubName("Services");
              }}
            >
              Services
              <span>
                {opensub && opensubName === "Services" ? (
                  <FiChevronDown size={20} className=" rotate-180" />
                ) : (
                  <FiChevronDown size={20} />
                )}
              </span>
            </button>

            <div
              className={`${
                opensub && opensubName === "Services" ? "block" : "hidden"
              }`}
            >
              <MobileDropDown
                dropDownMenuData={aboutDropDownData}
                opensub={opensub}
              />
            </div>
          </li>

          <li className="hover:text-secondary transition-all duration-300">
            <Link to={"/partners"}>Partners</Link>
          </li>
          <li className="hover:text-secondary transition-all duration-300 relative">
            <button
              className=" w-full flex items-center justify-between gap-10 "
              onClick={() => {
                setOpensub(!opensub);
                setOpensubName("Career");
              }}
            >
              Career
              <span>
                {opensub && opensubName === "Career" ? (
                  <FiChevronDown size={20} className=" rotate-180" />
                ) : (
                  <FiChevronDown size={20} />
                )}
              </span>
            </button>

            <div
              className={`${
                opensub && opensubName === "Career" ? "block" : "hidden"
              }`}
            >
              <MobileDropDown
                dropDownMenuData={careerDropDrownData}
                opensub={opensub}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNavBar;
