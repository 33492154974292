import React from "react";

import ApplicationTab from "../../Components/Admin/applications/ApplicationTab";

const AllApplications = () => {
  return (
    <div className="container w-full mx-auto bg-white py-4 rounded-lg">
      <ApplicationTab />
    </div>
  );
};

export default AllApplications;
