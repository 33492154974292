import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsImage } from "react-icons/bs";
import { MdOutlineEditNote } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formats, modules } from "../../Components/utils/react_quill_files";
import {
  useGetSingleExamQuery,
  useUpdateExamMutation,
} from "../../features/exam/examApiSlice";

const UpdateExam = () => {
  const [photo, setPhoto] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [description, setDescription] = useState("");
  const [loadValue, setLoadValue] = useState({
    departmentName: "",
    examQuestionInText: "",
    description: "",
    meetLink: "",
    examJobPost: "",
    examJobPostName: "",
  });
  const { access_token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const {
    data: dataExam,
    isError: isErrorExam,
    isSuccess: isSuccessExam,
  } = useGetSingleExamQuery(
    { access_token, id },
    { refetchOnReconnect: true }
  ) || {};

  const [
    updateExam,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useUpdateExamMutation() || {};

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPhoto(reader.result);
      };
      reader.onerror = (error) => {
        console.log(error);
      };
    }
  };

  // setting the initial value that is fetched from an api
  useEffect(() => {
    if (isSuccessExam && !isErrorExam) {
      setDescription(dataExam?.exam?.description);
      setQuestionText(dataExam?.exam?.examQuestionInText);
      setLoadValue({
        departmentName: dataExam?.exam?.departmentName,
        // examQuestionInText: dataExam?.exam?.examQuestionInText,
        // description: dataExam?.exam?.description,
        meetLink: dataExam?.exam?.meetLink,
        examJobPost: dataExam?.exam?.examJobPost,
        examJobPostName: dataExam?.exam?.examJobPostName,
      });
    }
  }, [isSuccessExam, isErrorExam]);

  // showing success message when updating the exam
  useEffect(() => {
    if (isSuccessUpdate && !isErrorUpdate) {
      toast.success("Exam Updated Successfully!");
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  // showing error message when updating the exam
  useEffect(() => {
    if (isErrorUpdate && !isSuccessUpdate) {
      toast.error(errorUpdate?.data?.message || "Not Updated!");
    }
  }, [isErrorUpdate, isSuccessUpdate, errorUpdate]);
  return (
    <div className="md:container w-full flex flex-col justify-center items-center gap-6 bg-white rounded-lg py-4">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <MdOutlineEditNote className="-mb-1" /> Update Exam
        </h1>
      </div>
      <div className="container w-full mx-auto">
        <Formik
          initialValues={loadValue}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            if (questionText) {
              values.examQuestionInText = questionText;
            }
            if (description) {
              values.description = description;
            }
            if (photo) {
              values.examPhotoBase64 = photo;
            }
            updateExam({
              access_token,
              bodyData: values,
              jobId: values.examJobPost,
              jobName: values.examJobPostName,
            });
            resetForm();
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form
                onSubmit={handleSubmit}
                className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 gap-6"
              >
                <div className="w-full mx-auto md:mx-0">
                  <label className="text-[1rem] font-medium" htmlFor="">
                    Department Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    className="w-full outline-none px-4 cursor-not-allowed py-1.5 rounded-sm border "
                    name="departmentName"
                    placeholder="Department Name"
                    readOnly
                    value={values.departmentName}
                  />
                </div>
                <div className="w-full mx-auto md:mx-0">
                  <label className="text-[1rem] font-medium" htmlFor="">
                    Exam Job Post Name<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="examJobPostName"
                    className="w-full outline-none px-4 py-1.5 rounded-sm border  cursor-not-allowed "
                    placeholder="Exam Job Post Name"
                    readOnly
                    value={values.examJobPostName}
                  />
                </div>

                <div className="w-full mx-auto md:mx-0">
                  <label className="text-[1rem] font-medium" htmlFor="">
                    Exam Question<span className="text-red-600">*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    className="exam_quill_editor w-full outline-none rounded-sm bg-white text-sm mb-8"
                    value={questionText}
                    onChange={(e) => setQuestionText(e)}
                    modules={modules}
                    formats={formats}
                    placeholder="Exam Question"
                  />
                </div>
                <div className="w-full mx-auto md:mx-0 mt-14 md:mt-0">
                  <label className="text-[1rem] font-medium" htmlFor="">
                    Description <span className="text-red-600">*</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    name="description"
                    className="exam_quill_editor w-full outline-none bg-white text-sm"
                    modules={modules}
                    formats={formats}
                    value={description}
                    onChange={(e) => setDescription(e)}
                    placeholder="Exter the description"
                  />
                </div>
                <div className="w-full mx-auto md:mx-0">
                  <label className="text-[1rem] font-medium" htmlFor="">
                    Google Meet Link<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="meetLink"
                    className="w-full outline-none px-4 py-1.5 rounded-sm border cursor-pointer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Google Meet Link"
                    value={values.meetLink}
                  />
                  {errors.meetLink && touched.meetLink ? (
                    <p className="text-[10px] text-red-500 mt-1">
                      {errors.meetLink}
                    </p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 mt-9 md:mt-0">
                  <label className="text-base font-medium text-gray-700 ">
                    Question Photo <span className="text-red-600">*</span>
                  </label>
                  <div
                    className="text-gray-900
placeholder-transparent rounded-md
 placeholder:text-3xl flex items-center gap-x-4"
                  >
                    <label
                      className="w-full bg-white md:w-[80%] border text-sm whitespace-nowrap  outline-none px-4 py-1.5 rounded-sm cursor-pointer border-gray-300"
                      htmlFor="photo"
                    >
                      <BsImage className="text-secondary text-lg inline-block mr-1 text-prborder-primaryColor" />
                      Choose Image
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => {
                        convertToBase64(e.target.files[0]);
                      }}
                      id="photo"
                    />
                    <div className="">
                      <img
                        className="w-10 h-10 rounded-full object-contain"
                        src={photo || dataExam?.exam?.examQuestionInImg?.url}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full mx-auto md:mx-0 flex justify-start items-end">
                  <button
                    type="submit"
                    disabled={isLoadingUpdate}
                    className={`w-[30%] text-center flex justify-center items-center text-white px-4 py-1.5 border hover:border-primaryColor rounded-sm transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent ${
                      isLoadingUpdate && "cursor-pointer"
                    }`}
                  >
                    {isLoadingUpdate ? "Please Wait.." : "Update"}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateExam;
