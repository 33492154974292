import React from "react";
import Helmet from "react-helmet";
import bgImg from "../Assets/images/4.png";
import PartnerBanner from "../Components/Banner/PartnerBanner";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import AllPartner from "../Components/Partner/AllPartner";

const PartnerPage = () => {
  return (
    <div>
      <Helmet>
        <title>Partners - Masterkey Technologies Ltd.</title>
      </Helmet>
      <div
        className="pb-28"
        style={{
          background: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavBar />
        <PartnerBanner></PartnerBanner>
      </div>
      <AllPartner></AllPartner>
      <Footer></Footer>
    </div>
  );
};

export default PartnerPage;
