import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

// import userimg from "../../Assets/images/user.png";
import business from "../../Assets/images/servicesicons/Asset 8 (1).svg";
import digital from "../../Assets/images/servicesicons/Asset 9 (1).svg";
import ECommerce from "../../Assets/images/servicesicons/Asset 7 (1).svg";
import affiliate from "../../Assets/images/servicesicons/Asset 6 (1).svg";
import network from "../../Assets/images/servicesicons/Asset 4 (2).svg";
import dev from "../../Assets/images/servicesicons/Asset 10 (1).svg";
const SupportDetails = () => {
  return (
    <div className=" container">
      <div className="flex gap-4 justify-between mt-16 flex-wrap md:flex-nowrap  gap-y-5">
        <div className=" md:basis-[50%] grid md:grid-cols-3 grid-cols-2   gap-4">
          <div
            className=" r md:h-40  lg:h:48 bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           flex flex-col items-center justify-center"
          >
            <img className="w-16" src={dev} alt="" />
            <p className=" text-center mt-3">Development</p>
          </div>

          <div
            className=" rounded  md:h-40  lg:h:48  bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           p-5 flex flex-col items-center justify-center"
          >
            <img className="w-16" src={business} alt="" />
            <p className=" text-center mt-3">Bussiness Management</p>
          </div>
          <div
            className=" rounded  md:h-40  lg:h:48  bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           p-5 flex flex-col items-center justify-center"
          >
            <img className="w-16" src={digital} alt="" />
            <p className=" text-center mt-3">Digital Marketing</p>
          </div>
          <div
            className=" rounded  md:h-40  lg:h:48  bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           p-5 flex flex-col items-center justify-center"
          >
            <img className="w-16" src={ECommerce} alt="" />
            <p className=" text-center mt-3">E-commerce Solution</p>
          </div>
          <div
            className=" rounded  md:h-40  lg:h:48  bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           p-5 flex flex-col items-center justify-center"
          >
            <img className="w-16" src={affiliate} alt="" />
            <p className=" text-center mt-3">Affiliate Marketing Solution</p>
          </div>
          <div
            className=" rounded  md:h-40  lg:h:48  bg-gradient-to-b from-[#4f589b26] to-[#684f9b00]
           p-5 flex flex-col items-center justify-center"
          >
            <img className="w-16" src={network} alt="" />
            <p className=" text-center mt-3">Ad Network Solution</p>
          </div>
        </div>
        <div className=" md:basis-[45%] space-y-6 w-full  ">
          <p className=" text-secondary">How can we help you?</p>
          <h1 className=" text-2xl md:text-3xl  lg:text-5xl font-semibold text-textColor tracking-wide">
            You name it, We will do it{" "}
            {/* <span className=" text-secondary">Business</span>{" "} */}
          </h1>
          <p className="text-left">
            Our versatile and super-talented tech team can provide solutions to
            your needs.
          </p>
          <div className=" flex items-center gap-6 ">
            <Link
              to="/services"
              className=" bg-secondary py-2 px-4 text-white rounded-md
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l  flex items-center gap-2"
            >
              Learn More{" "}
              <span>
                <BsArrowRight />
              </span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportDetails;
