import React from "react";

const Radiobutton = ({ check, setCheck, setVaccency }) => {
  return (
    <div className="flex gap-x-1">
      <input
        className=""
        type="radio"
        value="NA"
        checked={check === "NA"}
        onChange={(e) => {
          setCheck(e.target.value);
          setVaccency("NA");
        }}
      />
      NA
      <input
        className="ml-4"
        type="radio"
        value="Number"
        checked={check === "Number"}
        onChange={(e) => setCheck(e.target.value)}
      />
      Number
    </div>
  );
};

export default Radiobutton;
