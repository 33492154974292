import React from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";

const Commonlayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div className="py-6">{children}</div>
      <Footer></Footer>
    </>
  );
};

export default Commonlayout;
