import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const JobPostLoading = () => {
  return (
    <div className="h-10 w-full bg-white shadow-[0px_4px_4px_#00000040] my-4 rounded-md">
      <Skeleton count={1} height={"100%"}></Skeleton>
    </div>
  );
};

export default JobPostLoading;
