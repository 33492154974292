import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePhone } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { MdOutlinePassword } from "react-icons/md";
import { RxEnvelopeClosed, RxUpdate } from "react-icons/rx";
import { TbEdit } from "react-icons/tb";
import { useSelector } from "react-redux";
import profile_update from "../../Assets/images/gifs/profile_update.gif";
import { useUpdateProfileMutation } from "../../features/user/userApiSlice";
import { updateProfileSchema } from "../../validation/schema";

const ProfileCard = ({ response }) => {
  const { access_token } = useSelector((state) => state.auth);
  const [
    updateProfile,
    {
      data: dataUpdate,
      isSuccess: isSuccessUpdate,
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateProfileMutation() || {};
  const [showEdit, setShowEdit] = useState(false);
  const { data: dataUser } = response;

  const {
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      password: "",
      phone: "",
    },
    validationSchema: updateProfileSchema,
    onSubmit: (values, action) => {
      updateProfile({ access_token, bodyData: values });
    },
  });

  // setting initial value
  useEffect(() => {
    if (dataUser?.user) {
      setFieldValue("phone", dataUser?.user?.phone);
      setFieldValue("password", dataUser?.user?.password);
    }
  }, [dataUser, setFieldValue]);

  // showing success update message
  useEffect(() => {
    if (isSuccessUpdate && !isErrorUpdate) {
      toast.success(dataUpdate?.message || "Profile Updated!");
    }
  }, [isSuccessUpdate, isErrorUpdate, dataUpdate]);

  // showing error update message
  useEffect(() => {
    if (isErrorUpdate && !isSuccessUpdate) {
      toast.error(errorUpdate?.data?.message || "Profile Not Updated!");
    }
  }, [isSuccessUpdate, isErrorUpdate, dataUpdate, errorUpdate]);

  return (
    <div className="bg-white border rounded-md   p-4 mt-8">
      <form
        onSubmit={handleSubmit}
        className="grid grid-col-1 md:grid-cols-2 gap-4"
      >
        <div className="flex flex-col items-center gap-10 ">
          <img
            src={dataUser?.user?.photo?.url}
            className="w-36 object-contain rounded-full"
            alt=""
          />
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 ">
            <div className="w-full flex items-center">
              <span className="w-12 h-10 rounded-sm bg-purple-200 flex justify-center items-center">
                <BiUser className="text-xl text-primaryColor" />
              </span>
              <div className="w-full flex flex-col ">
                <span className="text-xs font-normal text-gray-600 px-2">
                  Name
                </span>
                <input
                  className="text-sm font-semibold text-gray-500 border-none bg-white outline-none px-2 py-0.5 cursor-not-allowed"
                  readOnly
                  value={dataUser?.user?.name}
                />
              </div>
            </div>
            <div className="w-full flex items-center">
              <span className="w-12 h-10 rounded-sm bg-purple-200 flex justify-center items-center">
                <RxEnvelopeClosed className="text-xl text-primaryColor" />
              </span>
              <div className="w-full flex flex-col ">
                <span className="text-xs font-normal text-gray-600 px-2">
                  Email
                </span>
                <input
                  className="text-sm font-semibold text-gray-500 border-none bg-white outline-none px-2 py-0.5 cursor-not-allowed"
                  readOnly
                  value={dataUser?.user?.email}
                />
              </div>
            </div>
            <div className="w-full flex items-center">
              <span className="w-12 h-10 rounded-sm bg-purple-200 flex justify-center items-center">
                <AiOutlinePhone className="text-xl text-primaryColor" />
              </span>
              <div className="w-full flex flex-col ">
                <span className="text-xs font-normal text-gray-600 px-2">
                  Phone
                </span>
                <input
                  className={`text-sm font-semibold text-gray-500 outline-none border ${
                    showEdit ? "  bg-white" : "bg-white border-transparent"
                  } px-2 py-0.5 ${
                    errors.phone && touched.phone && "border-red-500"
                  } outline-none px-2 py-0.5`}
                  name="phone"
                  readOnly={!showEdit}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                />
              </div>
            </div>
            <div className="w-full flex items-center">
              <span className="w-12 h-10 rounded-sm bg-purple-200 flex justify-center items-center">
                <MdOutlinePassword className="text-xl text-primaryColor" />
              </span>
              <div className="w-full flex flex-col ">
                <span className="text-xs font-normal text-gray-600 px-2">
                  Password
                </span>
                <input
                  className={`text-sm font-semibold text-gray-500 outline-none border ${
                    showEdit ? "  bg-white" : "bg-white border-transparent"
                  } px-2 py-0.5 ${
                    errors.password && touched.password && "border-red-500"
                  } outline-none px-2 py-0.5`}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={!showEdit}
                  value={values.password}
                />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => setShowEdit(!showEdit)}
                className="whitespace-pre bg-[#EB6D46] hover:bg-[#c75533] text-white flex items-center gap-1 px-4 py-1 rounded-sm"
              >
                Edit <TbEdit />
              </button>
              {showEdit && (
                <button
                  type="submit"
                  disabled={isLoadingUpdate}
                  className={`whitespace-pre bg-[#EB6D46] hover:bg-[#c75533] text-white flex items-center gap-1 px-4 py-1 rounded-sm ${
                    isLoadingUpdate && "cursor-not-allowed"
                  }`}
                >
                  {isLoadingUpdate ? "Please Wait.." : "Update"} <RxUpdate />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className=" flex items-center justify-center gap-4">
          <img className="  w-[50%]" src={profile_update} alt="" />
        </div>
      </form>
    </div>
  );
};

export default ProfileCard;
