import React from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/images/masterkeylogo1.jpg";
import { candidateDashboardData } from "../../Data/data";
import { userLoggedOut } from "../../features/auth/authSlice";
import { showMenu } from "../../features/menu/menuSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const view = useSelector((state) => state.menu.view);

  const handleLogout = () => {
    dispatch(userLoggedOut());
    localStorage.removeItem("auth");
  };

  // for active link style
  const activeNavLink = ({ isActive }) => {
    return {
      color: isActive ? "#3b82f6" : "#6b7280",
      // borderColor: isActive ? "#7efff5" : "#ffffff",
    };
  };

  return (
    <div className="relative">
      <div
        className={`${view ? "md:translate-x-0" : "md:translate-x-[-100%]"} ${
          !view ? "translate-x-0" : "translate-x-[-100%]"
        } w-[220px] fixed top-0 left-0 bottom-0 transition-all duration-1000 bg-white shadow-md p-4 pl-0 z-[210]`}
      >
        {/* <div
          onClick={() => dispatch(toggleMenu())}
          className="absolute left-full top-0 w-8 h-8 bg-gray-500 flex md:hidden justify-center items-center"
        >
          <HiMenuAlt1 className={`text-xl text-white`} />
        </div> */}
        <div className="flex flex-col items-center justify-center gap-3">
          <Link to={"/candidate-dashboard"}>
            <img src={logo} className="w-28  border rounded-full" alt="" />
          </Link>
          <div>
            <h1 className="text-[14px] font-medium text-gray-500 text-center">
              Masterkey Technologies Ltd.
            </h1>
            <h1 className="text-xs font-medium text-gray-500 text-center">
              {user?.email ? user?.email : "career@masterkey.tech"}
            </h1>
          </div>
        </div>
        <div>
          {candidateDashboardData?.map((item, index) => (
            <div
              key={index}
              className="group my-6 rounded-r-md px-4 py-2 hover:bg-blue-100 transition-all duration-500"
            >
              <NavLink
                title={item?.title}
                to={item?.link}
                className="w-fit flex items-center gap-2 text-sm font-normal  group-hover:text-blue-500 transition-all duration-500"
                style={activeNavLink}
                end
              >
                <item.icon className="text-base " />
                {item?.title}
              </NavLink>
            </div>
          ))}

          <div className="px-4">
            <button
              onClick={handleLogout}
              className="text-sm font-normal text-gray-500 flex items-center gap-2"
            >
              <IoLogOutOutline className="text-lg text-gray-500" /> Logout
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={() => dispatch(showMenu())}
        className={`${
          view ? "hidden" : "block"
        } md:hidden absolute w-screen h-screen top-[62px] left-0 bottom-0 right-0 bg-[rgba(0,0,0,0.3)] z-[200]`}
      ></div>
    </div>
  );
};

export default Sidebar;
