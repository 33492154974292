import React from "react";
import { useSelector } from "react-redux";
import JobCard from "../../Components/card/JobCard";
import Error from "../../error/Error";
import { useMyAppliedJobsQuery } from "../../features/user/userApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const MyJobApplication = () => {
  const { access_token } = useSelector((state) => state.auth);
  const {
    data: dataMyJob,
    isLoading: isLoadingMyJob,
    isSuccess: isSuccessMyJob,
    isError: isErrorMyJob,
  } = useMyAppliedJobsQuery(access_token, { refetchOnReconnect: true }) || {};

  let content = null;

  // loading case
  if (isLoadingMyJob && !isErrorMyJob) {
    content = (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8">
        {Array(3)
          .fill()
          .map((item, index) => (
            <div
              key={index}
              className="w-full group shadow-md rounded-md  bg-no-repeat object-contain bg-cover py-1"
            >
              <div className=" m-2 p-3 rounded-md h-40 relative">
                <div className="flex justify-between pb-2 ">
                  <span className="w-40 h-4 bg-gray-200 animate-pulse"></span>
                  <span className="w-4 h-4 bg-gray-200 animate-pulse"></span>
                </div>
                <div className="flex justify-between pb-2 ">
                  <span className="w-36 h-2 bg-gray-200 animate-pulse"></span>
                </div>
                <div className=" flex items-center justify-between mt-8">
                  <span className="w-10 h-2 bg-gray-200 animate-pulse"></span>
                  <span className="w-20 h-8 rounded-full bg-gray-200 animate-pulse"></span>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  // success case
  if (isSuccessMyJob && !isErrorMyJob && dataMyJob?.jobDetails?.length !== 0) {
    content = (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-8">
        {dataMyJob?.jobDetails?.map((item, index) => (
          <JobCard key={index} data={item} index={index} />
        ))}
      </div>
    );
  }
  // error case
  if (isErrorMyJob && !isSuccessMyJob) {
    content = (
      <>
        <Error />
      </>
    );
  }
  // empty case
  if (isSuccessMyJob && !isErrorMyJob && dataMyJob?.jobDetails?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  return (
    <div className="px-5 py-2">
      <div>
        <h1 className="text-xl  text-gray-500 font-semibold flex items-center gap-2">
          Your Applied Jobs
        </h1>
        <p className="text-xs text-gray-400">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic
          laudantium, at quod exercitationem asperiores atque quibusdam
          distinctio deleniti ex fuga!
        </p>
      </div>
      {content}
    </div>
  );
};

export default MyJobApplication;
