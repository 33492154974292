import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../Assets/images/logo.png";
import { ImFacebook } from "react-icons/im";
import { FaYoutube, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";

import { MdEmail, MdOutlineLocationCity } from "react-icons/md";

const Footer = () => {
  return (
    <div className=" bg-[#efeafb80] p-3  ">
      <div className="  my-6">
        <div className=" container   flex  justify-around   gap-5 flex-wrap md:flex-nowrap ">
          <div className=" container   self-start basis-[90%] md:basis-[25%]   space-y-2">
            <Link
              className=" flex items-center justify-center md:justify-start"
              to={"/"}
            >
              <img className=" w-32" src={logoImg} alt="" />
            </Link>
            <p className="w-[90%] text-sm text-textColor text-center md:text-left">
              Masterkey Technologies Ltd. Providing reliable tech since 2021
            </p>
            <div className=" flex items-center justify-center md:justify-start  gap-4">
              <a
                href="https://www.facebook.com/masterkey.tech.ltd"
                target="_blank"
                rel="noreferrer"
                className=" hover:text-secondary  text-textColor transition-all duration-500  "
              >
                <p className=" mt-3 bg-white rounded-full shadow-md w-fit p-2">
                  <ImFacebook size={16} />
                </p>
              </a>
              <Link
                className=" hover:text-secondary  text-textColor transition-all duration-500  "
                to={""}
              >
                <p className=" mt-3 bg-white rounded-full shadow-md w-fit p-2">
                  <FaYoutube size={16} />
                </p>
              </Link>
              <a
                className=" hover:text-secondary  text-textColor transition-all duration-500  "
                href="https://www.linkedin.com/company/83132019"
                target="_blank"
                rel="noreferrer"
              >
                <p className=" mt-3 bg-white rounded-full shadow-md w-fit p-2">
                  <FaLinkedinIn size={16} />
                </p>
              </a>
            </div>
          </div>

          <div
            className="   grid grid-cols-1  md:flex   justify-between gap-x-6 gap-y-6
           md:gap-x-5 md:gap-y-5  "
          >
            <div className=" container  px-4 text-center md:text-left">
              <h2 className=" text-lg text-textColor mb-3 font-semibold">
                Services
              </h2>
              <ul className=" list-none space-y-2 text-sm text-textColor text-center md:text-left ">
                <li className="  text-base">
                  <Link to={"/"}>Development</Link>
                </li>
                <li className=" text-base">
                  <Link to={"/"}>Business Management</Link>
                </li>
                <li className="  text-base">
                  <Link to={"/"}>Digital Marketing</Link>
                </li>
                <li className="  text-base">
                  <Link to={"/"}>E-commerce Solution</Link>
                </li>
                <li className="  text-base">
                  <Link to={"/"}>Affiliate Marketing Solution</Link>
                </li>
                <li className="  text-base">
                  <Link to={"/"}>Ad Network Solution</Link>
                </li>
              </ul>
            </div>
            {/* menu section */}
            <div className=" container  px-4 text-center md:text-left ">
              <h2 className=" text-lg text-textColor mb-3 font-semibold">
                Menu
              </h2>
              <ul className=" list-none space-y-2 text-sm text-textColor text-center md:text-left ">
                <li className=" whitespace-pre text-base">
                  <Link className="hover:underline" to={"/about"}>
                    About Us
                  </Link>
                </li>
                <li className=" whitespace-pre text-base">
                  <Link to={"/"}>Contact Us</Link>
                </li>
                <li className=" whitespace-pre text-base">
                  <Link to={"/services"}>Services</Link>
                </li>
                <li className=" whitespace-pre text-base">
                  <Link to={"/partners"}>Our Partners</Link>
                </li>
              </ul>
            </div>
            {/* legal section */}
            <div className=" container px-4 text-center md:text-left ">
              <h2 className=" text-lg text-textColor mb-3 font-semibold">
                Legal
              </h2>
              <ul className=" list-none space-y-2 text-sm text-textColor text-center md:text-left ">
                <li className=" whitespace-pre text-base">
                  <Link to={"/"}>Terms of Use</Link>
                </li>
                <li className=" whitespace-pre text-base">
                  <Link to={"/"}>Privacy Policy </Link>
                </li>
                <li className=" whitespace-pre text-base">
                  <Link to={"/"}>Cookie Policy</Link>
                </li>
              </ul>
            </div>
            {/* contact us section */}
            <div className=" container md:pr-8 px-1 md:px-4 text-center md:text-left">
              <h2 className=" whitespace-pre text-lg text-textColor mb-3 font-semibold">
                Contact Us
              </h2>
              <ul className=" list-none space-y-2 text-sm text-textColor text-center md:text-left ">
                <li className="text-base text-center md:text-left mx-auto flex items-center justify-center md:justify-start  ">
                  <Link
                    className=" whitespace-pre flex   gap-2  text-center md:text-left"
                    to={"/"}
                  >
                    {" "}
                    <span>
                      <FaPhoneAlt size={18} />
                    </span>{" "}
                    +880 1858977722
                  </Link>
                </li>
                <li className="  text-base flex justify-center  md:justify-start ">
                  <Link className="  flex items-center gap-2 " to={"/"}>
                    {" "}
                    <span>
                      <MdEmail size={18} />
                    </span>{" "}
                    contact@masterkey.tech
                  </Link>
                </li>
                <li className=" text-base flex items-center justify-center  md:justify-start ">
                  <Link className=" flex   gap-2  " to={"/"}>
                    {" "}
                    <span>
                      <MdOutlineLocationCity size={18} />
                    </span>{" "}
                    TA-39/1, Gulshan - Badda Link Road, Dhaka - 1212
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className=" pt-4 py-1">
        <hr className=" border border-[#E0E0E0]" />
        <p className=" text-center  py-4 text-sm font-normal">
          Copyright &copy; {new Date().getFullYear()}{" "}
          <span className=" text-secondary"> Masterkey Technologies Ltd.</span>{" "}
          All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
