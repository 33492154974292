import React from "react";
import { BiHappyAlt, BiShowAlt } from "react-icons/bi";
import {
  BsBookmarks,
  BsFillBookmarkCheckFill,
  BsFillBookmarkHeartFill,
} from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { RxBookmark, RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { changeVisitAPI, updateKeyValue } from "../../../api/applyJobAPI";

const ApplicationView = ({
  application,
  taskData,
  show,
  setShow,
  reload,
  setReload,
}) => {
  const { pathname } = useLocation();
  const { access_token: token } = useSelector((state) => state.auth);

  // waiting CV
  const handleWaitingCV = async (id, data) => {
    // console.log(typeof data.cvWaiting)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Waiting Status update!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // let cvWaiting = true;
        const res = await changeVisitAPI(id, token, data); // api call
        if (res && res.status === 200) {
          setShow(false);
          Swal.fire(`Waiting Status Update!`);
          setReload(reload + 1);
        }
      }
    });
  };
  // update Shortlist
  const handleShortList = async (id, data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Update Short List it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await changeVisitAPI(id, token, data); // api call
        if (res && res.status === 200) {
          setShow(false);
          Swal.fire("This Resume/CV is Shortlist Status Updated");
          setReload(reload + 1);
        }
      }
    });
  };

  // cv marked added
  const handleCVMark = async (id) => {
    const { value: ipAddress } = await Swal.fire({
      title: "Enter your Mark",
      input: "number",
      inputLabel: "CV Mark",
      // inputValue: inputValue,
      showCancelButton: true,
      inputValidator: async (value) => {
        if (!value) {
          return "You need to write something!";
        } else {
          const res = await changeVisitAPI(id, token, { cvMark: value });
          if (res && res?.status === 200) {
            setShow(false);
            Swal.fire("This Resume/CV is Marked");
            setReload(reload + 1);
          }
        }
      },
    });
  };
  // task  marked added
  const handleTaskMark = async (id) => {
    const { value: ipAddress } = await Swal.fire({
      title: "Enter your Mark",
      input: "number",
      inputLabel: "Task Mark",
      // inputValue: inputValue,
      showCancelButton: true,
      inputValidator: async (value) => {
        if (!value) {
          return "You need to write something!";
        } else {
          const res = await changeVisitAPI(id, token, { taskMark: value });
          if (res && res?.status === 200) {
            setShow(false);
            Swal.fire("Task Marked Added");
            setReload(reload + 1);
          }
        }
      },
    });
  };
  // InterviewMark  marked added
  const dynamicStatusUpdate = async (id, data) => {
    // get object key
    const key = Object.keys(data);
    // console.log(key[0]);
    let keyValue = key[0];

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${keyValue} status update!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await updateKeyValue(id, token, data); // api call
        if (res && res.status === 200) {
          setShow(false);
          Swal.fire(`${keyValue}  updated`);
          setReload(reload + 1);
        }
      }
    });
  };

  // InterviewMark  marked added
  // const handleInterviewMark = async (id) => {
  //   const { value: ipAddress } = await Swal.fire({
  //     title: "Enter your Mark",
  //     input: "number",
  //     inputLabel: "Interview  Mark",
  //     // inputValue: inputValue,
  //     showCancelButton: true,
  //     inputValidator: async (value) => {
  //       if (!value) {
  //         return "You need to write something!";
  //       } else {
  //         const res = await changeVisitAPI(id, token, { interViewMark: value });
  //         if (res && res?.status === 200) {
  //           setShow(false);
  //           Swal.fire("Task Marked Added");
  //           setReload(reload + 1);
  //         }
  //       }
  //     },
  //   });
  // };

  const handleInterviewMark = async (id) => {
    const { value: ipAddress } = await Swal.fire({
      title: "Inteview Mark and Comment",
      icon: "success",
      html:
        '<input type="number" id="interview-mark" min=0 max=10 class="swal2-input w-[90%] mx-auto focus:outline-none text-base px-2"  placeholder="Interview Mark">' +
        '<textarea style="height:100px" type="text" id="interview-comment" class="swal2-input w-[90%] mx-auto focus:outline-none text-base px-2" placeholder="Interview Comment"></textarea>',
      showCancelButton: true,
      focusConfirm: false,
      customClass: {
        title: "text-xl",
        icon: "inverview_Icon",
        confirmButton: "text-base py-1",
        cancelButton: "text-base py-1",
      },
      preConfirm: async () => {
        const interviewMarkInput = document.getElementById("interview-mark");
        const interviewMark = interviewMarkInput.value;
        const interViewCommentInput =
          document.getElementById("interview-comment");
        const interViewComment = interViewCommentInput.value;
        if (!interviewMark) {
          Swal.showValidationMessage("Please Enter Interview Mark");
        } else {
          const res = await changeVisitAPI(id, token, {
            interViewMark: interviewMark,
            interViewComment: interViewComment,
          });
          if (res && res?.status === 200) {
            setShow(false);
            Swal.fire("Interview Mark Added");
            setReload(reload + 1);
          }
        }
      },
    });
  };

  return (
    <div
      className={`w-screen h-screen p-10 bg-[rgba(100,100,100,0.8)] ${
        show ? "flex" : "hidden"
      } items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-[10000]`}
    >
      <div
        className=" container relative h-full md:w-[80%] md:h-[90vh] p-6 flex flex-col justify-center items-center bg-white 
    shadow-2xl rounded-lg shadow-[rgba(0,0,0,0.5)] "
      >
        {/* notice details  */}
        <div
          className="w-full h-full  flex flex-col gap-y-2 rounded-sm p-1 overflow-y-scroll scroll-smooth 
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
      scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1 "
        >
          <div className=" flex items-center gap-5  justify-center border-b-2 pb-2">
            <div>
              <div className=" w-24 h-24 rounded-full ring-2 overflow-hidden">
                <img src={application?.photo?.url} alt="" />
              </div>
            </div>

            <div className="w-full ">
              <div>
                <h1 className=" my-1 text-xl font-semibold text-textColor">
                  Name:{" "}
                  {application?.name && (
                    <span className="ml-1">{application?.name}</span>
                  )}
                </h1>
                <h2 className=" text-sm font-semibold text-textColor">
                  Gmail: <span className="ml-1">{application?.email}</span>
                </h2>
                <h2 className=" my-1 text-sm font-semibold text-textColor">
                  Phone: <span className="ml-1">{application?.phone}</span>
                </h2>
                <h2 className=" my-1 text-sm text-textColor font-semibold ">
                  Gender:
                  <span className=" ml-1">{application?.gender}</span>
                </h2>
                <h2 className=" my-1 text-sm font-semibold text-textColor ">
                  Age: <span className="ml-1">{application?.age}</span>
                </h2>
              </div>
            </div>
          </div>
          <div>
            <h1 className=" text-lg text-secondary">Skills</h1>
            <div className=" flex-wrap my-2 text-textColor">
              {application?.skills}
            </div>
          </div>
          <div>
            <h1 className=" text-lg text-secondary">
              Why Do You Want to Join With Us
            </h1>
            <div className=" flex-wrap my-2 text-textColor">
              {application?.reasonOfJoin}
            </div>
          </div>
          <div>
            <h1 className=" text-lg text-secondary">
              Which Things Sets You Apart From Other
            </h1>
            <div className=" flex-wrap my-2 text-textColor">
              {application?.reasonOfChoosingYou}
            </div>
          </div>
          <div className="flex items-center gap-3">
            <h1 className=" text-lg  text-secondary">Expected Salary:</h1>
            <div className=" flex items-center gap-4 my-2 text-textColor">
              <p>
                Max:{" "}
                <span className={`text-gray-400`}>
                  {application?.maxSalary || "N/A"}
                </span>
              </p>
              <p>
                Min:{" "}
                <span className={`text-gray-400`}>
                  {application?.minSalary || "N/A"}
                </span>
              </p>
            </div>
          </div>

          {application?.education?.length > 0 && (
            <div className="flex flex-col  ">
              <h1 className=" text-lg  text-secondary">Education</h1>
              <div className=" my-2 text-textColor">
                {application?.education?.map((item, i) => (
                  <div key={i} className=" flex items-center gap-4">
                    <p className="text-sm w-fit px-3 py-2 gap-x-0 text-secondary text-left capitalize">
                      {item?.educationlavel}
                    </p>
                    <p>Result: {item?.result}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {application?.dynamicFields &&
            Object?.keys(application?.dynamicFields)?.map((item, i) => (
              <tr key={i} className="border-b ">
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-secondary text-left capitalize">
                  {item}
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <p
                    className="w-full h-10 whitespace-normal overflow-scroll scroll-smooth 
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
      scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1 pt-3 flex justify-start items-center"
                  >
                    <a
                      target="_blank"
                      href={application?.dynamicFields[item]}
                      rel="noreferrer"
                    >
                      {application?.dynamicFields[item]}
                    </a>
                  </p>
                </td>
              </tr>
            ))}
          {pathname === "/admin/task-submited" && (
            <div className="flex flex-col  gap-3 mb-4">
              <h1 className=" text-lg  text-secondary">Feedback</h1>
              <p className="text-sm text-gray-600">{taskData?.feedback}</p>
            </div>
          )}

          <button className="absolute top-1 right-1">
            <RxCross2 onClick={() => setShow(false)} size={30} />
          </button>
        </div>
        <div className=" w-full p-3 flex items-center justify-between gap-3 bg-bgColor rounded-md overflow-x-auto">
          {application.cvWaiting === false ? (
            <button
              onClick={() =>
                handleWaitingCV(application._id, { cvWaiting: true })
              }
              className="text-center whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-yellow-500 cursor-pointer rounded-full transition-all duration-[0.3s] text-yellow-500 bg-primaryColor bg-transparent flex items-center gap-1"
            >
              <HiOutlineEmojiHappy size={18}></HiOutlineEmojiHappy>
              <p>ADD Consideration</p>
            </button>
          ) : (
            <button
              onClick={() =>
                handleWaitingCV(application._id, { cvWaiting: false })
              }
              className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-yellow-500 cursor-pointer rounded-full transition-all duration-[0.3s] text-yellow-500 bg-primaryColor bg-transparent flex items-center gap-1"
            >
              <HiOutlineEmojiHappy size={18}></HiOutlineEmojiHappy>
              <p>Remove Consideration</p>
            </button>
          )}

          {application.cvShortList === false ? (
            <button
              onClick={() =>
                handleShortList(application._id, { cvShortList: true })
              }
              className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-green-500 cursor-pointer rounded-full transition-all duration-[0.3s] text-green-500 bg-primaryColor bg-transparent flex gap-1 items-center"
            >
              <BiHappyAlt className="text-green-400" size={18}></BiHappyAlt>
              <p>Make Short List</p>
            </button>
          ) : (
            <button
              onClick={() =>
                handleShortList(application._id, { cvShortList: false })
              }
              className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-green-500 cursor-pointer rounded-full transition-all duration-[0.3s] text-green-500 bg-primaryColor bg-transparent flex gap-1 items-center"
            >
              <BiHappyAlt className="text-green-400" size={18}></BiHappyAlt>
              <p>Remove Short List</p>
            </button>
          )}

          <button
            onClick={() => handleCVMark(application._id)}
            className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-primaryColor cursor-pointer rounded-full transition-all duration-[0.3s] text-primaryColor bg-primaryColor bg-transparent flex items-center gap-1"
          >
            <RxBookmark size={18}></RxBookmark>
            CV Mark
          </button>
          <button
            onClick={() => handleTaskMark(application._id)}
            className="text-center  whitespace-pre mt-2 text-xs w-fit px-2 py-1 border border-primaryColor cursor-pointer rounded-full transition-all duration-[0.3s] text-primaryColor bg-primaryColor bg-transparent flex items-center gap-1"
          >
            <BsFillBookmarkCheckFill size={16}></BsFillBookmarkCheckFill>
            Task Mark
          </button>
          {/* interview call status button */}
          {application.interviewCall === true ? (
            <button
              onClick={() =>
                dynamicStatusUpdate(application._id, { interviewCall: false })
              }
              className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-primaryColor cursor-pointer rounded-full transition-all duration-[0.3s] text-primaryColor bg-primaryColor bg-transparent flex items-center gap-1"
            >
              Remove InterView Call
              <BsFillBookmarkHeartFill size={16}></BsFillBookmarkHeartFill>
            </button>
          ) : (
            <button
              onClick={() =>
                dynamicStatusUpdate(application._id, { interviewCall: true })
              }
              className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-primaryColor cursor-pointer rounded-full transition-all duration-[0.3s] text-primaryColor bg-primaryColor bg-transparent flex items-center gap-1"
            >
              <BsFillBookmarkHeartFill size={16}></BsFillBookmarkHeartFill>
              ADD Interview Call
            </button>
          )}

          {/* ------ */}

          <button
            onClick={() => handleInterviewMark(application._id)}
            className="text-center  whitespace-pre mt-2 text-xs w-fit  px-2 py-1 border border-primaryColor cursor-pointer rounded-full transition-all duration-[0.3s] text-primaryColor bg-primaryColor bg-transparent flex items-center gap-1"
          >
            <BsBookmarks size={16}></BsBookmarks>
            InterView Mark
          </button>
          <a
            href={application?.cv?.url}
            download={`${application?.name}.pdf`}
            target="_blank"
            className="text-center  whitespace-pre mt-2 text-xs w-fit px-2 py-1 border border-blue-500 cursor-pointer rounded-full transition-all duration-[0.3s] text-blue-500 bg-transparent flex items-center gap-1"
            rel="noreferrer"
          >
            <BiShowAlt className="" size={18}></BiShowAlt>
            <p>Show CV</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ApplicationView;
