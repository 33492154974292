import { useFormik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { BsImage } from "react-icons/bs";
import { IoCreateOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import placeholderimg from "../../Assets/images/task.png";
import { updateTeamAPI } from "../../api/teamAPI";
import { createTeamSchema } from "../../validation/schema";

const UpdateTeam = () => {
  const { state } = useLocation();
  const { access_token: token } = useSelector((state) => state.auth);
  const initialValues = {
    name: state?.name,
    designation: state?.designation,
    photo: state?.photo?.url,
  };
  const convertToBase64 = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFieldValue("photo", reader.result);
      };
      reader.onerror = (error) => {
        console.log(error);
      };
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: createTeamSchema,
    onSubmit: async (values, action) => {
      const res = await updateTeamAPI(state?._id, values, token); // api call
      if (res && res.status === 200) {
        toast.success(res.data.message);
        action.resetForm();
      }
    },
  });

  return (
    <div className="md:container w-full bg-white rounded-lg py-4 md:px-10">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <IoCreateOutline /> Update Team
        </h1>
      </div>
      <div className="container w-full my-10 md:p-5 py-3  ">
        <form onSubmit={handleSubmit}>
          <div className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            <div className=" mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter Team Member Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
              />
              {errors.name && touched.name ? (
                <p className="text-sm text-red-500">{errors.name}</p>
              ) : null}
            </div>
            <div className=" mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Designation <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="designation"
                placeholder="Enter Team Member Designation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.designation}
                className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
              />
              {errors.designation && touched.designation ? (
                <p className="text-sm text-red-500">{errors.designation}</p>
              ) : null}
            </div>
            <div className="mx-auto w-full md:mx-0 ">
              <label className="text-[1rem] font-medium text-gray-700 ">
                Photo <span className="text-red-600">*</span>
              </label>
              <div
                className="text-gray-900
placeholder-transparent rounded-md
 placeholder:text-3xl flex items-center gap-x-4"
              >
                <label
                  className="w-full bg-white md:w-[80%] border text-sm whitespace-nowrap outline-none px-[0.8rem] py-[0.5rem] rounded-md border-gray-300"
                  htmlFor="photo"
                >
                  <BsImage className="text-secondary text-lg inline-block mr-1 text-prborder-primaryColor" />
                  Choose Image
                </label>
                <input
                  type="file"
                  name="photo"
                  className="hidden"
                  onChange={(e) => {
                    convertToBase64(e.target.files[0]);
                  }}
                  onBlur={handleBlur}
                  id="photo"
                />
                <div className="">
                  <img
                    className="w-10 h-10 rounded-full object-contain"
                    src={values?.photo || placeholderimg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto w-full md:mx-0  flex justify-center gap-x-4 items-center pt-10">
            <button
              type="submit"
              className=" text-center flex justify-center items-center text-white px-[0.8rem] py-[0.5rem] border hover:border-primaryColor rounded-full transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateTeam;
