import asse39 from "../Assets/images/servicesicons/Asset 39.svg";
import asse38 from "../Assets/images/servicesicons/Asset 38.svg";
import asse37 from "../Assets/images/servicesicons/Asset 37.svg";
import asse36 from "../Assets/images/servicesicons/Asset 36.svg";
import asse35 from "../Assets/images/servicesicons/Asset 35.svg";
export const networkData = [
  {
    img: asse39,
    title: "Branding",
    description:
      "Maximizing brand visibility with most appropriate display, video and verities of other innovative ads placements.",
  },
  {
    img: asse38,
    title: "Association",
    description:
      "Masterkey’s partnership with global advertising networks, performance marketing platforms and highly effective publishers enables the advertising network operate effectively.",
  },
  {
    img: asse37,
    title: "Performance",
    description:
      "Measure performance of your advertisement with solid analytics for mobile and desktop devices.",
  },
  {
    img: asse36,
    title: "Targeting",
    description:
      "Get the most out of your advertisements through precise ad targeting, access to updated data of user profiles which helps you grow customer and users.",
  },
  {
    img: asse35,
    title: "Data Intelligence",
    description:
      "MK data intelligence captures all the conversion data and root causes for you to decide precisely about your advertising and digital promotional strategies.",
  },
];
