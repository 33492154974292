import moment from "moment";
import { useEffect, useState } from "react";
import { BsBriefcase, BsHourglassSplit } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { TfiTime } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import exam from "../../Assets/images/exam.svg";
import exam_done from "../../Assets/images/gifs/exam_done.gif";
import no_exam from "../../Assets/images/gifs/no_exam.gif";
import ExamDetails from "../../Components/Exam/ExamDetails";
import BookedExamSchedule from "../../Components/ExamTimeSlot/BookedExamSchedule";
import ExamTimeSlot from "../../Components/ExamTimeSlot/ExamTimeSlot";
import ApplicationStepper from "../../Components/Stepper/ApplicationStepper";
import Error from "../../error/Error";
import { useExamStatusUpdateMutation } from "../../features/exam/examApiSlice";
import { useGetBookedExamScheduleQuery } from "../../features/exam/examTimeSlotApiSlice";
import {
  useAppliedJobDetailsQuery,
  useGetExamScheduleQuery,
} from "../../features/job-application/jobApiSlice";
const JobCardDetails = () => {
  const { timerStopped } = useSelector((state) => state.timer);
  const [showExamDetails, setShowExamDetails] = useState(false);
  const { id } = useParams();
  const { access_token } = useSelector((state) => state.auth);

  const { data: dataJob, isSuccess: isSuccessJob } =
    useAppliedJobDetailsQuery({ access_token, id }) || {};
  const [examStatusUpdate] = useExamStatusUpdateMutation() || {};
  const response = useAppliedJobDetailsQuery({ access_token, id }) || {};
  const { data: dataStatus } = response;
  const {
    data: dataExam,
    isLoading: isLoadingExam,
    isError: isErrorExam,
    isSuccess: isSuccessExam,
  } = useGetExamScheduleQuery(
    {
      access_token,
      jobpostname: dataJob?.jobDetails[0]?.jobpostname,
      jobID: dataJob?.jobDetails[0]?.jobID,
    },
    { skip: isSuccessJob ? false : true }
  ) || {};

  const { data: dataSchedule } =
    useGetBookedExamScheduleQuery(
      {
        access_token,
        jobId: dataJob?.jobDetails[0]?.jobID,
      },
      { skip: !dataJob?.jobDetails[0]?.jobID }
    ) || {};

  // exam duration as formatted
  const getExamDuration = (starttime, endtime) => {
    const startTime = moment(starttime);
    const endTime = moment(endtime);
    const duration = moment.duration(endTime.diff(startTime));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let timeFormat = "";
    if (days > 0) {
      timeFormat = `${days}d-${hours}h-${minutes}m-${seconds}s`;
    } else {
      timeFormat = `${hours}h-${minutes}m-${seconds}s`;
    }
    return timeFormat;
  };
  const matchExamTime = (startime, examtime) => {
    const currentTime = moment();
    return currentTime.isBetween(startime, examtime);
  };
  const isTimeOver = (examtime) => {
    const currentTime = moment();
    const isOver = currentTime.isAfter(moment(examtime));
    return isOver;
  };

  let content = null;
  // loading case
  if (isLoadingExam && !isErrorExam) {
    content = (
      <div className="w-full h-full p-2 flex flex-col justify-center items-center gap-4">
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
        <span className="w-64 h-4 bg-gray-200 animate-pulse"></span>
      </div>
    );
  }
  // success case
  if (isSuccessExam && !isErrorExam) {
    content = (
      <div className="p-4 bg-white shadow-[0px_0px_4px_-1px_rgba(0,0,0,0.2)]">
        {dataExam?.jobDetails?.length !== 0 &&
        dataExam?.jobDetails[0]?.userExamDoneDetails[0]?.userExamStatus ===
          "pending" ? (
          <div>
            <h1 className="text-gray-500 bg-gradient-to-r from-red-50 to-purple-50 font-normal text-lg px-4 py-2 flex items-center gap-2">
              <BsBriefcase />
              Exam Schedule
            </h1>
            <div className=" p-4 flex flex-col gap-2">
              <h1 className="text-base font-medium text-gray-500 flex items-center gap-2">
                <SlCalender /> Start Date:{" "}
                <span className="text-sm font-normal text-gray-500">
                  {moment(dataExam?.jobDetails[0]?.startSlotDate).format(
                    "YYYY-MM-DD"
                  )}
                </span>
              </h1>
              <h1 className="text-base font-medium text-gray-500 flex items-center gap-2">
                <SlCalender />
                End Date:{" "}
                <span className="text-sm font-normal text-gray-500">
                  {moment(dataExam?.jobDetails[0]?.endSlotDate).format(
                    "YYYY-MM-DD"
                  )}
                </span>
              </h1>
              <h1 className="text-base font-medium text-gray-500 flex items-center gap-2">
                <TfiTime />
                Start Time:{" "}
                <span className="text-sm font-normal text-gray-500">
                  {moment(dataExam?.jobDetails[0]?.startSlotDate).format("LT")}
                </span>
              </h1>
              <h1 className="text-base font-medium text-gray-500 flex items-center gap-2">
                <TfiTime /> End Time:{" "}
                <span className="text-sm font-normal text-gray-500">
                  {moment(dataExam?.jobDetails[0]?.endSlotDate).format("LT")}
                </span>
              </h1>
              <h1 className="text-base font-medium text-gray-500 flex items-center gap-2">
                <BsHourglassSplit /> Duration:{" "}
                <span className="text-sm font-normal text-gray-500">
                  {getExamDuration(
                    dataExam?.jobDetails[0]?.startSlotDate,
                    dataExam?.jobDetails[0]?.endSlotDate
                  )}
                </span>
              </h1>
              <button
                onClick={() => setShowExamDetails(true)}
                disabled={
                  !matchExamTime(
                    dataExam?.jobDetails[0]?.startSlotDate,
                    dataExam?.jobDetails[0]?.endSlotDate
                  )
                }
                className={`whitespace-pre w-fit flex items-center gap-2 bg-secondary py-0.5 px-2 text-sm md:text-base md:px-4 text-white rounded-md
   shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l ${
     !matchExamTime(
       dataExam?.jobDetails[0]?.startSlotDate,
       dataExam?.jobDetails[0]?.endSlotDate
     ) && "cursor-not-allowed"
   }`}
              >
                Start Exam
              </button>
            </div>
          </div>
        ) : dataExam?.jobDetails?.length !== 0 &&
          dataExam?.jobDetails[0]?.userExamDoneDetails[0]?.userExamStatus ===
            "done" ? (
          <div className="flex flex-col justify-center items-center">
            <img src={exam_done} alt="" className="w-32" />
            <p className="text-gray-400 mt-10 text-center">Exam Time is Over</p>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <img src={no_exam} alt="" className="w-32" />
            <p className="text-gray-400 mt-10 text-center">No Exam Available</p>
          </div>
        )}
      </div>
    );
  }
  // error case
  if (isErrorExam && !isSuccessExam) {
    content = (
      <>
        <Error />
      </>
    );
  }

  // checking if the time is over or not
  useEffect(() => {
    if (isTimeOver(dataExam?.jobDetails[0]?.endSlotDate)) {
      examStatusUpdate({
        access_token,
        id: dataExam?.jobDetails[0]?.examDetails[0]?._id,
      });
    }
  }, []);
  return (
    <div className="px-3 md:px-5 py-2">
      <div>
        <h1 className="text-xl  text-gray-500 font-semibold flex items-center gap-2">
          {dataJob?.jobDetails[0]?.jobpostname}
        </h1>
        <p className="text-xs text-gray-400">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic
          laudantium, at quod exercitationem asperiores atque quibusdam
          distinctio deleniti ex fuga!
        </p>
      </div>
      <ApplicationStepper response={response} />
      {dataStatus?.jobDetails[0]?.cvShortList ? (
        <div>
          {!dataSchedule?.isCreated && (
            <ExamTimeSlot
              jobId={dataJob?.jobDetails[0]?.jobID}
              jobpostname={dataJob?.jobDetails[0]?.jobpostname}
            />
          )}
          {dataSchedule?.isCreated && (
            <BookedExamSchedule data={dataSchedule?.isCreated} />
          )}
          <div className="w-full md:w-[90%] mx-auto mb-4">
            <h1 className="text-gray-500 font-normal text-sm md:text-lg px-2 md:px-4 py-2 flex items-center justify-center gap-2">
              <BsBriefcase />
              {dataJob?.jobDetails[0]?.jobpostname}
            </h1>
            {!showExamDetails && (
              <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-4 mt-4">
                {content}
                <div className="p-4 bg-white flex justify-center items-center shadow-[0px_0px_4px_-1px_rgba(0,0,0,0.2)]">
                  <img src={exam} alt="" className="w-60" />
                </div>
              </div>
            )}
            {showExamDetails && !timerStopped && (
              <ExamDetails
                isSuccessJob={isSuccessJob}
                jobpostname={dataJob?.jobDetails[0]?.jobpostname}
                jobID={dataJob?.jobDetails[0]?.jobID}
                startTime={dataExam?.jobDetails[0]?.startSlotDate}
                endTime={dataExam?.jobDetails[0]?.endSlotDate}
                id={dataExam?.jobDetails[0]?.examDetails[0]?._id}
                setShowExamDetails={setShowExamDetails}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full md:w-[90%] mx-auto mb-4">
          <h1 className="font-normal text-gray-500 text-center py-4">
            Sorry! You are not elegible for exam.
          </h1>
        </div>
      )}
    </div>
  );
};

export default JobCardDetails;
