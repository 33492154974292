import { useFormik } from "formik";
import "moment-timezone";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSubmitTaskMutation } from "../../features/exam/examApiSlice";
import { useGetExamDetailsQuery } from "../../features/job-application/jobApiSlice";
import { submitTaskSchema } from "../../validation/schema";
import CountdownTime from "../Timer/CountdownTime";

const ExamDetails = ({
  isSuccessJob,
  jobpostname,
  setShowExamDetails,
  startTime,
  endTime,
  jobID,
  id,
}) => {
  const { access_token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [
    submitTask,
    {
      isLoading: isLoadingTask,
      isSuccess: isSuccessTask,
      isError: isErrorTask,
      error: errorTask,
      data: dataTask,
    },
  ] = useSubmitTaskMutation() || {};
  const { data: dataExamDetails } =
    useGetExamDetailsQuery(
      { access_token, jobpostname, jobID },
      {
        skip: isSuccessJob ? false : true,
        refetchOnReconnect: true,
      }
    ) || {};
  // const getExamDuration = (starttime, endtime) => {
  //   const startTime = moment(starttime);
  //   const endTime = moment(endtime);
  //   const duration = moment.duration(endTime.diff(startTime));
  //   return duration;
  // };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: { submitionLink: "", feedback: "" },
      validationSchema: submitTaskSchema,
      onSubmit: async (values, action) => {
        submitTask({
          access_token,
          bodyData: values,
          jobId: jobID,
          jobName: jobpostname,
        });
        action.resetForm();
      },
    });

  // success message show
  useEffect(() => {
    if (isSuccessTask && !isErrorTask) {
      toast.success(dataTask?.message || "Task Submitted Successfully!");
      navigate("/candidate-dashboard");
    }
  }, [isSuccessTask, isErrorTask, navigate]);

  // error message show
  useEffect(() => {
    if (isErrorTask && !isSuccessTask) {
      toast.error(errorTask?.data?.message || "Submission Failed!");
    }
  }, [isSuccessTask, isErrorTask, errorTask]);

  return (
    <div className="mt-4 w-full min-h-[500px] h-full bg-gray-100 p-4 relative">
      <div className="absolute top-4 left-4">
        <CountdownTime startDate={startTime} endDate={endTime} id={id} />
      </div>
      <h1 className="text-xl text-gray-500 text-center">
        Online Assesment for{" "}
        <span className="text-primaryColor font-medium">
          {dataExamDetails?.jobDetails[0]?.examJobPostName}
        </span>
      </h1>

      <button
        onClick={() => setShowExamDetails(false)}
        className="absolute top-4 right-4 px-4 py-1.5 text-sm text-white bg-[#765da8] hover:bg-[#49376d] "
      >
        Close
      </button>
      {/* question paper */}
      <div className="w-full mt-20">
        {dataExamDetails?.jobDetails[0]?.examQuestionInImg?.url && (
          <div className="w-[90%] mx-auto mt-20 flex justify-center">
            <img
              src={dataExamDetails?.jobDetails[0]?.examQuestionInImg?.url}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>
        )}
        {dataExamDetails?.jobDetails[0]?.examQuestionInText && (
          <div className="px-6  text-sm my-3 ">
            {ReactHtmlParser(
              dataExamDetails?.jobDetails[0]?.examQuestionInText
            )}
          </div>
        )}
        {/* submission form */}
        <form
          onSubmit={handleSubmit}
          className="w-full px-6 flex flex-col gap-4"
          action=""
        >
          <div className="">
            <label className="text-sm text-gray-500 font-normal" htmlFor="">
              Github Url <span className="text-xl text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full border outline-none px-4 py-2 text-sm textgray-500"
              name="submitionLink"
              value={values.submitionLink}
              placeholder="Paste your task github repo url.."
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.submitionLink && touched.submitionLink ? (
              <p className="text-xs mt-1 text-red-500">
                {errors.submitionLink}
              </p>
            ) : null}
          </div>
          <div className="">
            <label className="text-sm text-gray-500 font-normal" htmlFor="">
              Feedback
            </label>
            <textarea
              type="text"
              rows={10}
              className="w-full border outline-none px-4 py-1 text-sm textgray-500"
              name="feedback"
              value={values.feedback}
              placeholder="Your Feedback.."
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-10">
            <button
              type="submit"
              disabled={isLoadingTask}
              className={`whitespace-pre w-fit flex items-center gap-2 bg-secondary py-0.5 px-2 text-sm md:text-base md:px-4 text-white rounded-md
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l ${
               isLoadingTask && "cursor-not-allowed"
             }`}
            >
              {isLoadingTask ? "Please Wait.." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamDetails;
