import React from "react";
import Helmet from "react-helmet";
import bgImg from "../Assets/images/4.png";
import AboutCompany from "../Components/AboutCompany/AboutCompany";
import Banner from "../Components/Banner/Banner";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar/NavBar";
import NewsLetter from "../Components/NewsLetter/NewsLetter";
import Particle from "../Components/Particle/Particle";
import Partner from "../Components/Partner/Partner";
import TotalServices from "../Components/Service/TotalServices";
import SupportDetails from "../Components/SupportDetails/SupportDetails";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Masterkey Technologies Ltd.</title>
      </Helmet>
      <div
        className=" pb-28"
        style={{
          background: `url(${bgImg})`,

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat relative",
        }}
      >
        <Particle></Particle>
        <NavBar />
        <Banner />
      </div>

      <SupportDetails />
      <AboutCompany />
      <TotalServices />
      <Partner />
      <Contact />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Home;
