import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "../CandidateDashboard/Sidebar";
import Topbar from "../CandidateDashboard/Topbar";
const CandidateDashboardLayout = () => {
  const view = useSelector((state) => state.menu.view);

  return (
    <main className=" w-screen min-h-screen overflow-hidden bg-gray-50 ">
      <div className="w-full h-full flex">
        <Sidebar />
        <div
          className={`w-full h-full ${
            view ? "md:ml-[220px]" : "ml-0"
          } transition-all duration-1000`}
        >
          <Topbar />
          <div
            className={`p-4 w-full min-h-screen transition-all duration-1000 bg-white rounded-md ml-2 pr-6`}
          >
            <Outlet />
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </main>
  );
};
export default CandidateDashboardLayout;
