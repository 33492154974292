import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import gifBanner from "../../Assets/images/gifs/partnership.gif";

const PartnerBanner = () => {
  return (
    <div className=" container ">
      <div className="flex items-center justify-between mt-3 flex-wrap-reverse md:flex-nowrap gap-2 gap-y-5">
        <div className=" space-y-8 w-full  md:basis-1/2 mt-6 mx-auto">
          <h1 className=" text-2xl md:text-4xl  lg:text-5xl font-semibold text-textColor tracking-wide">
            Partners
          </h1>
          <p className="  text-left ">
            Our industrial collaborators constitute an essential component of
            our information technology firm. They are some of the most
            forward-thinking and well-respected businesses in the industry,
            which enables us to provide our clients with the very finest goods
            and services that are currently on the market.
          </p>
          <div className=" flex items-center gap-6 ">
            <Link
              to={"/"}
              className=" flex items-center gap-2 text-textColor font-medium text-base"
            >
              Learn More{" "}
              <span>
                <BsArrowRight />
              </span>{" "}
            </Link>
          </div>
        </div>
        <div className=" w-full  md:basis-1/2  px-3 md:px-36 mt-4">
          <img className=" w-full " src={gifBanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PartnerBanner;
