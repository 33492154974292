import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineFilePdf } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { RiMapPinUserFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import placeholderimg from "../../Assets/images/avatar_placeholder.png";
import { applyJobAPI, getAllFieldsAPI } from "../../api/applyJobAPI";
import { showOrHideModal } from "../../features/modal/modalSlice";
import { applyJobSchema } from "../../validation/schema";
import Loading from "../Loading/Loading";
import Commonlayout from "../layout/Commonlayout";
import EducationListField from "./EducationListField";

const ApplyJob = () => {
  const navigate = useNavigate();
  const [applyResData, setApplyResData] = useState({});
  const dispatch = useDispatch();
  const { jobpostname } = useParams();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [filename, setFileName] = useState("Choose Pdf");
  const [filesize, setFileSize] = useState(0);
  const [iamgesize, setImageSize] = useState(0);
  const [filetype, setFileType] = useState("");
  const [inputValues, setInputValues] = useState({}); // for optional field
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    age: "",
    photo: "",
    skills: "",
    reasonOfJoin: "",
    reasonOfChoosingYou: "",
    cv: "",
    minSalary: "",
    maxSalary: "",
    jobID: "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // check file size
  const fileSize = (_size) => {
    // console.log(_size/(824*1024))
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  // converting normal file to base64 string
  const convertBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        values.cv = reader.result;
      };
      reader.onerror = (error) => {
        toast.error(error);
      };
    }
  };

  // converting normal image to base64 string
  const convertImageToBase64 = (file) => {
    if (file?.type?.split("/")[0] !== "image") {
      toast.error("Only Image");
    } else {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setFieldValue("photo", reader.result);
        };
        reader.onerror = (error) => {
          console.log(error);
        };
      }
    }
  };

  // get all optional fields
  useEffect(() => {
    const getAllFields = async () => {
      const res = await getAllFieldsAPI(jobpostname); // api call
      if (res && res?.status === 200) {
        setState(res?.data?.resData[0]);
        setInputFields(res?.data?.resData[0]?.dynamicFields);
      }
    };
    getAllFields();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: applyJobSchema,
    onSubmit: async (values, action) => {
      values.deadline = state?.deadline;
      values.published = state?.createdAt;
      values.jobpostname = state?.jobpostname;
      values.jobcategory = state?.jobcategory;
      values.jobsubcategory = state?.jobsubcategory;
      values.education = education;
      values.dynamicFields = inputValues;
      values.jobID = state?._id;
      if (!values.cv) {
        toast.error("Please select your latest Resume/CV");
      } else if (!values.photo) {
        toast.error("Please select your photo");
      } else if (
        education?.length === 0 ||
        education[0]?.educationlavel === "" ||
        education[0]?.result === ""
      ) {
        toast.error("Please Add Your Education");
      } else if (
        (iamgesize.toString().split(" ")[1] === "MB" ||
          iamgesize.toString().split(" ")[1] === "GB") &&
        Number(iamgesize.toString().split(" ")[0] > 1)
      ) {
        toast.error("Image is too large");
      } else if (
        (filesize.split(" ")[1] === "MB" || filesize.split(" ")[1] === "GB") &&
        Number(filesize.split(" ")[0] > 3)
      ) {
        toast.error("File is too large");
      } else if (filetype !== "application/pdf") {
        toast.error("Only Pdf file allowed");
      } else {
        setLoading(true);
        const res = await applyJobAPI(values, setLoading); // api call
        if (res && res?.status === 201) {
          setApplyResData({ ...res?.data });
          setLoading(false);
          dispatch(showOrHideModal({ isAfterJobApply: true }));
          setInputValues({});
          toast.success("Application Successful!");
          navigate("/join-us");
          action.resetForm();
        }
      }
    },
  });

  console.log(state);
  return (
    <>
      {loading && <Loading loading={true} />}
      {/* <AfterApplyJobModal data={applyResData} /> */}
      <Commonlayout>
        <div className="container w-full md:w-[90%] lg:w-[80%] mx-auto pt-10 px-2 md:px-10">
          <div className="border-b flex gap-1 items-center pb-4">
            <RiMapPinUserFill className="text-2xl text-primaryColor" />
            <h1 className="text-lg text-primaryColor font-semibold">
              Apply Here (
              <span className="text-secondary font-normal">{jobpostname}</span>)
            </h1>
          </div>
          <div className="container w-full lg:w-[90%] my-10 shadow-lg rounded-xl md:p-5 py-3  ">
            <form onSubmit={handleSubmit}>
              <div className="mx-auto w-full grid grid-cols-1 gap-6">
                <div className=" mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Your name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-full border border-gray-300"
                  />
                  {errors.name && touched.name ? (
                    <p className="text-sm text-red-500">{errors.name}</p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-full border border-gray-300"
                  />
                  {errors.email && touched.email ? (
                    <p className="text-sm text-red-500">{errors.email}</p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Phone <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Enter Your Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    className="w-full outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300"
                  />
                  {errors.phone && touched.phone ? (
                    <p className="text-sm text-red-500">{errors.phone}</p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Add Education <span className="text-red-600">*</span>
                  </label>
                  <EducationListField
                    inputs={education}
                    setInputs={setEducation}
                  />
                </div>
                <div className="md:flex gap-x-4">
                  <div className="mx-auto w-full md:mx-0 ">
                    <label className="text-[1rem] font-medium text-gray-700 ">
                      Photo{" "}
                      <span className="text-xs text-gray-400">(Max 1mb)</span>{" "}
                      <span className="text-red-600">*</span>
                    </label>
                    <div className="flex items-center gap-x-4">
                      <label
                        className="w-full bg-white md:w-[80%] border text-sm whitespace-nowrap outline-none px-[0.8rem] py-[0.5rem] rounded-full border-gray-300"
                        htmlFor="photo"
                      >
                        <BsImage className="text-secondary text-lg inline-block mr-1 text-prborder-primaryColor" />
                        Choose Image
                      </label>
                      <input
                        type="file"
                        name="photo"
                        className="hidden"
                        onChange={(e) => {
                          setImageSize(fileSize(e.target?.files[0]?.size));
                          convertImageToBase64(e.target.files[0]);
                        }}
                        onBlur={handleBlur}
                        id="photo"
                      />
                      <div className="">
                        <img
                          className="w-10 h-10 rounded-full object-contain"
                          src={values?.photo || placeholderimg}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto w-full md:mx-0 ">
                    <label
                      className="text-[1rem] font-medium text-textColor"
                      htmlFor=""
                    >
                      Gender <span className="text-red-600">*</span>
                    </label>

                    <div className=" text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300">
                      <select
                        name="gender"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gender}
                        className="w-full outline-none"
                      >
                        <option value="">Choose Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {errors.gender && touched.gender ? (
                      <p className="text-sm text-red-500">{errors.gender}</p>
                    ) : null}
                  </div>
                  <div className="mx-auto w-full md:mx-0 ">
                    <label
                      className="text-[1rem] font-medium text-textColor"
                      htmlFor=""
                    >
                      Age <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      name="age"
                      placeholder="Enter Your Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.age}
                      className="w-full outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300"
                    />
                    {errors.age && touched.age ? (
                      <p className="text-sm text-red-500">{errors.age}</p>
                    ) : null}
                  </div>
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Skills <span className="text-red-600">*</span>
                  </label>
                  <textarea
                    name="skills"
                    rows={4}
                    placeholder="Enter Your Skills"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.skills}
                    className="w-full outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-md border border-gray-300"
                  />
                  {errors.skills && touched.skills ? (
                    <p className="text-sm text-red-500">{errors.skills}</p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Why Do You Want To Join with Us{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <textarea
                    type="text"
                    name="reasonOfJoin"
                    rows={4}
                    placeholder="Why Do You Want To Join with Us"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reasonOfJoin}
                    className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
                  ></textarea>
                  {errors.reasonOfJoin && touched.reasonOfJoin ? (
                    <p className="text-sm text-red-500">
                      {errors.reasonOfJoin}
                    </p>
                  ) : null}
                </div>
                <div className="mx-auto w-full md:mx-0 ">
                  <label
                    className="text-[1rem] font-medium text-textColor"
                    htmlFor=""
                  >
                    Which Things Sets You Apart From Other
                    <span className="text-red-600">*</span>
                  </label>
                  <textarea
                    type="text"
                    name="reasonOfChoosingYou"
                    rows={4}
                    placeholder="Which Things Sets You Apart From Other"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reasonOfChoosingYou}
                    className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
                  ></textarea>
                  {errors.reasonOfChoosingYou && touched.reasonOfChoosingYou ? (
                    <p className="text-sm text-red-500">
                      {errors.reasonOfChoosingYou}
                    </p>
                  ) : null}
                </div>

                {inputFields &&
                  inputFields?.map((field, index) => (
                    <div key={index} className="mx-auto w-full md:mx-0 ">
                      <label
                        className="text-[1rem] font-medium text-textColor"
                        htmlFor=""
                      >
                        {field?.fieldlabel}
                      </label>
                      <input
                        type={field?.fieldtype}
                        name={field?.fieldname}
                        placeholder={`Enter Your ${field?.fieldlabel}`}
                        value={inputValues[field.fieldname] || ""}
                        onChange={handleInputChange}
                        className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-full border border-gray-300"
                      />
                    </div>
                  ))}
                <div className="mx-auto w-full md:mx-0 ">
                  <label className="text-[1rem] font-medium text-gray-700">
                    Drop Your CV/Resume Here{" "}
                    <span className="text-gray-400 text-sm">(max 3mb)</span>
                    <span className="text-red-600">*</span>
                  </label>
                  <div
                    className="text-textColor
placeholder-transparent rounded-md
 placeholder:text-3xl flex items-center gap-x-4"
                  >
                    <label
                      className="w-full  border text-sm whitespace-nowrap outline-none px-[0.8rem] py-[0.5rem] rounded-full border-gray-300"
                      htmlFor="cv"
                    >
                      <AiOutlineFilePdf className="inline-block mr-1 text-prborder-primaryColor" />
                      {filename}
                    </label>
                    <input
                      type="file"
                      name="cv"
                      className="hidden"
                      onChange={(e) => {
                        convertBase64(e.target?.files[0]);
                        setFileName(e.target?.files[0]?.name);
                        setFileSize(fileSize(e.target?.files[0]?.size));
                        setFileType(e.target?.files[0]?.type);
                      }}
                      onBlur={handleBlur}
                      id="cv"
                    />
                  </div>
                </div>
                {/* {state?.employeestatus !== "Internship" && (
                  <div className="mx-auto w-full md:mx-0 ">
                    <label
                      className="text-[1rem] font-medium text-textColor"
                      htmlFor=""
                    >
                      Expected Salary
                    </label>
                    <div className="flex flex-col md:flex-row gap-4 w-[50%]">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          name="minSalary"
                          placeholder="Minimum"
                          min={0}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.minSalary}
                          className="outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300"
                        />
                        {errors.minSalary && touched.minSalary ? (
                          <p className="text-sm text-red-500">
                            {errors.minSalary}
                          </p>
                        ) : null}
                      </div>
                      <div className="flex flex-col">
                        <input
                          name="maxSalary"
                          type="number"
                          min={0}
                          placeholder="Maximum"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maxSalary}
                          className="outline-none text-sm px-[0.8rem] py-[0.5rem] rounded-full border border-gray-300"
                        />
                        {errors.maxSalary && touched.maxSalary ? (
                          <p className="text-sm text-red-500">
                            {errors.maxSalary}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="mx-auto w-full md:mx-0  flex justify-center gap-x-4 items-center pt-10">
                {loading ? (
                  <button
                    disabled
                    className=" text-center flex justify-center items-center text-white bg-primaryColor px-4 py-2 border  rounded-full transition-all duration-[0.3s]"
                  >
                    Processing{" "}
                    <PulseLoader size={8} color="#ffffff" className="ml-2" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className=" text-center flex justify-center items-center text-white px-7 py-2 border hover:border-primaryColor rounded-full transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Commonlayout>
    </>
  );
};

export default ApplyJob;
