import moment from "moment";
import React from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { getExamFormattedTime } from "../../Components/utils/getExamFormattedTime";
import Error from "../../error/Error";
import { useGetAdminBookedExamScheduleQuery } from "../../features/exam/examTimeSlotApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const BookedTimeSlots = () => {
  const { jobId } = useParams();
  const { access_token } = useSelector((state) => state.auth);
  const {
    data: dataSlot,
    isLoading: isLoadingSlot,
    isError: isErrorSlot,
    isSuccess: isSuccessSlot,
  } = useGetAdminBookedExamScheduleQuery(
    { access_token, jobId },
    { refetchOnReconnect: true }
  ) || {};

  let content = null;
  //   loading message
  if (isLoadingSlot && !isErrorSlot) {
    content = (
      <tbody>
        {Array(5)
          ?.fill()
          ?.map((item, index) => (
            <tr key={index} className="border-t border-gray-300">
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div className="flex items-center gap-2">
                  <div>
                    <svg
                      className="text-gray-200 w-10 h-10 dark:text-gray-700"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-2 animate-pulse"></div>

                    <div className="h-2.5 bg-gray-200 rounded-full w-40 animate-pulse"></div>
                  </div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0  text-left">
                <div className="h-3 w-24 bg-gray-200 rounded-full  animate-pulse"></div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0  text-left">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full w-20 mb-2 animate-pulse"></div>

                  <div className="h-2.5 bg-gray-200 rounded-full w-16 animate-pulse"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0  text-left">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full w-20 mb-2 animate-pulse"></div>

                  <div className="h-2.5 bg-gray-200 rounded-full w-16 animate-pulse"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0  text-left">
                <div className="h-2.5 bg-gray-200 rounded-full w-20 mb-2 animate-pulse"></div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-left">
                <div className="h-4 w-4 bg-gray-200 rounded-full mb-2 animate-pulse"></div>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  //   success message
  if (isSuccessSlot && !isErrorSlot && dataSlot?.slot?.length !== 0) {
    content = (
      <tbody>
        {dataSlot?.slot?.map((item, index) => (
          <tr key={index} className="relative bg-white">
            <td className="text-sm py-2 px-3">
              <div>
                <span className="text-xs font-semibold flex items-center gap-2 p-4">
                  <img
                    src={item?.userDetails[0]?.photo?.url}
                    className="w-8 h-8 rounded-full object-cover"
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-500">
                      {item?.userDetails[0]?.name}
                    </span>
                    <span className="text-[12px] font-normal text-gray-500">
                      {item?.userDetails[0]?.email}
                    </span>
                  </div>
                </span>
              </div>
            </td>
            <td className="text-sm py-2 px-3">
              <p className="text-xs font-semibold text-gray-500">
                {item?.jobPostName}
              </p>
            </td>
            <td className="text-sm py-2 px-3">
              <p className="text-xs font-normal text-gray-500">
                Start Date :{" "}
                <span className="font-semibold">
                  {moment(item?.startSlotDate).format("LL")}
                </span>
              </p>
              <p className="text-xs font-normal text-gray-500">
                Start Time :{" "}
                <span className="font-semibold">
                  {moment(item?.startSlotDate).format("LT")}
                </span>
              </p>
            </td>
            <td className="text-sm py-2 px-3">
              <p className="text-xs font-normal text-gray-500">
                End Date :{" "}
                <span className="font-semibold">
                  {moment(item?.endSlotDate).format("LL")}
                </span>
              </p>

              <p className="text-xs font-normal text-gray-500">
                End Time :{" "}
                <span className="font-semibold">
                  {moment(item?.endSlotDate).format("LT")}
                </span>
              </p>
            </td>
            <td className="text-sm py-2 px-3">
              <p className="text-xs font-semibold text-gray-500">
                {getExamFormattedTime(item?.startSlotDate, item?.endSlotDate)}
              </p>
            </td>
            <td className="text-sm py-2 px-3">
              <p className="text-xs font-semibold text-gray-500">
                {item?.slotNumber}
              </p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  //   error message
  if (isErrorSlot && !isSuccessSlot) {
    content = (
      <tbody>
        <tr>
          <td colSpan={6}>
            <Error />
          </td>
        </tr>
      </tbody>
    );
  }

  //   empty message
  if (isSuccessSlot && !isErrorSlot && dataSlot?.slot?.length === 0) {
    content = (
      <tbody>
        <tr>
          <td colSpan={6}>
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <>
      {isLoadingSlot && <Loading loading={true} />}
      <div className=" w-full mx-auto bg-bgColor rounded-lg">
        <div className="flex justify-center items-center ">
          <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
            <IoCalendarOutline /> Booked Time Slots
          </h1>
        </div>
        <div className=" flex flex-col gap-y-2">
          <div className={` flex flex-col gap-4 mt-2`}>
            <div className="w-full h-full overflow-x-auto  ">
              <table
                className="whitespace-nowrap w-full h-full px-4
         transition duration-700 border-separate shadow border-spacing-y-3"
              >
                <thead>
                  <tr className="bg-gray-200">
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Candidate
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Job Post Name
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Start Time
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      End Time
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Duration
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Slot No.
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookedTimeSlots;
