import React from "react";
import taskComplete from "../../Assets/images/task.png";
import rating from "../../Assets/images/rating.png";
import react from "../../Assets/images/react.png";
import event from "../../Assets/images/event.png";

const TotalServices = () => {
  return (
    <div className=" bg-primaryColor py-4 my-10">
      <div className=" container md:flex  grid grid-cols-2 items-center gap-x-3 gap-y-8 md:gap-3  justify-between p-3">
        <div className=" text-white flex flex-col justify-center items-center space-y-1">
          <img className=" w-14" src={taskComplete} alt="" />
          <h1 className=" text-3xl font-bold">120+</h1>
          <p className=" text-sm">Project Completed</p>
        </div>
        <div className=" text-white flex flex-col justify-center items-center space-y-1">
          <img className=" w-14" src={rating} alt="" />
          <h1 className=" text-3xl font-bold">789+</h1>
          <p className=" text-sm">5 Star Rating</p>
        </div>
        <div className=" text-white flex flex-col justify-center items-center space-y-1">
          <img className=" w-14" src={react} alt="" />
          <h1 className=" text-3xl font-bold">30+</h1>
          <p className=" text-sm">Expert Consultants</p>
        </div>
        <div className=" text-white flex flex-col justify-center items-center space-y-1">
          <img className=" w-14" src={event} alt="" />
          <h1 className=" text-3xl font-bold">7+</h1>
          <p className=" text-sm">Events Completed</p>
        </div>
      </div>
    </div>
  );
};

export default TotalServices;
