import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getExamFormattedTime } from "../utils/getExamFormattedTime";

const BookedExamSchedule = ({ data }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="w-full md:w-[90%] mx-auto rounded-sm border mb-4">
      <div className="p-4 space-y-2">
        <h1 className="text-base md:text-lg font-normal text-gray-500">
          Hey! {user?.name}
        </h1>
        <h2 className="text-sm md:text-base font-normal text-gray-400">
          Your jobpost name{" "}
          <span className="text-secondary">{data?.jobPostName}</span>
        </h2>
        <h2 className="text-sm md:text-base font-normal text-gray-400">
          You booked your exam schedule at,{" "}
          <span className="text-gray-700 text-xs md:text-sm">
            {moment(data?.startSlotDate).format("lll") +
              " - " +
              moment(data?.endSlotDate).format("lll")}
          </span>
        </h2>
        <h2 className="text-sm md:text-base text-gray-400 grid-flow-row-dense">
          Total exam time :{" "}
          <span className="text-gray-700">
            {getExamFormattedTime(data?.startSlotDate, data?.endSlotDate)}{" "}
          </span>
        </h2>
      </div>
      <div></div>
    </div>
  );
};

export default BookedExamSchedule;
