import React from "react";
import { BsFolderX } from "react-icons/bs";
const NoDataFound = () => {
  return (
    <div className="w-full px-4 py-6 flex justify-center items-center">
      <div className="flex flex-col items-center">
        <BsFolderX className="text-2xl text-gray-500" />
        <h1 className="text-base font-normal text-gray-500">
          Sorry! No Data Found!
        </h1>
      </div>
    </div>
  );
};

export default NoDataFound;
