import React, { useContext, useEffect, useState } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { SlEnvelopeOpen } from "react-icons/sl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchApplicationContext } from "../../App";
import bangladesh from "../../Assets/images/bangladesh.png";
import avatar_placeholder from "../../Assets/images/mk-icon.jpg";
import { getSearchApplicationDataAPI } from "../../api/applyJobAPI";
import Search from "./Search/Search";

const AdminHeader = () => {
  const { access_token: token } = useSelector((state) => state.auth);

  // search functionality
  const [search, setSearch] = useState("");
  const { setGlobalSearch } = useContext(SearchApplicationContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getSearchedApplicants = async () => {
      if (search) {
        const res = await getSearchApplicationDataAPI(token, search); // api call
        if (res && res.status === 200) {
          setGlobalSearch([...res?.data?.resData[0]?.applicants]);
          navigate(`/admin/application?search=${search}`, {
            state: { search },
          });
        }
      }
    };
    if (search) {
      getSearchedApplicants();
    }
  }, [search]);

  return (
    <div className="w-full h-auto sm:h-16 bg-white border-b-2 flex flex-col-reverse sm:flex-row items-center justify-between px-8">
      <div className="w-full md:w-[50%]">
        <Search search={search} setSearch={setSearch} />
      </div>
      <div className="w-full md:w-[50%] flex justify-center sm:justify-end items-center gap-x-4 text-gray-500 pt-4 sm:pt-0 ">
        <SlEnvelopeOpen />
        <div className="relative transition-all duration-700 cursor-pointer">
          <IoIosNotificationsOutline size={24} />
          <span className="w-4 h-4 flex justify-center items-center rounded-full absolute bg-red-500 bottom-[40%] left-[40%] text-white font-medium text-[0.7rem]">
            3
          </span>
        </div>
        <img src={bangladesh} className="w-7 object-contain " alt="" />
        <img
          src={avatar_placeholder}
          className="w-7 object-contain rounded-full ring-1 ring-primaryColor"
          alt=""
        />
      </div>
    </div>
  );
};

export default AdminHeader;
