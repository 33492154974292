import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ApplyJob from "./Components/ApplyJobs/ApplyJob";
import AllJobs from "./Components/JobPosts/AllJobs";
import Home from "./Pages/Home";
import AllJobsLists from "./Pages/admin/AllJobsList";
import CreateJobPost from "./Pages/admin/CreateJobPost";

import { createContext, useState } from "react";
import { PropagateLoader } from "react-spinners";
import AdminDashboardLayout from "./Components/layout/AdminDashboardLayout";
import CandidateDashboardLayout from "./Components/layout/CandidateDashboardLayout";
import About from "./Pages/About";
import Error404 from "./Pages/Error404";
import JoinUsPage from "./Pages/JoinUsPage";
import Login from "./Pages/Login";
import PartnerPage from "./Pages/PartnerPage";
import Services from "./Pages/Services";
import AllApplications from "./Pages/admin/AllApplications";
import AllBrands from "./Pages/admin/AllBrands";
import AllContacts from "./Pages/admin/AllContacts";
import AllExams from "./Pages/admin/AllExams";
import AllTeams from "./Pages/admin/AllTeams";
import ApplicantSMS from "./Pages/admin/ApplicantSMS";
import ApplicationsPostwise from "./Pages/admin/ApplicationsPostwise";
import BookedTimeSlots from "./Pages/admin/BookedTimeSlots";
import CreateBrand from "./Pages/admin/CreateBrand";
import CreateExam from "./Pages/admin/CreateExam";
import CreateExamTimeSlot from "./Pages/admin/CreateExamTimeSlot";
import CreateTeam from "./Pages/admin/CreateTeam";
import ExamAttemptsUser from "./Pages/admin/ExamAttemptsUser";
import SearchedApplicants from "./Pages/admin/SearchedApplicants";
import TaskSubmited from "./Pages/admin/TaskSubmited";
import UpdateExam from "./Pages/admin/UpdateExam";
import UpdateJobPosts from "./Pages/admin/UpdateJobPosts";
import UpdateTeam from "./Pages/admin/UpdateTeam";
import AccountSetting from "./Pages/user/AccountSetting";
import ContactWithExaminer from "./Pages/user/ContactWithExaminer";
import JobCardDetails from "./Pages/user/JobCardDetails";
import JobStatus from "./Pages/user/JobStatus";
import useAuthCheck from "./hooks/useAuthCheck";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
export const SearchApplicationContext = createContext();

function App() {
  const [globalSearch, setGlobalSearch] = useState([]);

  const authChecked = useAuthCheck();

  return !authChecked ? (
    <>
      <div className="w-screen h-screen flex justify-center items-center">
        <PropagateLoader color="#684F9B" />
      </div>
    </>
  ) : (
    <>
      <SearchApplicationContext.Provider
        value={{ globalSearch, setGlobalSearch }}
      >
        <div className="App">
          <Toaster />
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/about" element={<About />} />

            <Route
              path="/login"
              element={
                <PublicRoute path={"/admin"}>
                  <Login />
                </PublicRoute>
              }
            />

            <Route path="/services" element={<Services />} />
            <Route path="/join-us" element={<JoinUsPage />} />
            <Route path="/partners" element={<PartnerPage />} />
            <Route path="/createjob" element={<CreateJobPost />} />
            <Route path="/apply/job/:jobpostname" element={<ApplyJob />} />

            {/* <Route
              path="/applicant/login"
              element={
                <PublicRoute path="/candidate-dashboard">
                  <ApplicantLogin />
                </PublicRoute>
              }
            /> */}

            <Route path="/alljobs" element={<AllJobs />} />
            {/* <Route path="/career" element={<Career />} /> */}
            <Route path="/career" element={<JoinUsPage />} />
            <Route path="*" element={<Error404 />} />

            {/* Candidate route */}
            <Route
              path="/candidate-dashboard"
              element={
                <PrivateRoute
                  allowedRoles={["Candidate"]}
                  path={"/applicant/login"}
                >
                  <CandidateDashboardLayout />
                </PrivateRoute>
              }
            >
              <Route index element={<JobStatus />} />
              <Route path="job-status/:id" element={<JobCardDetails />} />
              <Route path="account-setting" element={<AccountSetting />} />
              <Route path="contact" element={<ContactWithExaminer />} />
            </Route>

            {/* admin route */}
            <Route
              path="/admin"
              element={
                <PrivateRoute allowedRoles={["Admin"]} path={"/login"}>
                  <AdminDashboardLayout />
                </PrivateRoute>
              }
            >
              <Route index element={<AllJobsLists />} />
              <Route path="exam-attempt-users" element={<ExamAttemptsUser />} />
              <Route path="task-submited" element={<TaskSubmited />} />
              <Route path="create-exam/:id" element={<CreateExam />} />
              <Route path="update-exam/:id" element={<UpdateExam />} />
              <Route
                path="create-exam-time-slot"
                element={<CreateExamTimeSlot />}
              />
              <Route path="create-job" element={<CreateJobPost />} />
              <Route path="update-job/:id" element={<UpdateJobPosts />} />
              <Route path="job-list" element={<AllJobsLists />} />
              <Route path="applications" element={<AllApplications />} />
              <Route
                path="application/postwise"
                element={<ApplicationsPostwise />}
              />
              <Route path="application" element={<SearchedApplicants />} />
              <Route path="create-brand" element={<CreateBrand />} />
              <Route path="brands" element={<AllBrands />} />
              <Route path="contacts" element={<AllContacts />} />
              <Route path="applicant-sms" element={<ApplicantSMS />} />
              <Route path="exams" element={<AllExams />} />
              <Route
                path="exams/booked-time-slots/:jobId"
                element={<BookedTimeSlots />}
              />
              <Route path="create-team" element={<CreateTeam />} />
              <Route path="teams" element={<AllTeams />} />
              <Route path="update-team/:id" element={<UpdateTeam />} />
            </Route>
          </Routes>
        </div>
      </SearchApplicationContext.Provider>
    </>
  );
}

export default App;
