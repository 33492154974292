import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { TfiBag } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../../Components/Loading/Loading";
import { deleteJobPostAPI, getAllJobsAdminAPI } from "../../api/jobpostAPI";

const AllJobsList = () => {
  const [showMenuIndex, setShowMenuIndex] = useState();
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState("");
  const [query, setQuery] = useSearchParams();
  const [jobs, setAllJobs] = useState([]);
  const [reload, setReload] = useState(0);
  const { access_token: token } = useSelector((state) => state.auth);

  useEffect(() => {
    const getAllJobs = async () => {
      setLoading(true);
      const res = await getAllJobsAdminAPI(token); //api call
      if (res && res.status === 200) {
        setLoading(false);
        setAllJobs([...res?.data?.resData]);
      }
    };
    getAllJobs();
  }, [reload]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteJobPostAPI(id, token); // api call
        if (res && res.status === 200) {
          Swal.fire(res.data.message);
          setReload(reload + 1);
        }
      }
    });
  };
  console.log(jobs);
  // search functionality
  // useEffect(() => {
  //   if (search) {
  //     navigate(`/admin/application?search=${search}`, { state: search });
  //   }
  // }, [search]);
  return (
    <>
      {loading && <Loading loading={true} />}
      {/* <Search search={search} setSearch={setSearch} /> */}
      <div className=" w-full mx-auto bg-bgColor rounded-lg">
        <div className="flex justify-center items-center ">
          <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
            <TfiBag /> Job List
          </h1>
        </div>
        <div className=" flex flex-col gap-y-2">
          <div className={` flex flex-col gap-4 mt-2`}>
            {jobs?.length !== 0 && (
              <div className="w-full h-full overflow-x-auto  ">
                <table
                  className="whitespace-nowrap w-full h-full px-4
         transition duration-700 border-separate shadow border-spacing-y-3"
                >
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                        Job Title
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                        Applications
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-center">
                        Viewed | Not Viewed
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-center">
                        Shortlisted
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-center">
                        Interview Call
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobs &&
                      jobs?.map((item, index) => (
                        <tr
                          onClick={() => setShowMenuIndex()}
                          key={index}
                          className="relative bg-white"
                        >
                          <td className="text-sm w-fit flex flex-col p-5 gap-x-0 gap-y-1 text-textColor text-left">
                            <p className=" text-blue-500 font-medium ">
                              {item?.jobpostname}
                            </p>
                            <p className="text-xs font-semibold">
                              Published:{" "}
                              <span className="font-normal">
                                {moment(item?.publishDate).format("LL")}
                              </span>
                            </p>
                            <p className="text-xs font-semibold">
                              Deadline:{" "}
                              <span className="font-normal">
                                {moment(item?.Deadline).format("LL")}
                              </span>
                            </p>
                            <p className="text-xs font-semibold">
                              Job Status:{" "}
                              {new Date(item?.Deadline) > new Date() ? (
                                <span className="text-green-500 font-bold">
                                  Active
                                </span>
                              ) : (
                                <span className="text-red-500 font-bold">
                                  Inactive
                                </span>
                              )}
                            </p>
                          </td>
                          <td className=" text-base w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div className="relative">
                              <Link
                                to={`/admin/application/postwise?jobID=${item?.jobId}`}
                                state={{ jobpostname: item?._id }}
                              >
                                {item?.totalApplications}
                              </Link>
                              <div className="absolute px-1 h-4 -top-2 left-3 flex justify-center items-center rounded-full bg-red-400">
                                <Link
                                  className=" text-white text-[10px]"
                                  to={`/admin/application/postwise?jobID=${item?.jobId}`}
                                  state={{
                                    jobpostname: item?._id,
                                    newApply: true,
                                  }}
                                >
                                  {item?.newCV} New
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td className="text-base text-center w-fit px-3 py-2 gap-x-0 text-textColor">
                            <div className="pr-6">
                              <span className="text-green-400 ">
                                <Link
                                  to={`/admin/application/postwise?jobID=${item?.jobId}`}
                                  state={{
                                    jobpostname: item?._id,
                                    viewStatus: true,
                                  }}
                                >
                                  {item?.totalViewCV}
                                </Link>
                              </span>{" "}
                              |{" "}
                              <span className="text-red-400">
                                <Link
                                  to={`/admin/application/postwise?jobID=${item?.jobId}`}
                                  state={{
                                    jobpostname: item?._id,
                                    viewStatus: false,
                                  }}
                                >
                                  {item?.totalNotViewCV}
                                </Link>
                              </span>
                            </div>
                          </td>
                          <td className="text-base w-fit px-3 py-2 gap-x-0 text-textColor text-center">
                            <Link
                              to={`/admin/application/postwise?jobID=${item?.jobId}`}
                              state={{
                                jobpostname: item?._id,
                                cvShortList: true,
                              }}
                            >
                              {item?.totalShortlist}
                            </Link>
                          </td>
                          <td className="text-base w-fit px-3 py-2 gap-x-0 text-textColor text-center">
                            <Link
                              to={`/admin/application/postwise?jobID=${item?.jobId}`}
                              state={{
                                jobpostname: item?._id,
                                interviewCall: true,
                              }}
                            >
                              {item?.interviewCall}
                            </Link>
                          </td>
                          <td className="text-base w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowMenuIndex(index);
                              }}
                              className="flex justify-center items-center gap-x-2 text-xl w-10 h-10 rounded-full hover:bg-gray-100 transition-all duration-500 cursor-pointer relative"
                            >
                              <BiDotsVerticalRounded />
                              {showMenuIndex === index && (
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className={`absolute right-[95%] w-40 bg-white shadow-[0px_0px_8px_-1px_rgba(0,0,0,0.2)] z-[100] py-2 ${
                                    jobs?.length - 1 === index
                                      ? "bottom-full"
                                      : "top-full"
                                  }`}
                                >
                                  <Link
                                    to={`/admin/create-exam/${item?.jobId}`}
                                    className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                                  >
                                    <AiOutlinePlus /> Create Exam
                                  </Link>

                                  <Link
                                    to={`/admin/update-job/${item?.jobId}`}
                                    state={item}
                                    className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                                  >
                                    <FaEdit /> Update Job Post
                                  </Link>
                                  <span
                                    title="Delete"
                                    onClick={() => handleDelete(item?.jobId)}
                                    className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                                  >
                                    <FaTrashAlt /> Delete Job Post
                                  </span>
                                </div>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {(jobs?.length === 0 || loading) && (
              <div className="w-full h-full overflow-x-auto ">
                <table
                  className=" whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
                >
                  <tbody>
                    {Array(5)
                      ?.fill()
                      ?.map((item, index) => (
                        <tr key={index} className="border-t border-gray-300">
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div className="flex items-center gap-x-2">
                              <div>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    className="max-w-sm animate-pulse"
                                  >
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-54 mb-2"></div>
                                  </div>
                                </p>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    className="max-w-sm animate-pulse"
                                  >
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2"></div>
                                  </div>
                                </p>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    className="max-w-sm animate-pulse"
                                  >
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-2"></div>
                                  </div>
                                </p>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    className="max-w-sm animate-pulse"
                                  >
                                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div
                              role="status"
                              className="max-w-sm animate-pulse"
                            >
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div
                              role="status"
                              className="max-w-sm animate-pulse"
                            >
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div
                              role="status"
                              className="max-w-sm animate-pulse"
                            >
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div
                              role="status"
                              className="max-w-sm animate-pulse"
                            >
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div
                              role="status"
                              className="max-w-sm animate-pulse"
                            >
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllJobsList;
