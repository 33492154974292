import webDev from "../Assets/images/servicesicons/Asset 76.svg";
import webNews from "../Assets/images/servicesicons/Asset 75.svg";
import WordPress from "../Assets/images/servicesicons/Asset 74.svg";
import webdesign from "../Assets/images/servicesicons/Asset 73.svg";
import cloud from "../Assets/images/servicesicons/Asset 72.svg";
import chatBot from "../Assets/images/servicesicons/Asset 71.svg";
import ios from "../Assets/images/servicesicons/Asset 70.svg";
import android from "../Assets/images/servicesicons/Asset 69.svg";
import food from "../Assets/images/servicesicons/Asset 68.svg";
import Tracking from "../Assets/images/servicesicons/Asset 67.svg";
import Blockchain from "../Assets/images/servicesicons/Asset 66.svg";
import WebApplication from "../Assets/images/servicesicons/Asset 65.svg";
import WebsiteRedesign from "../Assets/images/servicesicons/Asset 64.svg";
import Websitemaintenance from "../Assets/images/servicesicons/Asset 63.svg";
import UXUI from "../Assets/images/servicesicons/Asset 62.svg";
import Facebook from "../Assets/images/servicesicons/Asset 61.svg";

export const webcardData = [
  {
    img: webDev,
    title: "Website Development",
  },
  {
    img: webNews,
    title: "Newspaper Website",
  },
  {
    img: WordPress,
    title: "WordPress Development",
  },
  {
    img: webdesign,
    title: "Web Design",
  },
  {
    img: cloud,
    title: "Cloud & Cyber Security",
  },
  {
    img: chatBot,
    title: "Chat Bots",
  },
  {
    img: ios,
    title: "iOS App Development",
  },
  {
    img: android,
    title: "Android App Development",
  },
  {
    img: food,
    title: "Food Delivery App",
  },
  {
    img: Tracking,
    title: "Tracking App",
  },
  {
    img: Blockchain,
    title: "Blockchain Development",
  },
  {
    img: WebApplication,
    title: "Web Application",
  },
  {
    img: WebsiteRedesign,
    title: "Website Redesign",
  },
  {
    img: Websitemaintenance,
    title: "Website maintenance",
  },
  {
    img: UXUI,
    title: "UX/UI Development",
  },
  {
    img: Facebook,
    title: "Facebook Applications",
  },
];
