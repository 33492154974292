import React from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Error from "../../error/Error";
import { useGetAllBrandsQuery } from "../../features/brand/brandApiSlice";
import NoDataFound from "../../no-data/NoDataFound";
import PartnerCardLoading from "../Loading/PartnerCardLoading";

const Partner = () => {
  const { access_token } = useSelector((state) => state.auth);
  const {
    isLoading: isLoadingBrand,
    isSuccess: isSuccessBrand,
    isError: isErrorBrand,
    data: dataBrand,
  } = useGetAllBrandsQuery({ access_token }) || {};

  let content = null;
  if (isLoadingBrand && !isErrorBrand) {
    content = (
      <>
        <div className="container grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {Array(18)
            .fill()
            .map((item, index) => (
              <PartnerCardLoading key={index} />
            ))}
        </div>
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length !== 0) {
    content = (
      <>
        <div className="container grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {dataBrand?.brands.map((brand, i) => (
            <div
              key={i}
              className=" flex items-center justify-center rounded-md h-[110px] bg-white shadow-[0px_2px_2px_#00000040] hover:scale-105 duration-300 "
            >
              <img
                className=""
                src={brand?.photourl ? brand?.photourl : brand?.photo?.url}
                alt=""
              />
            </div>
          ))}
        </div>
      </>
    );
  }
  if (isErrorBrand && !isSuccessBrand) {
    content = (
      <>
        <Error />
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }

  return (
    <>
      <div>
        <p className=" text-secondary text-center mb-8">Our Partners</p>
        <div className="my-10">
          {content}
          <Link
            to="/partners"
            className="flex items-center justify-center mt-5"
          >
            <p className="text-textColor text-center text-sm mr-1">see more</p>
            <HiOutlineArrowLongRight className="text-textColor"></HiOutlineArrowLongRight>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Partner;
