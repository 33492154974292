import React from "react";
import BarLoader from "react-spinners/BarLoader";
const Loading = ({ loading }) => {
  return (
    <div>
      <BarLoader
        className="fixed top-0 left-0 right-0"
        color="#684F9B"
        loading={loading}
        cssOverride={{ display: "block" }}
        size={1080}
        width={"100%"}
        speedMultiplier={2}
      />
    </div>
  );
};

export default Loading;
