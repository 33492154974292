import { apiSlice } from "../api/apiSlice";

export const CEApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createContactWithExaminer: builder.mutation({
      query: ({ access_token, bodyData }) => {
        return {
          url: `/api/v1/create-Contact`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: [""],
    }),
    getAllContact: builder.query({
      query: ({ access_token }) => {
        return {
          url: `/api/v1/get-all-Contacts`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
    deleteApplicantMessage: builder.mutation({
      query: (data) => {
        const { access_token, id } = data;
        return {
          url: `/api/v1/delete-applicant-message/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const {
  useDeleteApplicantMessageMutation,
  useCreateContactWithExaminerMutation,
  useGetAllContactQuery,
} = CEApiSlice;
