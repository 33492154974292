import React from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";

const OptionalFields = ({
  inputs,
  setInputs,
  showOptionalField,
  setShowOptionalField,
}) => {
  const handleChange = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index][e.target.name] = e.target.value;
    setInputs(newInputs);
  };
  const addInput = () => {
    setInputs([
      ...inputs,
      { fieldlabel: "", fieldname: "", fieldtype: "text" },
    ]);
  };
  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   inputs?.forEach((item) => {
  //     if (!item.label || !item.name || !item.type) {
  //       return toast.error("Please add label, name, type");
  //     }
  //   });
  //   if (inputs.length === 0) {
  //     toast.error("Fields Are Empty");
  //   } else {
  //     const res = await createFieldAPI(token, inputs); //api call
  //     if (res && res.status === 201) {
  //       toast.success(res.data.message);
  //       setInputs([]);
  //     }
  //   }
  // };

  return (
    <div className="w-full">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {inputs.map((input, index) => (
            <div key={index}>
              <label>
                Label:
                <input
                  className="w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border border-primaryColor"
                  type="text"
                  name="fieldlabel"
                  value={input.fieldlabel}
                  onChange={(e) => handleChange(e, index)}
                />
              </label>
              <br />
              <label>
                Name:
                <input
                  className="w-full outline-none px-[0.8rem] py-[0.5rem] rounded-md border border-primaryColor"
                  type="text"
                  name="fieldname"
                  value={input.fieldname}
                  onChange={(e) => handleChange(e, index)}
                />
              </label>
              <br />
              <label>
                Type:
                <div className="px-[0.8rem] py-[0.5rem] bg-white rounded-md border border-primaryColor">
                  <select
                    name="fieldtype"
                    className="outline-none w-full"
                    value={input.fieldtype}
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option value="text">Text</option>
                    <option value="email">Email</option>
                    <option value="number">Number</option>
                  </select>
                </div>
              </label>
              <br />
              <div className="p-1 border w-fit rounded-full bg-gray-200 cursor-pointer">
                <AiOutlineDelete
                  size={25}
                  className="text-center flex justify-center items-center text-red-500 "
                  onClick={() => removeInput(index)}
                />
              </div>
              <br />
            </div>
          ))}
        </div>
        <div className="flex gap-x-4">
          <div className="p-1 border w-fit rounded-full bg-gray-200 cursor-pointer">
            <AiOutlinePlus
              size={25}
              className="text-center flex justify-center items-center text-green-500 "
              onClick={() => {
                addInput();
                setShowOptionalField(true);
              }}
            />
          </div>
          {/* <input
            className=" text-center flex justify-center items-center text-white px-4 border hover:border-primaryColor rounded-full transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
            type="button"
            onClick={handleSubmit}
            value="Confirm"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default OptionalFields;
