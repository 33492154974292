import React from "react";
import ServiceBanner from "../Components/Banner/ServiceBanner";
import NavBar from "../Components/NavBar/NavBar";
import bgImg from "../Assets/images/4.png";
import Footer from "../Components/Footer/Footer";
import ServiceProvider from "../Components/Serviceprovider/ServiceProvider";
import Helmet from "react-helmet";
const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services - Masterkey Technologies Ltd.</title>
      </Helmet>
      <div
        className=" pb-28"
        style={{
          background: `url(${bgImg})`,
          backgroundSize: "cover",
        }}
      >
        <NavBar />
        <ServiceBanner />
      </div>
      <ServiceProvider />
      <Footer />
    </>
  );
};

export default Services;
