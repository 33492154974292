// import React from "react";
// import Countdown from "react-countdown";

import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useExamStatusUpdateMutation } from "../../features/exam/examApiSlice";
import { toggleTimer } from "../../features/timer/timerSlice";

// const CountdownTime = ({ totalduration, currentduration }) => {
//   return (
//     <div>
//       <Countdown
//         date={Date.now() + (totalduration - currentduration)}
//         onStart={() => {
//           console.log("Start");
//         }}
//         onComplete={() => {
//           console.log("Done");
//         }}
//         renderer={({ hours, minutes, seconds, completed }) => {
//           if (completed) {
//             return <span>Countdown completed</span>;
//           } else {
//             return (
//           <div className="w-44 flex items-center justify-center text-xl font-semibold text-blue-500 bg-white px-4 py-2 border">
//             <span className="mx-1">
//               {hours}
//               <span className="font-normal text-gray-400">h</span>
//             </span>
//             :
//             <span className="mx-1">
//               {minutes}
//               <span className="font-normal text-gray-400">m</span>
//             </span>
//             :
//             <span className="mx-1">
//               {seconds}
//               <span className="font-normal text-gray-400">s</span>
//             </span>
//           </div>
//         );
//       }
//     }}
//   />
// </div>
//   );
// };

// export default CountdownTime;

const CountdownTime = ({ startDate, endDate, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);
  const [examStatusUpdate] = useExamStatusUpdateMutation() || {};
  const [remainingDuration, setRemainingDuration] = useState("");
  useEffect(() => {
    const calculateRemainingDuration = () => {
      const now = moment();
      const end = moment(endDate);
      const duration = moment.duration(end.diff(now));

      if (duration.asMilliseconds() > 0) {
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setRemainingDuration(`${hours}:${minutes}:${seconds}`);
      } else {
        clearInterval(interval);
        examStatusUpdate({ access_token, id });
        setRemainingDuration("00:00:00");
        dispatch(toggleTimer({ timerStopped: true }));
        toast.success("Time is Over!");
        navigate("/candidate-dashboard");
      }
    };

    const interval = setInterval(calculateRemainingDuration, 1000);

    return () => clearInterval(interval);
  }, [endDate, access_token, dispatch, examStatusUpdate, id]);

  return (
    <div className="w-44 flex flex-col justify-center items-center bg-white px-4 py-1 border">
      <span className="whitespace-pre text-xs bg-blue-500 px-4 py-0.5 text-white">
        Remaining Duration
      </span>
      <h1 className="tracking-widest text-xl font-semibold text-blue-500">
        {remainingDuration}
      </h1>
    </div>
  );
};
export default CountdownTime;
