import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import gifBanner from "../../Assets/images/gifs/services.gif";

const ServiceBanner = () => {
  return (
    <div className=" container ">
      <div className="flex items-center justify-between mt-3 flex-wrap-reverse md:flex-nowrap gap-2 gap-y-5">
        <div className=" space-y-8 w-full  md:basis-1/2 mt-6 mx-auto">
          <h1 className=" text-2xl md:text-4xl  lg:text-5xl font-semibold text-textColor tracking-wide">
            Services
          </h1>
          <p className="  text-left ">
            Masterkey Technologies provides wide ranges of business solutions.
            Our services include mobile and web development, business management
            solutions, digital marketing solutions, affiliate marketing
            solutions, e-commerce solutions and advertising tech solutions.
            Through our highly experienced professionals, we strive to provide
            you with quality work while maintaining a high level of
            professionalism during the entire process.
          </p>
          <div className=" flex items-center gap-6 ">
            <Link
              to={"/"}
              className=" flex items-center gap-2 text-textColor font-medium text-base"
            >
              Learn More{" "}
              <span>
                <BsArrowRight />
              </span>{" "}
            </Link>
          </div>
        </div>
        <div className=" w-full  md:basis-1/2 px-3 mt-4">
          <img className=" w-full" src={gifBanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;
