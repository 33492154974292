import { apiSlice } from "../api/apiSlice";

export const jobPostApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBrand: builder.mutation({
      query: ({ access_token, bodyData }) => {
        return {
          url: `/api/v1/createbrand`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: [""],
    }),
    getAllBrands: builder.query({
      query: ({ access_token }) => {
        return {
          url: `/api/v1/getallbrands`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
    deleteBrand: builder.mutation({
      query: ({ access_token, id }) => {
        return {
          url: `/api/v1/deletebrand/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: [""],
    }),
  }),
});

export const {
  useCreateBrandMutation,
  useGetAllBrandsQuery,
  useDeleteBrandMutation,
} = jobPostApiSlice;
