import React from "react";

const SalaryRadio = ({ check, setCheck, setSalary }) => {
  return (
    <div className="flex gap-x-1">
      <input
        className=""
        type="radio"
        value="Negotiable"
        checked={check === "Negotiable"}
        onChange={(e) => {
          setCheck(e.target.value);
          setSalary("Negotiable");
        }}
      />
      Negotiable
      <input
        className="ml-4"
        type="radio"
        value="Number"
        checked={check === "Number"}
        onChange={(e) => setCheck(e.target.value)}
      />
      Number
    </div>
  );
};

export default SalaryRadio;
