import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  duration: 0,
  durationMinutes: 0,
  startTime: "",
  endTime: "",
  slotNumber: 0,
  timeSlots: [],
  // matchedDates: [],
};

export const calenderSlice = createSlice({
  name: "calenderSlice",
  initialState,
  reducers: {
    setExamSlot: (state, action) => {
      state.duration = action.payload.duration;
      state.durationMinutes = action.payload.durationMinutes;
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
      state.slotNumber = action.payload.slotNumber;
      state.timeSlots = action.payload.timeSlots;
    },
    resetExamSlot: (state) => {
      state.duration = 0;
      state.durationMinutes = 0;
      state.startTime = 0;
      state.endTime = 0;
      state.slotNumber = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setExamSlot, resetExamSlot } = calenderSlice.actions;

export default calenderSlice.reducer;
