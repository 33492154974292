import { apiSlice } from "../api/apiSlice";

export const examApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSingleJobPost: builder.query({
      query: ({ access_token, id }) => {
        return {
          url: `/api/v1/get-jobpost/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
    createExam: builder.mutation({
      query: ({ access_token, bodyData }) => {
        return {
          url: `/api/v1/create-exam`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["Exam"],
    }),
    updateExam: builder.mutation({
      query: ({ access_token, bodyData, jobId, jobName }) => {
        return {
          url: `/api/v1/exam-update/${jobId}/${jobName}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["Exam"],
    }),
    examAttemptsUsers: builder.query({
      query: ({ access_token, jobName, jobId }) => {
        return {
          url: `/api/v1/exam-attampt-user?jobName=${jobName}&jobId=${jobId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["BlockUser"],
    }),
    blockExamAttemptUser: builder.mutation({
      query: ({ access_token, email, jobname, id }) => {
        return {
          url: `/api/v1/exam-attampt-user/${email}/${jobname}/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["BlockUser"],
    }),
    getAllExams: builder.query({
      query: ({ access_token }) => {
        return {
          url: `/api/v1/all-created-exam`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Exam"],
    }),
    getSingleExam: builder.query({
      query: ({ access_token, id }) => {
        return {
          url: `/api/v1/single-created-exam/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["Exam"],
    }),
    submitTask: builder.mutation({
      query: ({ access_token, bodyData, jobId, jobName }) => {
        return {
          url: `/api/v1/submit-exam-ans/${jobName}/${jobId}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["Exam", "task"],
    }),
    examStatusUpdate: builder.mutation({
      query: ({ access_token, id }) => {
        return {
          url: `/api/v1/update-exam-status/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["ExamSchedule"],
    }),
    taskSubmiteduser: builder.query({
      query: ({ access_token, jobName, jobId }) => {
        return {
          url: `/api/v1/task-submition-user?jobName=${jobName}&jobId=${jobId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["task"],
    }),
  }),
});

export const {
  useGetSingleJobPostQuery,
  useCreateExamMutation,
  useUpdateExamMutation,
  useExamAttemptsUsersQuery,
  useBlockExamAttemptUserMutation,
  useGetAllExamsQuery,
  useGetSingleExamQuery,
  useSubmitTaskMutation,
  useExamStatusUpdateMutation,
  useTaskSubmiteduserQuery,
} = examApiSlice;
