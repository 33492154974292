import React from "react";
// import { FiChevronDown } from "react-icons/fi";

import { HashLink } from "react-router-hash-link";

const DropDownMenu = ({ dropDownMenuData, opensub }) => {
  const currentPath = window.location.pathname;
  console.log(currentPath)
  // const [open, setOpen] = useState(true);

  return (
    <>
      {/* DropDown ul li link */}

      <div
        className={` w-[300px] md:w-[350px]  bg-white drop-shadow-lg rounded-md text-left  absolute  top-[4rem] 
         -right-20 px-4 py-2  transition-all duration-1000`}
      >
        <ul
          className="w-full   text-textColor font-semibold text-base flex flex-col 
           gap-3  transition-all duration-1000     px-2 list-none "
        >
          {dropDownMenuData &&
            dropDownMenuData.map((item, i) => (
              <li
                key={i}
                className=" hover:text-secondary transition-all duration-300
                rounded-full w-fit whitespace-pre text-sm   font-normal"
              >
                {currentPath === "/services" ? (
                  <HashLink
                    to={`${item.itemlinksec}`}
                    className=" flex items-center  justify-between cursor-pointer"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={1}
                    duration={500}
                  >
                    {item.itemname}
                  </HashLink>
                ) : (
                  <HashLink
                    to={item.itemlink}
                    className=" flex items-center  justify-between cursor-pointer"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={2}
                    duration={500}
                  >
                    {item.itemname}
                  </HashLink>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default DropDownMenu;
