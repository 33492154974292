import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImg from "../../Assets/images/logo.png";
import { FiChevronDown } from "react-icons/fi";
import DropDownMenu from "./DropDownMenu";
import { aboutDropDownData } from "../../Data/AboutDropdownItem";
import MobileNavBar from "./MobileNavBar";
import { careerDropDrownData } from "../../Data/CareerData";
import { HashLink } from "react-router-hash-link";
const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [upperMenu, setUpperMenu] = useState("");

  const [aboutDropDown] = useState(aboutDropDownData);
  return (
    <div>
      <div className=" container p-2 flex items-center justify-between md:flex-wrap gap-4">
        <div className="">
          <Link to={"/"}>
            <img className=" w-32" src={logoImg} alt="" />
          </Link>
        </div>
        <div className=" md:flex items-center justify-between gap-12 relative  ">
          <ul
            className="  hidden md:w-[80%]   text-textColor font-semibold text-sm md:text-base md:flex  
          items-center gap-8 list-none"
          >
            <li className="hover:text-secondary transition-all duration-300 ">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="hover:text-secondary transition-all duration-300 relative py-5">
              <Link to={"/about"} className=" flex items-center gap-1">
                About
              </Link>
            </li>
            <li
              className="hover:text-secondary transition-all duration-300 relative py-5"
              onMouseEnter={() => {
                setIsOpen(true);
                setUpperMenu("Services");
              }}
              onMouseLeave={() => {
                setIsOpen(false);
                setUpperMenu("");
              }}
            >
              <Link to={"/services"} className=" flex items-center gap-x-1">
                Services
                <span>
                  <FiChevronDown size={18} />
                </span>
              </Link>
              {/* hover dropdown menu */}
              {isOpen && upperMenu === "Services" && (
                <DropDownMenu dropDownMenuData={aboutDropDown} />
              )}
            </li>
            {/* <li
              className="hover:text-secondary transition-all duration-300 relative py-5"
              onMouseEnter={() => {
                setIsOpen(true);
                setUpperMenu("Portfolio");
              }}
              onMouseLeave={() => {
                setIsOpen(false);
                setUpperMenu("");
              }}
            >
              <Link className=" flex items-center gap-1" to={"/"}>
                Portfolio
                <span>
                  <FiChevronDown size={18} />
                </span>
              </Link>
             
              {isOpen && upperMenu === "Portfolio" && (
                <DropDownMenu dropDownMenuData={aboutDropDown} />
              )}
            </li> */}
            <li className="hover:text-secondary transition-all duration-300">
              <Link to={"/partners"}>Partners</Link>
            </li>
            <li
              className="hover:text-secondary transition-all duration-300 relative py-5"
              onMouseEnter={() => {
                setIsOpen(true);
                setUpperMenu("Career");
              }}
              onMouseLeave={() => {
                setIsOpen(false);
                setUpperMenu("");
              }}
            >
              <Link to={"/career"} className=" flex items-center gap-x-1">
                Career
                <span>
                  <FiChevronDown size={18} />
                </span>
              </Link>
              {/* hover dropdown menu */}
              {isOpen && upperMenu === "Career" && (
                <DropDownMenu dropDownMenuData={careerDropDrownData} />
              )}
            </li>
          </ul>
          <div className=" hidden md:block">
            <HashLink to={"/join-us"}>
              <button
                className=" whitespace-pre px-7 py-2 bg-primaryColor 
            text-white rounded-full hover:bg-transparent hover:text-primaryColor
             border-2  border-primaryColor transition-all duration-300"
              >
                Apply Now
              </button>
            </HashLink>
          </div>
        </div>

        {/* mobile menu */}

        <MobileNavBar
          data={{ open, setOpen, isOpen, setIsOpen, upperMenu, setUpperMenu }}
        />
      </div>
    </div>
  );
};

export default NavBar;
