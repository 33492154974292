import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BsArrowLeft, BsCalendar4, BsGlobeAmericas } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCrateExamTimeScheduleMutation } from "../../features/exam/examTimeSlotApiSlice";
import { useAppliedJobDetailsQuery } from "../../features/job-application/jobApiSlice";
import { hideExamSlotForm } from "../../features/toggle/toggleSlice";
import { examSlotBookFormValidation } from "../../validation/schema";

export default function ExamSlotBookForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, access_token } = useSelector((state) => state.auth);
  const [
    crateExamTimeSchedule,
    {
      isLoading: isLoadingCreateSchedule,
      isError: isErrorCreateSchedule,
      isSuccess: isSuccessCreateSchedule,
      error: errorCreateSchedule,
    },
  ] = useCrateExamTimeScheduleMutation() || {};
  const { data: dataJob } =
    useAppliedJobDetailsQuery({ access_token, id }) || {};
  const { durationMinutes, duration, startTime, endTime, slotNumber } =
    useSelector((state) => state.calender);
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    email: "",
    jobPostName: "",
    jobPost: "",
    slotNumber: 0,
    startSlotDate: "",
    endSlotDate: "",
    description: "",
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: examSlotBookFormValidation,
      onSubmit: (values, action) => {
        values.jobPost = dataJob?.jobDetails[0]?.jobID;
        values.jobPostName = dataJob?.jobDetails[0]?.jobpostname;
        values.slotNumber = slotNumber;
        values.startSlotDate = startTime;
        values.endSlotDate = endTime;
        crateExamTimeSchedule({ access_token, bodyData: values });
      },
    });

  // success message
  useEffect(() => {
    if (isSuccessCreateSchedule && !isErrorCreateSchedule) {
      toast.success("Exam Schedule Created Successfully!");
      navigate("/candidate-dashboard");
    }
  }, [isSuccessCreateSchedule, isErrorCreateSchedule]);

  // error message
  useEffect(() => {
    if (!isSuccessCreateSchedule && isErrorCreateSchedule) {
      toast.error(
        errorCreateSchedule?.data?.message || "Exam Schedule Creation Failed!"
      );
    }
  }, [isSuccessCreateSchedule, isErrorCreateSchedule]);
  return (
    <div className=" gap-10 w-full grid grid-cols-1 md:grid-cols-2 px-4 md:px-10 mx-auto mt-5 ">
      <div>
        <button
          onClick={() => dispatch(hideExamSlotForm())}
          className=" rounded-full border p-1.5 transition-all duration-500 text-blue-600 hover:text-white hover:bg-blue-400 mb-4"
        >
          <BsArrowLeft className="text-2xl" />
        </button>
        <h1 className="text-gray-500 font-medium text-xs md:text-sm">
          {user?.name}
        </h1>
        <h1 className="text-gray-500 font-normal text-sm md:text-base">
          Total{" "}
          <span className=" text-primaryColor font-bold">{duration || 0}</span>{" "}
          minutes exam
        </h1>

        <div className="space-y-2 mt-4">
          <span className="flex items-center gap-1 text-gray-500 font-normal text-sm md:text-base">
            <IoTimeOutline />{" "}
            <span className=" text-primaryColor font-bold">
              {duration || 0}
            </span>{" "}
            min
          </span>
          <span className="flex items-center gap-1 text-gray-500 font-normal text-sm md:text-base">
            <BsCalendar4 />{" "}
            {moment(startTime).format("LLL") +
              " - " +
              moment(endTime).format("LLL")}
          </span>
          <span className="flex items-center gap-1 text-gray-400 font-normal text-xs md:text-sm">
            <BsGlobeAmericas /> Asia/Dhaka
          </span>
        </div>
      </div>
      <div>
        <h1 className="text-lg font-medium text-gray-600">Enter Details</h1>
        <form action="" className="space-y-6 my-4" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <label htmlFor="" className="text-sm font-medium text-gray-600">
              Name <span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter your name"
              className="border outline-none px-4 py-2 rounded-md focus:border-blue-300 text-sm"
            />
            {errors.name && touched.name ? (
              <p className="text-xs md:text-sm text-red-400">{errors.name}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-1 md:gap-2">
            <label htmlFor="" className="text-sm font-medium text-gray-600">
              Email <span className="text-red-400">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter your email"
              className="border outline-none px-2 py-1 md:px-4 md:py-2 rounded-md focus:border-blue-300 text-sm"
            />
            {errors.email && touched.email ? (
              <p className="text-xs md:text-sm text-red-400">{errors.email}</p>
            ) : null}
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="" className="text-sm font-medium text-gray-600">
              Job Post <span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              value={dataJob?.jobDetails[0]?.jobpostname || ""}
              readOnly
              placeholder="Enter your job post"
              className="border outline-none px-2 py-1 md:px-4 md:py-2  rounded-md focus:border-blue-300 text-sm"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="" className="text-sm font-medium text-gray-600">
              Please share anything that will help prepare for our meeting.
            </label>
            <textarea
              rows={5}
              name="description"
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Please share anything"
              className="border outline-none px-2 py-1 md:px-4 md:py-2  rounded-md focus:border-blue-300 text-sm"
            />
            {errors.description && touched.description ? (
              <p className="text-xs md:text-sm text-red-400">
                {errors.description}
              </p>
            ) : null}
          </div>
          <button
            type="submit"
            disabled={isLoadingCreateSchedule}
            className="text-xs py-2 px-3 transition-all duration-300 bg-secondary hover:bg-[#c5522f] text-white rounded-full flex items-center gap-1.5 whitespace-pre"
          >
            {isLoadingCreateSchedule ? "Please Wait.." : "Schedule Exam"}
          </button>
        </form>
      </div>
    </div>
  );
}
