import { AiOutlineEdit } from "react-icons/ai";
import { BsBook, BsListCheck } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoCreateOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineEditNote, MdOutlineSms } from "react-icons/md";
import { TbMessageCircle } from "react-icons/tb";
import { TfiBag } from "react-icons/tfi";
import { TiContacts } from "react-icons/ti";
import { VscFileSubmodule } from "react-icons/vsc";

export const adminNavbar = [
  {
    heading: "Dashboard",
    subNav: [
      {
        title: "Job Lists",
        link: "/admin/job-list",
        Icon: TfiBag,
      },
      {
        title: "Applications",
        link: "/admin/applications",
        Icon: HiOutlineClipboardDocumentList,
      },
      {
        title: "Brands",
        link: "/admin/brands",
        Icon: BsListCheck,
      },
      {
        title: "Teams",
        link: "/admin/teams",
        Icon: FaUsers,
      },
      {
        title: "Contacts",
        link: "/admin/contacts",
        Icon: TiContacts,
      },
      {
        title: "Applicant's SMS",
        link: "/admin/applicant-sms",
        Icon: MdOutlineSms,
      },
      {
        title: "Exams",
        link: "/admin/exams",
        Icon: BsBook,
      },
      {
        title: "Create Job Post",
        link: "/admin/create-job",
        Icon: MdOutlineEditNote,
      },
      {
        title: "Create Brand",
        link: "/admin/create-brand",
        Icon: AiOutlineEdit,
      },
      {
        title: "Create Team",
        link: "/admin/create-team",
        Icon: IoCreateOutline,
      },
    ],
  },
];

export const candidateDashboardData = [
  // {
  //   title: "Dashboard",
  //   link: "/candidate-dashboard",
  //   icon: RxDashboard,
  // },
  {
    title: "Your Applied Jobs",
    link: "/candidate-dashboard",
    icon: VscFileSubmodule,
  },
  {
    title: "Contact",
    link: "/candidate-dashboard/contact",
    icon: TbMessageCircle,
    dropDown: true,
  },
  {
    title: "Account Setting",
    link: "/candidate-dashboard/account-setting",
    icon: IoSettingsOutline,
  },
];
