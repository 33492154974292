import React from "react";

const NewsLetter = () => {
  return (
    <div className=" bg-secondary mt-[7%] py-4 ">
      <div
        className="  container mx-auto  py-4  flex items-center justify-between
       flex-wrap md:flex-nowrap gap-10"
      >
        <div className=" space-y-2 ">
          <h1 className=" text-white text-xl font-semibold">
            Subscribe to our Newsletter
          </h1>
          <p className=" text-white text-sm ">
            Stay up to date with the latest news, announcements, job posts, and
            articles.
          </p>
        </div>
        <div className="   flex items-center gap-3 md:flex-nowrap flex-wrap ">
          <input
            className="py-2 px-3 rounded-md outline-none"
            type="text"
            placeholder=" Enter Your Email"
          />
          <button className=" text-white bg-black py-2 px-3 rounded-md">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
