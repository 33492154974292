import axios from "axios";
import { toast } from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;

export const createJobPostAPI = async (data, token) => {
  console.log(data);
  try {
    return await axios.post(`${baseURL}/api/v1/createjob`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateJobpostAPI = async (id, data, token) => {
  try {
    return await axios.put(`${baseURL}/api/v1/updateJobPost/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getAllJobsAdminAPI = async (token) => {
  try {
    return await axios.get(`${baseURL}/api/v1/getalljob`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const getAllJobsAPI = async () => {
  try {
    return await axios.get(`${baseURL}/api/v1/getalljobs`);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteJobPostAPI = async (id, token) => {
  try {
    return await axios.delete(`${baseURL}/api/v1/deleteJobPost/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// optional field
export const createFieldAPI = async (token, inputs) => {
  try {
    return await axios.post(`${baseURL}/api/v1/createfield`, inputs, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
