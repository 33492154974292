import asse29 from "../Assets/images/servicesicons/Asset 29.svg";
import asse28 from "../Assets/images/servicesicons/Asset 28.svg";
import asse27 from "../Assets/images/servicesicons/Asset 27.svg";
import asse26 from "../Assets/images/servicesicons/Asset 26.svg";
import asse25 from "../Assets/images/servicesicons/Asset 25.svg";
import asse24 from "../Assets/images/servicesicons/Asset 24.svg";
import asse23 from "../Assets/images/servicesicons/Asset 23.svg";

export const digitalMarketing = [
  {
    img: asse29,
    title: "Email Marketing",
    description:
      "Generating an impressive ROI, email is a powerhouse channel that can drive incredible incremental revenue with a comprehensive strategy and solid execution.",
  },
  {
    img: asse28,
    title: "Social media marketing",
    description:
      "Our social media marketing team isn’t limited to just making visual and content writing. Our magic happens when you meet the performance-oriented inauguration.",
  },
  {
    img: asse27,
    title: "Multimedia content development",
    description:
      "Multimedia content development is important in today’s world. For instance, images and videos speak a thousand words. People can learn more about a business through a picture and video, more than through a blog post.",
  },
  {
    img: asse26,
    title: "Mobile content solution",
    description:
      "Mobile content management (MCM) is a set of technologies that provide secure access to corporate data on smartphones, tablets, and other endpoint devices. The main component of a mobile content management system is a file storage and file sharing service.",
  },
  {
    img: asse25,
    title: "Email Marketing",
    description:
      "Mobile content management (MCM) is a set of technologies that provide secure access to corporate data on smartphones, tablets, and other endpoint devices. The main component of a mobile content management system is a file storage and file sharing service.",
  },
  {
    img: asse24,
    title: "SEO services",
    description:
      "Capture more online sales with our ecommerce SEO services. Get access the team and tech that have helped our grow and accelerate your earnings. Learn more about our tech-enabled ecommerce SEO services now or request a custom proposal for your store",
  },
  {
    img: asse23,
    title: "Conversation rate optimization",
    description:
      "CRO strategy is designed to convert more of your visitors into paying customers. We will aid you convert your target audiences into paying customers. Our enriched library of user data has proven result oriented strategies",
  },
];
