import React from "react";
import AllContacts from "./AllContacts";

const ApplicantSMS = () => {
  return (
    <div>
      <AllContacts />
    </div>
  );
};

export default ApplicantSMS;
