import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timerStopped: false,
};

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    toggleTimer: (state, action) => {
      state.timerStopped = action.payload.timerStopped;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTimer } = timerSlice.actions;

export default timerSlice.reducer;
