import React from "react";
import { useSelector } from "react-redux";
import ProfileCard from "../../Components/card/ProfileCard";
import { useMyProfileQuery } from "../../features/user/userApiSlice";

const AccountSetting = () => {
  const { access_token } = useSelector((state) => state.auth);
  const response = useMyProfileQuery({ access_token }) || {};
  const { data, isLoading, isError, isSuccess, error } = response;
  return (
    <div className="px-5 py-2">
      <div>
        <h1 className="text-xl  text-gray-500 font-semibold flex items-center gap-2">
          My Account
        </h1>
        <p className="text-xs text-gray-400">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic
          laudantium, at quod exercitationem asperiores atque quibusdam
          distinctio deleniti ex fuga!
        </p>
      </div>
      <ProfileCard response={response} />
    </div>
  );
};

export default AccountSetting;
