import { useFormik } from "formik";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { toast } from "react-hot-toast";
import { BsEnvelope, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import login from "../Assets/images/login.svg";
import Input from "../Components/Admin/Input";
import { useLoginUserMutation } from "../features/auth/authApiSlice";
import { loginSchema } from "../validation/schema";

const Login = () => {
  const navigate = useNavigate();
  const [loginUser, { data, isLoading, isError, isSuccess, error }] =
    useLoginUserMutation() || {};
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: async (values, action) => {
        loginUser({ bodyData: values });
        action.resetForm();
      },
    });
  // success toast
  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success("Admin Login Successfull!");
      navigate("/candidate-dashboard");
    }
  }, [isSuccess, navigate, isError]);

  // error toast
  useEffect(() => {
    if (!isSuccess && isError) {
      toast.error(error.data.message);
    }
  }, [isError, error?.data?.message, isSuccess]);
  return (
    <>
      <Helmet>
        <title>Login - Masterkey Technologies Ltd.</title>
      </Helmet>
      <div className="bg-gray-900 h-screen w-screen relative overflow-hidden flex justify-center items-center">
        <div className="h-40-r w-40-r bg-gradient-to-r from-primaryColor to-secondary rounded-full absolute left-2/3 -top-56 transform rotate-160"></div>
        <div className="h-35-r w-35-r bg-gradient-to-r from-secondary to-primaryColor rounded-full absolute top-96 -left-20 transform rotate-180"></div>

        <div className="py-5 container rounded-lg w-full lg:w-[80%] mx-auto bg-white flex flex-col lg:flex-row gap-6 items-center">
          <div className="w-full md:w-[80%] lg:w-[45%] flex gap-6 flex-col items-center  z-[100]">
            <h2 className="text-[2rem] font-semibold text-primaryColor">
              Login Admin
            </h2>
            <img
              className="w-72 object-contain object-center"
              src={login}
              alt="SignUp"
            />
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-[80%] lg:w-[40%] flex flex-col gap-4 justify-center  z-[100]"
          >
            <Input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Email"
              Icon={BsEnvelope}
            />
            {errors.email && touched.email ? (
              <p className="text-[10px] text-red-500 -mt-2">{errors.email}</p>
            ) : null}
            <Input
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
              Icon={BsEyeSlash}
            />
            {errors.password && touched.password ? (
              <p className="text-[10px] text-red-500 -mt-2">
                {errors.password}
              </p>
            ) : null}
            <div className="flex gap-4 justify-between items-center">
              <button
                type="submit"
                disabled={isLoading}
                className={`py-2 px-4 border border-primaryColor rounded-lg transition-all duration-[0.3s] hover:bg-primaryColor hover:text-bgColor ${
                  isLoading && "cursor-not-allowed"
                }`}
              >
                {isLoading ? "Please Wait.." : "Login"}
              </button>
            </div>
            {/* forgot password */}
            <h6 className="font-semibold text-[0.9rem] text-primaryColor">
              <Link to={"/forgotPassword"}>Forgot password?</Link>
            </h6>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
