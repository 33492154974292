import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineEdit } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { useSelector } from "react-redux";
import placeholderimg from "../../Assets/images/image_placeholder.png";
import { useCreateBrandMutation } from "../../features/brand/brandApiSlice";
import { createBrandSchema } from "../../validation/schema";

const CreateBrand = () => {
  const { access_token } = useSelector((state) => state.auth);
  const [
    createBrand,
    {
      isLoading: isLoadingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
      error: errorCreate,
    },
  ] = useCreateBrandMutation() || {};
  const initialValues = {
    brandname: "",
    homeurl: "",
    photourl: "",
    photo: "",
  };
  const convertToBase64 = (file) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFieldValue("photo", reader.result);
      };
      reader.onerror = (error) => {
        console.log(error);
      };
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: createBrandSchema,
    onSubmit: async (values) => {
      createBrand({ access_token, bodyData: values });
    },
  });

  // success message
  useEffect(() => {
    if (isSuccessCreate && !isErrorCreate) {
      toast.success("Brand Created Successfully!");
    }
  }, [isSuccessCreate, isErrorCreate]);

  // error message
  useEffect(() => {
    if (isErrorCreate && !isSuccessCreate) {
      toast.error(errorCreate?.data?.message || "Brand Creation Failed!");
    }
  }, [isErrorCreate, isSuccessCreate, errorCreate?.data?.message]);

  return (
    <div className="md:container w-full bg-white rounded-lg py-4 md:px-10">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <AiOutlineEdit className="-mb-1" /> Create Brand
        </h1>
      </div>
      <div className="container w-full my-5 md:p-5 py-3  ">
        <form onSubmit={handleSubmit}>
          <div className="mx-auto w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            <div className=" mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Brand Name <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="brandname"
                placeholder="Enter brand name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.brandname}
                className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
              />
              {errors.brandname && touched.brandname ? (
                <p className="text-sm text-red-500">{errors.brandname}</p>
              ) : null}
            </div>
            <div className=" mx-auto w-full md:mx-0 ">
              <label
                className="text-[1rem] font-medium text-gray-700"
                htmlFor=""
              >
                Home Page Link <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                name="homeurl"
                placeholder="Enter homepage link"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.homeurl}
                className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
              />
              {errors.homeurl && touched.homeurl ? (
                <p className="text-sm text-red-500">{errors.homeurl}</p>
              ) : null}
            </div>
            {!values.photo && (
              <div className=" mx-auto w-full md:mx-0 ">
                <label
                  className="text-[1rem] font-medium text-gray-700"
                  htmlFor=""
                >
                  Brand Photo Link <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  name="photourl"
                  placeholder="Enter Brand Photo link"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.photourl}
                  className="w-full outline-none px-[0.8rem] text-sm py-[0.5rem] rounded-md border border-gray-300"
                />
                {errors.photourl && touched.photourl ? (
                  <p className="text-sm text-red-500">{errors.photourl}</p>
                ) : null}
              </div>
            )}
            {!values.photourl && (
              <div className="mx-auto w-full md:mx-0 ">
                <label className="text-[1rem] font-medium text-gray-700">
                  Brand Photo <span className="text-red-600">*</span>
                </label>
                <div
                  className="text-gray-900
placeholder-transparent rounded-md
 placeholder:text-3xl flex items-center gap-x-4"
                >
                  <label
                    className="w-full bg-white md:w-[80%] border text-sm whitespace-nowrap outline-none px-[0.8rem] py-[0.5rem] rounded-md border-gray-300"
                    htmlFor="photo"
                  >
                    <BsImage className="text-secondary text-lg inline-block mr-1 text-prborder-primaryColor" />
                    Choose Image
                  </label>
                  <input
                    type="file"
                    name="photo"
                    className="hidden"
                    onChange={(e) => {
                      convertToBase64(e.target.files[0]);
                    }}
                    onBlur={handleBlur}
                    id="photo"
                  />
                  <div className="">
                    <img
                      className="w-10 h-10 rounded-full object-contain"
                      src={values?.photo || placeholderimg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mx-auto w-full md:mx-0  flex justify-center gap-x-4 items-center pt-10">
            <button
              type="submit"
              disabled={isLoadingCreate}
              className=" text-center flex justify-center items-center text-white px-[0.8rem] py-[0.5rem] border hover:border-primaryColor rounded-full transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
            >
              {isLoadingCreate ? "Please Wait.." : "Create Brand"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBrand;
