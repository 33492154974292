import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { getAllContactsAPI } from "../../api/contactAPI";
import { useDeleteContactMutation } from "../../features/contact/contactApiSlice";
import {
  useDeleteApplicantMessageMutation,
  useGetAllContactQuery,
} from "../../features/contactWithExaminer/CEApiSlice";

const AllContacts = () => {
  const location = useLocation();
  const [contacts, setContacts] = useState([]);
  const { access_token } = useSelector((state) => state.auth);
  const [
    deleteContact,
    { isSuccess: isSuccessContact, isError: isErrorContact },
  ] = useDeleteContactMutation() || {};
  const [
    deleteApplicantMessage,
    { isSuccess: isSuccessMsg, isError: isErrorMsg },
  ] = useDeleteApplicantMessageMutation() || {};
  const {
    data: contactData,
    isLoading,
    isSuccess,
    isError,
  } = useGetAllContactQuery(
    { access_token },
    {
      skip: !(location.pathname === "/admin/applicant-sms"),
    }
  ) || {};
  useEffect(() => {
    if (location.pathname === "/admin/applicant-sms") {
      if (!isLoading && isSuccess && !isError) {
        setContacts([...contactData?.contacts]);
        // console.log(contactData?.contacts);
      }
    } else {
      const getAllContacts = async () => {
        const res = await getAllContactsAPI(access_token); //api call
        if (res && res.status === 200) {
          setContacts([...res?.data?.contacts]);
        }
      };
      getAllContacts();
    }
  }, [
    location?.pathname,
    access_token,
    contactData,
    isLoading,
    isSuccess,
    isError,
  ]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        location.pathname === "/admin/applicant-sms"
          ? deleteApplicantMessage({ access_token, id })
          : deleteContact({ access_token, id });
      }
    });
  };

  // success message
  useEffect(() => {
    if (isSuccessMsg && !isErrorMsg) {
      toast.success("Message Deleted!");
    }
  }, [isSuccessMsg, isErrorMsg]);

  // error message
  useEffect(() => {
    if (!isSuccessMsg && isErrorMsg) {
      toast.error("Message Not Deleted!");
    }
  }, [isSuccessMsg, isErrorMsg]);

  // success message
  useEffect(() => {
    if (isSuccessContact && !isErrorContact) {
      toast.success("Contact Deleted!");
    }
  }, [isSuccessContact, isErrorContact]);

  // error message
  useEffect(() => {
    if (!isSuccessContact && isErrorContact) {
      toast.error("Contact Not Deleted!");
    }
  }, [isSuccessContact, isErrorContact]);
  return (
    <div className="md:container w-full mx-auto bg-white py-4 rounded-lg">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <TiContacts className="-mb-1" />{" "}
          {location.pathname === "/admin/applicant-sms"
            ? "Applicant SMS"
            : "Contacts"}
        </h1>
      </div>
      <div className="container flex flex-col gap-y-2">
        <div className={` flex flex-col gap-4 mt-4 `}>
          <div className="w-full h-full overflow-x-auto ">
            <table
              className="auto whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
            >
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Name
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Email
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Phone
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Description
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {contacts &&
                  contacts?.map((item, index) => (
                    <tr key={index} className="border-t border-gray-300">
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        {item.name}
                      </td>
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        {item.email}
                      </td>
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        {item.phone}
                      </td>
                      <td className="text-sm px-3 py-2 gap-x-0 text-textColor text-left">
                        <p
                          className="w-32 h-10 whitespace-normal overflow-scroll scroll-smooth 
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-white 
      scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-1"
                        >
                          {item.description}
                        </p>
                      </td>
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        <span className="flex items-center gap-x-2 text-xl">
                          {/* <FiEdit
                            title="View"
                            className="text-green-400 cursor-pointer"
                            size={16}
                          /> */}

                          <FaTrashAlt
                            title="Delete"
                            onClick={() => handleDelete(item._id)}
                            className="text-red-400 cursor-pointer"
                            size={16}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllContacts;
