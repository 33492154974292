import { apiSlice } from "../api/apiSlice";

export const examApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExamTimeSlotCandidate: builder.query({
      query: ({ access_token, jobId }) => {
        return {
          url: `/api/v1/exam-time-slot-get/${jobId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["ExamTimeSlot"],
    }),
    crateExamTimeSchedule: builder.mutation({
      query: ({ access_token, bodyData }) => {
        return {
          url: `/api/v1/time-slot-select`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: "ExamTimeSlot",
    }),
    getAdminBookedExamSchedule: builder.query({
      query: ({ access_token, jobId }) => {
        return {
          url: `/api/v1/get-selected-time-slot/${jobId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: "ExamTimeSlot",
    }),
    getBookedExamSchedule: builder.query({
      query: ({ access_token, jobId }) => {
        return {
          url: `/api/v1/check-selected-time-slot/${jobId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: "ExamTimeSlot",
    }),
    createExamTimeSlot: builder.mutation({
      query: ({ access_token, bodyData }) => {
        return {
          url: `/api/v1/exam-time-slot-create`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: "ExamTimeSlot",
    }),
  }),
});

export const {
  useGetExamTimeSlotCandidateQuery,
  useCrateExamTimeScheduleMutation,
  useGetAdminBookedExamScheduleQuery,
  useGetBookedExamScheduleQuery,
  useCreateExamTimeSlotMutation,
} = examApiSlice;
