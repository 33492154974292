import asse60 from "../Assets/images/servicesicons/Asset 60.svg";
import asse59 from "../Assets/images/servicesicons/Asset 59.svg";
import asse58 from "../Assets/images/servicesicons/Asset 57.svg";
import asse57 from "../Assets/images/servicesicons/Asset 56.svg";
import asse56 from "../Assets/images/servicesicons/Asset 55.svg";
import asse55 from "../Assets/images/servicesicons/Asset 54.svg";
import asse54 from "../Assets/images/servicesicons/Asset 53.svg";
import asse53 from "../Assets/images/servicesicons/Asset 52.svg";
// import asse52 from "../Assets/images/servicesicons/Asset 52.svg";
export const affiliateData = [
  {
    img: asse60,
    title: "01",
    description: "Making a proper strategic affiliate business plan.",
  },
  {
    img: asse59,
    title: "02",
    description:
      "Choosing or creating and setting up the right Affiliate tracking software.",
  },
  {
    img: asse58,
    title: "03",
    description:
      "Set up email automation, trigger emails, and pixels, add resources for affiliates, and then test the platform and make it ready to launch.",
  },
  {
    img: asse57,
    title: "04",
    description: "Recruiting active, engaging affiliates that can drive sales.",
  },
  {
    img: asse56,
    title: "05",
    description: "Managing affiliates to grow the affiliate campaign.",
  },
  {
    img: asse55,
    title: "06",
    description: "Fighting with click frauds.",
  },
  {
    img: asse54,
    title: "07",
    description: "Managing payroll.",
  },
  {
    img: asse53,
    title: "08",
    description: "Managing Branding by monitoring affiliates",
  },
];
