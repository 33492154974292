import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { RxCountdownTimer } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setExamSlot } from "../../features/calender/calenderSlice";
import { showExamSlotForm } from "../../features/toggle/toggleSlice";
import { generateDate, months } from "../utils/calender";
import cn from "../utils/cn";
import { getExamDurationAsMinutes } from "../utils/getExamDurationAsMinutes";
import { getExamFormattedTime } from "../utils/getExamFormattedTime";

export default function Calendar({ response }) {
  const [matchedDates] = useState([]);
  const [showNext, setShowNext] = useState();
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const currentDate = dayjs();
  const dispatch = useDispatch();
  const [today, setToday] = useState(currentDate);
  const [selectDate, setSelectDate] = useState(currentDate);
  const { data: dataCalender } = response;
  const isTimeOver = (examtime) => {
    const currentTime = moment();
    const examTime = moment(examtime);
    let over = currentTime.isAfter(examTime);
    return over;
  };
  const dateMatched = (date) => {
    var matched = false;
    dataCalender?.slot[0]?.slotDate?.forEach((item) => {
      if (
        item?.examStartSlotDate.split("T")[0] ===
          moment(date.toISOString()).add(0, "days").format("YYYY-MM-DD") &&
        moment(date.toISOString()).add(0, "days").format("YYYY-MM-DD") >=
          moment().format("YYYY-MM-DD")
      ) {
        if (!isTimeOver(item?.examEndSlotDate)) {
          matched = true;
          return;
        } else {
          matched = false;
        }
      }
    });
    return matched;
  };
  const handlePickDate = (date) => {
    if (matchedDates?.length < 1) {
      dataCalender?.slot[0]?.slotDate?.forEach((item) => {
        if (
          item?.examStartSlotDate.split("T")[0] ===
          moment(date.toISOString()).add(0, "days").format("YYYY-MM-DD")
        ) {
          matchedDates.push({
            startDate: item?.examStartSlotDate,
            endDate: item?.examEndSlotDate,
          });
        }
      });
    }
  };

  // picking the slot time
  const handlePickSlotTime = (item) => {
    dispatch(
      setExamSlot({
        durationMinutes: getExamFormattedTime(item?.startDate, item?.endDate),
        duration: getExamDurationAsMinutes(item?.startDate, item?.endDate),
        startTime: item?.startDate,
        endTime: item?.endDate,
        slotNumber: item?.slotNumber,
      })
    );
  };
  return (
    <div className=" gap-10 w-full grid grid-cols-1 md:grid-cols-2 px-4 md:px-10 mx-auto my-5 ">
      <div className="w-full max-w-[320px] md:max-w-[384px] md:w-96 mx-auto h-fit border p-2 rounded-md">
        <div className="flex justify-between items-center">
          <h1 className="select-none font-semibold text-sm md:text-lg">
            {months[today.month()]}, {today.year()}
          </h1>
          <div className="flex gap-2 md:gap-10 items-center ">
            <GrFormPrevious
              className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(today.month(today.month() - 1));
              }}
            />
            <h1
              className=" cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(currentDate);
              }}
            >
              Today
            </h1>
            <GrFormNext
              className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(today.month(today.month() + 1));
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-7 ">
          {days.map((day, index) => {
            return (
              <h1
                key={index}
                className="text-xs md:text-sm text-center h-10 w-10 md:h-14 md:w-14 grid place-content-center text-gray-500 select-none"
              >
                {day}
              </h1>
            );
          })}
        </div>

        <div className=" grid grid-cols-7 ">
          {generateDate(today.month(), today.year()).map(
            ({ date, currentMonth, today }, index) => {
              return (
                <div
                  key={index}
                  className="p-1 md:p-2 text-center h-10 md:h-14 grid place-content-center text-xs md:text-sm border-t"
                >
                  <button
                    disabled={!dateMatched(date)}
                    className={cn(
                      currentMonth ? "" : "text-gray-400",
                      today ? " text-red-500 font-bold" : "",
                      selectDate.toDate().toDateString() ===
                        date.toDate().toDateString()
                        ? "bg-green-500 text-white"
                        : "",
                      `h-7 w-7 md:h-10 md:w-10 rounded-full grid place-content-center ${
                        dateMatched(date)
                          ? "hover:bg-green-600 hover:text-white bg-blue-400 text-white"
                          : "cursor-default"
                      } transition-all cursor-pointer select-none
                      }`
                    )}
                    onClick={() => {
                      setSelectDate(date);
                      handlePickDate(date);
                    }}
                  >
                    {date.date()}
                  </button>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div onClick={() => setShowNext("")}>
        <h1 className="text-xl font-semibold text-gray-500">Time Slot</h1>
        {/* startTime && endTime */}
        {matchedDates?.length !== 0 ? (
          matchedDates?.map((item, index) => (
            <div
              onClick={(e) => e.stopPropagation()}
              key={index}
              className="mt-4"
            >
              <div className="w-fit h-12 p-2 border rounded-sm flex gap-2 px-4 justify-center items-center overflow-hidden">
                <h1
                  onClick={() => {
                    setShowNext(index);
                    handlePickSlotTime(item);
                  }}
                  className="font-semibold text-blue-500 cursor-pointer"
                >
                  {moment(item?.startDate).format("LT") +
                    " - " +
                    moment(item?.endDate).format("LT")}
                </h1>
                <button
                  onClick={() => {
                    dispatch(showExamSlotForm());
                  }}
                  className={`px-4 py-2 bg-blue-500 text-white transition-all duration-500 ${
                    showNext === index ? "block" : "hidden"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center w-full h-20">
            <h1 className="flex items-center gap-2 text-sm text-gray-400">
              <RxCountdownTimer /> No Slot Selected!
            </h1>
          </div>
        )}
      </div>
      {/* <div className="h-96 w-96 sm:px-5">
        <h1 className="font-semibold">
          Schedule for {selectDate.toDate().toDateString()}
        </h1>
        <p className="text-gray-400">No meetings for today.</p>
      </div> */}
    </div>
  );
}
