import React from "react";
import Contact from "../../Components/Contact/Contact";

const ContactWithExaminer = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};

export default ContactWithExaminer;
