import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import { MdOutlineEditNote } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import timeslot from "../../Assets/images/gifs/timeslot.gif";
import { useCreateExamTimeSlotMutation } from "../../features/exam/examTimeSlotApiSlice";

const CreateExamTimeSlot = () => {
  const [query] = useSearchParams();
  const { access_token } = useSelector((state) => state.auth);
  const [timeSlots, setTimeSlot] = useState([]);
  const [
    createExamTimeSlot,
    {
      isLoading: isLoadingTimeSlot,
      isSuccess: isSuccessTimeSlot,
      isError: isErrorTimeSlot,
      error: errorTimeSlot,
    },
  ] = useCreateExamTimeSlotMutation() || {};

  const initialValues = {
    jobPost: "",
    jobPostName: "",
    examStartSlotDate: new Date(),
    examEndSlotDate: new Date(),
    slotNumber: "",
    slotDate: [],
  };

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit: (values) => {
        values.jobPost = query.get("jobPost");
        values.jobPostName = query.get("jobPostName");
        values.slotDate = [...timeSlots];
        if (values?.slotDate?.length === 0) {
          toast.error("Please add time slot");
        } else {
          createExamTimeSlot({ access_token, bodyData: values });
        }
      },
    });

  // adding time slot to array
  const handleAdd = () => {
    const isExist = timeSlots?.find(
      (item) => item?.slotNumber === values.slotNumber
    );
    if (
      !values.examStartSlotDate ||
      !values.examEndSlotDate ||
      !values.slotNumber
    ) {
      toast.error("Please fill the value");
    } else if (isExist) {
      toast.error("Already Exists!");
    } else {
      setTimeSlot((prev) => [
        ...prev,
        {
          examStartSlotDate: values.examStartSlotDate,
          examEndSlotDate: values.examEndSlotDate,
          slotNumber: values.slotNumber,
        },
      ]);
      setFieldValue("examStartSlotDate", new Date());
      setFieldValue("examEndSlotDate", new Date());
      setFieldValue("slotNumber", "");
    }
  };

  //showing success message when creating exam time slot
  useEffect(() => {
    if (isSuccessTimeSlot && !isErrorTimeSlot) {
      toast.success("Exam Time Slot Created Successfully!");
      setTimeSlot([]);
    }
  }, [isErrorTimeSlot, isSuccessTimeSlot]);

  //showing error message when creating exam time slot
  useEffect(() => {
    if (isErrorTimeSlot && !isSuccessTimeSlot) {
      toast.error(errorTimeSlot?.data?.message || "No Exam Slot Created!");
    }
  }, [isErrorTimeSlot, isSuccessTimeSlot]);
  return (
    <div className="md:container w-full flex flex-col justify-center items-center gap-6 bg-white rounded-lg py-4">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <MdOutlineEditNote className="-mb-1" /> Create Exam Time Slot
        </h1>
      </div>
      <div className="container w-full mx-auto">
        <form
          onSubmit={handleSubmit}
          className="mx-auto w-full flex flex-col-reverse mb-5 md:mb-0 md:grid md:grid-cols-2 gap-6"
        >
          <div className="space-y-4">
            <div className="w-full mx-auto md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                Slot Number <span className="text-red-600">*</span>
              </label>
              <input
                type="number"
                name="slotNumber"
                value={values.slotNumber}
                onChange={handleChange}
                className="w-full outline-none px-4 py-1.5 rounded-sm border cursor-pointer"
                placeholder="Enter exam slot number"
              />
            </div>
            <div className="w-full md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                Start Exam Date <span className="text-red-600">*</span>
              </label>
              <ReactDatePicker
                name="examStartSlotDate"
                selected={values.examStartSlotDate}
                className="w-full border text-gray-600 py-1.5 pl-2 rounded-sm cursor-pointer outline-none"
                onChange={(val) => setFieldValue("examStartSlotDate", val)}
                dateFormat="MM/dd/yyyy h:mm aa"
                minDate={new Date()}
                showTimeInput
                showYearDropdown
                scrollableMonthYearDropdown
              />
            </div>
            <div className="w-full mx-auto md:mx-0">
              <label className="text-[1rem] font-medium" htmlFor="">
                End Exam Date <span className="text-red-600">*</span>
              </label>
              <ReactDatePicker
                name="examEndSlotDate"
                selected={values.examEndSlotDate}
                className="w-full border text-gray-600 py-1.5 pl-2 rounded-sm cursor-pointer outline-none"
                onBlur={handleBlur}
                onChange={(val) => setFieldValue("examEndSlotDate", val)}
                dateFormat="MM/dd/yyyy h:mm aa"
                minDate={new Date()}
                showTimeInput
                showYearDropdown
                scrollableMonthYearDropdown
              />
              {/* {errors.examDuration && touched.examDuration ? (
                <p className="text-[10px] text-red-500 mt-1">
                  {errors.examDuration}
                </p>
              ) : null} */}
            </div>
            <div className="w-full flex items-center gap-4 mx-auto md:mx-0">
              <button
                onClick={handleAdd}
                type="button"
                className={`text-center flex justify-center items-center text-white px-4 py-1 border rounded-sm transition-all duration-[0.3s] bg-secondary hover:bg-[#d15e3b] ${
                  false && "cursor-pointer"
                }`}
              >
                Add Slot
              </button>
              <button
                type="submit"
                disabled={isLoadingTimeSlot}
                className={`text-center flex justify-center items-center text-white px-4 py-1 border rounded-sm transition-all duration-[0.3s] bg-secondary hover:bg-[#d15e3b] ${
                  isLoadingTimeSlot && "cursor-pointer"
                }`}
              >
                {isLoadingTimeSlot ? "Please Wait.." : "Create"}
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img className="w-80" src={timeslot} alt="" />
          </div>
        </form>

        <div className="w-full h-full overflow-x-auto ">
          <table
            className="auto whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
          >
            <thead>
              <tr className="bg-gray-100">
                <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                  Exam Start Time
                </th>
                <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                  Exam End Time
                </th>
                <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                  Slot Number
                </th>
              </tr>
            </thead>
            <tbody>
              {timeSlots?.length !== 0 ? (
                timeSlots?.map((item, index) => (
                  <tr key={index} className="border-t border-gray-300">
                    <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                      {moment(item?.examStartSlotDate).format("LLL")}
                    </td>
                    <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                      {moment(item?.examEndSlotDate).format("LLL")}
                    </td>
                    <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                      {item?.slotNumber}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border-t border-gray-300">
                  <td
                    colSpan={3}
                    className="text-sm w-fit px-3 py-2 gap-x-0 text-gray-400 text-center"
                  >
                    No Slot Selected
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CreateExamTimeSlot;
