import { apiSlice } from "../api/apiSlice";

export const jobApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    appliedJobDetails: builder.query({
      query: ({ access_token, id }) => {
        return {
          url: `/api/v1/single-applied-job/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["ExamTimeSlot"],
    }),
    getExamSchedule: builder.query({
      query: ({ access_token, jobpostname, jobID }) => {
        return {
          url: `/api/v1/get-exam-date-duration/${jobpostname}/${jobID}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["ExamTimeSlot"],
    }),
    getExamDetails: builder.query({
      query: ({ access_token, jobpostname, jobID }) => {
        return {
          url: `/api/v1/get-exam-ref/${jobpostname}/${jobID}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["ExamTimeSlot"],
    }),
  }),
});

export const {
  useAppliedJobDetailsQuery,
  useGetExamScheduleQuery,
  useGetExamDetailsQuery,
} = jobApiSlice;
