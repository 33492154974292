import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const PartnerCardLoading = () => {
  return (
    <div className="rounded-md h-[110px] bg-white shadow-[0px_4px_4px_#00000040]">
      <Skeleton count={1} height={"100%"}></Skeleton>
    </div>
  );
};

export default PartnerCardLoading;
