import React, { useState } from "react";
import servicegif from "../../Assets/images/gifs/website-design.gif";
import { webcardData } from "../../Data/webMobileCardData";
const WebMobile = ({ tab }) => {
  const [data] = useState(webcardData);
  return (
    <div hidden={tab !== 1} className=" transition-all duration-500">
      <div className=" flex items-center justify-between flex-wrap-reverse md:flex-nowrap gap-6">
        <div className="mx-auto w-full md:basis-[64%] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 item gap-4">
          {data?.map((item, i) => (
            <div
              key={i}
              className=" h-[140px]  shadow-[0px_4px_4px_#00000040]

           flex flex-col items-center justify-center gap-3 rounded-md text-center px-2"
            >
              <img className=" w-12" src={item.img} alt="" />
              <p className=" text-textColor text-sm break-words">
                {item.title}
              </p>
            </div>
          ))}
        </div>
        <div className=" w-full md:basis-[45%] md:ml-8">
          <img className="" src={servicegif} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WebMobile;
