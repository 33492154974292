import React from "react";

const InterViewCommentTooltip = ({ interViewComment }) => {
  return (
    <div className="w-40 h-auto text-white rounded-md flex flex-col justify-center items-center gap-y-2">
      <div>
        <p className="text-sm">{interViewComment}</p>
      </div>
    </div>
  );
};

export default InterViewCommentTooltip;
