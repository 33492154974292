export const careerDropDrownData = [
  // {
  //   itemname: "Team",
  //   itemlink: "/career",
  // },
  {
    itemname: "Join Our Team",

    itemlink: "/join-us#join_us",
    itemlinksec: "#join_us",
  },
];
