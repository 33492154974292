import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MdOutlineCreate, MdOutlineTask } from "react-icons/md";
import { TfiBag } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import TimeSlotModal from "../../Components/Modal/TimeSlotModal";
import Error from "../../error/Error";
import { useGetAllExamsQuery } from "../../features/exam/examApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const AllExams = () => {
  const [showMenuIndex, setShowMenuIndex] = useState();
  const [showSlot, setSlotSlow] = useState(false);
  const [slotDetails, setSlotDetails] = useState({});
  const { access_token } = useSelector((state) => state.auth);
  const {
    data: dataExam,
    isLoading: isLoadingExam,
    isSuccess: isSuccessExam,
    isError: isErrorExam,
  } = useGetAllExamsQuery({ access_token }, { refetchOnReconnect: true }) || {};

  let content = null;
  // loading case
  if (isLoadingExam && !isErrorExam) {
    content = (
      <tbody>
        {Array(5)
          ?.fill()
          ?.map((item, index) => (
            <tr key={index} className="border-t border-gray-300">
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div className="flex items-center gap-x-2">
                  <div>
                    <p className="text-primaryColor font-medium flex items-center gap-1">
                      <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-54 mb-2"></div>
                      </div>
                    </p>
                    <p className="text-primaryColor font-medium flex items-center gap-1">
                      <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2"></div>
                      </div>
                    </p>
                    <p className="text-primaryColor font-medium flex items-center gap-1">
                      <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40 mb-2"></div>
                      </div>
                    </p>
                    <p className="text-primaryColor font-medium flex items-center gap-1">
                      <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                      </div>
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                </div>
              </td>
              <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  // success case
  if (isSuccessExam && !isErrorExam && dataExam?.jobDetails?.length !== 0) {
    content = (
      <tbody>
        {dataExam?.jobDetails &&
          dataExam?.jobDetails?.map((item, index) => (
            <tr
              onClick={() => setShowMenuIndex()}
              key={index}
              className="relative bg-white"
            >
              <td className="text-sm w-fit flex flex-col p-5 gap-x-0 gap-y-1 text-textColor text-left">
                <p className="text-xs font-semibold">
                  Department:{" "}
                  <span className="font-normal">{item?.departmentName}</span>
                </p>
                <p className="text-xs font-semibold">
                  Exam Name:{" "}
                  <span className="font-normal">{item?.examJobPostName}</span>
                </p>
                {/* <p className="text-xs font-semibold">
                  Exam Status:{" "}
                  {item?.examStatus === "pending" ? (
                    <span className="text-red-500 font-bold">Pending</span>
                  ) : (
                    <span className="text-green-500 font-bold">Done</span>
                  )}
                </p> */}
              </td>
              <td className="text-sm w-fit p-5 space-y-1 text-textColor">
                <button
                  onClick={() => {
                    setSlotDetails(item?.exam_time_slots[0]);
                    setSlotSlow(true);
                  }}
                  className="px-2 py-0.5 text-sm border rounded-sm text-white bg-gray-500 hover:bg-gray-600"
                >
                  Created Slots
                </button>
                <Link
                  to={`/admin/exams/booked-time-slots/${item?.examJobPost}`}
                  className="ml-2 px-2 py-1 text-sm border rounded-sm text-white bg-gray-500 hover:bg-gray-600"
                >
                  Booked Slots
                </Link>
              </td>
              <td className="text-base w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMenuIndex(index);
                  }}
                  className="flex justify-center items-center gap-x-2 text-xl w-10 h-10 rounded-full hover:bg-gray-100 transition-all duration-500 cursor-pointer relative"
                >
                  <BiDotsVerticalRounded />
                  {showMenuIndex === index && (
                    <span
                      onClick={(e) => e.stopPropagation()}
                      className={`absolute right-[95%] w-44 bg-white shadow-[0px_0px_8px_-1px_rgba(0,0,0,0.2)] z-[100] py-2 ${
                        dataExam?.jobDetails?.length === 1
                          ? "bottom-0"
                          : dataExam?.jobDetails?.length - 1 === index &&
                            dataExam?.jobDetails?.length > 1
                          ? "bottom-full"
                          : "top-full"
                      }`}
                    >
                      <Link
                        to={`/admin/create-exam-time-slot?jobPost=${item?.examJobPost}&jobPostName=${item?.examJobPostName}`}
                        className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                      >
                        <MdOutlineCreate /> Create Exam Time Slot
                      </Link>
                      <Link
                        to={`/admin/update-exam/${item?._id}`}
                        className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                      >
                        <AiOutlineEdit /> Update Exam
                      </Link>

                      <Link
                        to={`/admin/exam-attempt-users?jobName=${item?.examJobPostName}&jobId=${item?.examJobPost}`}
                        className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                      >
                        <FaUsers /> Exam Attempts Users
                      </Link>
                      <Link
                        to={`/admin/task-submited?jobName=${item?.examJobPostName}&jobId=${item?.examJobPost}`}
                        className="px-2 py-1 text-sm text-gray-400 hover:text-gray-700 transition-all duration-500 whitespace-pre rounded-md  font-normal flex items-center gap-1"
                      >
                        <MdOutlineTask /> Task Submited User
                      </Link>
                    </span>
                  )}
                </span>
              </td>
            </tr>
          ))}
      </tbody>
    );
  }
  // error case
  if (isErrorExam && !isSuccessExam) {
    content = (
      <tbody>
        <tr>
          <td colSpan={3}>
            <Error />
          </td>
        </tr>
      </tbody>
    );
  }
  // empty case
  if (isSuccessExam && !isErrorExam && dataExam?.jobDetails?.length === 0) {
    content = (
      <tbody>
        <tr>
          <td colSpan={3}>
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <>
      {isLoadingExam && <Loading loading={true} />}
      <TimeSlotModal
        showSlot={showSlot}
        setSlotSlow={setSlotSlow}
        data={slotDetails}
      />

      <div className=" w-full mx-auto bg-bgColor rounded-lg">
        <div className="flex justify-center items-center ">
          <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
            <TfiBag /> All Exams
          </h1>
        </div>
        <div className=" flex flex-col gap-y-2">
          <div className={` flex flex-col gap-4 mt-2`}>
            <div className="w-full h-full overflow-x-auto  ">
              <table
                className="whitespace-nowrap w-full h-full px-4
         transition duration-700 border-separate shadow border-spacing-y-3"
              >
                <thead>
                  <tr className="bg-gray-200">
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Exam
                    </th>
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Exam Time Slots
                    </th>
                    <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Action
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllExams;
