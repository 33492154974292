import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BaseURL = `${process.env.REACT_APP_BASE_URL}`;
export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL,
  }),
  tagTypes: [
    "Exam",
    "ExamSchedule",
    "task",
    "User",
    "ExamTimeSlot",
    "BlockUser",
  ],
  endpoints: (builder) => ({}),
});
