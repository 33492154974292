import { useFormik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import gifBanner from "../../Assets/images/gifs/contact.gif";
import { createContactAPI } from "../../api/contactAPI";
import { useCreateContactWithExaminerMutation } from "../../features/contactWithExaminer/CEApiSlice";
import { createContactSchema } from "../../validation/schema";

const Contact = () => {
  const { access_token } = useSelector((state) => state.auth);
  const location = useLocation();
  // console.log(location);
  const [
    createContactWithExaminer,
    { data, isLoading, isError, isSuccess, error },
  ] = useCreateContactWithExaminerMutation() || {};

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    description: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: createContactSchema,
      onSubmit: async (values, action) => {
        console.log(location);
        let res;
        if (location?.pathname === "/candidate-dashboard/contact") {
          createContactWithExaminer({
            access_token,
            bodyData: values,
          });

          if (!isLoading && isSuccess && !isError) {
            toast.success("Submit Successful!!!");
            action.resetForm();
          } else if (!isLoading && isSuccess && isError) {
            toast.success(error);
            action.resetForm();
          }
        } else {
          res = await createContactAPI(values); // api call
          if (res && res.status === 201) {
            toast.success(res.data.message);
            action.resetForm();
          }
        }
      },
    });

  return (
    <div
      className={`${
        location?.pathname === "/candidate-dashboard/contact" ? "pt-2" : "pt-10"
      } container`}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 items-center">
        <div>
          {location?.pathname === "/candidate-dashboard/contact" ? (
            <div className=" ">
              <h1 className="text-xl  text-gray-500 font-semibold flex items-center gap-2">
                Contact With Us
              </h1>
              <p className=" text-xs text-gray-400">
                We are here to help you. Please feel free to contact us with
                your queries.
              </p>
            </div>
          ) : (
            <div className="text-center">
              <h1 className="text-2xl text-textColor font-bold">
                Contact With Us
              </h1>
              <p className=" py-3 text-textColor ">
                We are here to help you. Please feel free to <br /> contact us
                with your queries.
              </p>
            </div>
          )}

          <div className="pt-5">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4"
              action=""
            >
              <div>
                <div className="border flex gap-1 items-center px-2 rounded-md">
                  <AiOutlineUser className="text-gray-400"></AiOutlineUser>
                  <input
                    className="py-2 w-full text-sm focus:outline-none"
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Full Name"
                  />
                </div>
                {errors.name && touched.name ? (
                  <p className="text-sm text-red-500">{errors.name}</p>
                ) : null}
              </div>
              <div>
                <div className="border flex gap-1 items-center px-2 rounded-md">
                  <BiPhone className="text-gray-400"></BiPhone>
                  <input
                    className="py-2 w-full text-sm focus:outline-none"
                    type="text"
                    name="phone"
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Phone number"
                  />
                </div>
                {errors.phone && touched.phone ? (
                  <p className="text-sm text-red-500">{errors.phone}</p>
                ) : null}
              </div>
              <div>
                <div className="border flex gap-1 items-center px-2 rounded-md">
                  <AiOutlineMail className="text-gray-400"></AiOutlineMail>
                  <input
                    className="py-2 w-full text-sm focus:outline-none"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                  />
                </div>
                {errors.email && touched.email ? (
                  <p className="text-sm text-red-500">{errors.email}</p>
                ) : null}
              </div>

              <div>
                <textarea
                  rows={7}
                  className="py-2 px-6 rounded-md text-sm border w-full focus:outline-none"
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Description"
                />
                {errors.description && touched.description ? (
                  <p className="text-sm text-red-500">{errors.description}</p>
                ) : null}
              </div>
              <div className="pt-3">
                <button
                  className=" bg-secondary py-[6px] px-4 text-white rounded-md
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className=" w-full  md:basis-1/2 px-3 mt-4">
          <img className=" w-full" src={gifBanner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
