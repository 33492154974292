import axios from "axios";
import { toast } from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;

export const applyJobAPI = async (data, setLoading) => {
  try {
    // console.log(data)
    return await axios.post(`${baseURL}/api/v1/applyjob`, data);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    setLoading(false);
  }
};
export const getAllApplicantsAPI = async (
  token,
  category,
  subcategory,
  page
) => {
  try {
    let link =
      category && subcategory
        ? `/api/v1/allapplications?category=${category}&subcategory=${subcategory}&page=${page}`
        : category
        ? `/api/v1/allapplications?category=${category}&page=${page}`
        : `/api/v1/allapplications?page=${page}`;
    return await axios.get(`${baseURL}${link}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getApplicantsOfPostFilterAPI = async (
  token,
  jobpostname,
  newApply,
  viewStatus,
  cvShortList,
  interviewCall,
  page
) => {
  try {
    console.log(jobpostname);
    let link =
      newApply === true
        ? `/api/v1/getallnewapplicant/${page}?jobID=${jobpostname}&newApply=${true}`
        : viewStatus === true || viewStatus === false
        ? `/api/v1/getallnewapplicant/${page}?jobID=${jobpostname}&viewStatus=${viewStatus}`
        : cvShortList === true
        ? `/api/v1/getallnewapplicant/${page}?jobID=${jobpostname}&cvShortList=${cvShortList}`
        : interviewCall === true
        ? `/api/v1/getallnewapplicant/${page}?jobID=${jobpostname}&interviewCall=${interviewCall}`
        : `/api/v1/getallnewapplicant/${page}?jobID=${jobpostname}`;

    return await axios.get(`${baseURL}${link}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const deleteApplicantsAPI = async (id, token) => {
  try {
    return await axios.delete(`${baseURL}/api/v1/deleteApplicants/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const updateNewCVAPI = async (token, data) => {
  try {
    return await axios.put(`${baseURL}/api/v1/updateNewCv`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const changeVisitAPI = async (id, token, data) => {
  try {
    return await axios.put(`${baseURL}/api/v1/updateapply/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const shortListAPI = async (id, token, status) => {
  try {
    return await axios.put(
      `${baseURL}/api/v1/updateshortlist/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json;charset=UTF-8",
        },
      }
    );
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
export const getAllCategoriesAPI = async (token) => {
  try {
    return await axios.get(`${baseURL}/api/v1/getallcategory`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getAllFieldsAPI = async (jobpostname) => {
  try {
    return await axios.get(`${baseURL}/api/v1/getfields/${jobpostname}`);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

// update key value
export const updateKeyValue = async (id, token, data) => {
  console.log(data);
  try {
    return await axios.put(`${baseURL}/api/v1//update/keyvalue/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error("Server Side Error!");
  }
};

export const getSearchApplicationDataAPI = async (token, search) => {
  try {
    return await axios.get(
      `${baseURL}/api/v1/getapplicants/search?search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json;charset=UTF-8",
        },
      }
    );
  } catch (error) {
    // toast.error(error?.response?.data?.message);
    toast.error("Something Wrong");
  }
};
