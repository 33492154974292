import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myProfile: builder.query({
      query: ({ access_token }) => {
        return {
          url: `/api/v1/myprofile`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: ["User"],
    }),
    myAppliedJobs: builder.query({
      query: (access_token) => {
        return {
          url: `/api/v1/my-applied-job`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        };
      },
      providesTags: [""],
    }),
    updateProfile: builder.mutation({
      query: (data) => {
        const { access_token, bodyData } = data;
        console.log(data);
        return {
          url: `/api/v1/update-profile`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: bodyData,
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useMyProfileQuery,
  useMyAppliedJobsQuery,
} = userApiSlice;
