import React from "react";
import { useSelector } from "react-redux";
import PartnerCardLoading from "../../Components/Loading/PartnerCardLoading";
import Error from "../../error/Error";
import { useGetAllBrandsQuery } from "../../features/brand/brandApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const AllPartner = () => {
  const { access_token } = useSelector((state) => state.auth);
  const {
    isLoading: isLoadingBrand,
    isSuccess: isSuccessBrand,
    isError: isErrorBrand,
    data: dataBrand,
  } = useGetAllBrandsQuery({ access_token }) || {};

  let content = null;
  if (isLoadingBrand && !isErrorBrand) {
    content = (
      <>
        <div className="container grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {Array(18)
            .fill()
            .map((item, index) => (
              <PartnerCardLoading key={index} />
            ))}
        </div>
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length !== 0) {
    content = (
      <>
        <div className="container grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {dataBrand?.brands.map((brand, i) => (
            <div
              key={i}
              className=" flex items-center justify-center rounded-md h-[110px] bg-white shadow-[0px_2px_2px_#00000040] hover:scale-105 duration-300 "
            >
              <img
                className=""
                src={brand?.photourl ? brand?.photourl : brand?.photo?.url}
                alt=""
              />
            </div>
          ))}
        </div>
      </>
    );
  }
  if (isErrorBrand && !isSuccessBrand) {
    content = (
      <>
        <Error />
      </>
    );
  }
  if (isSuccessBrand && !isErrorBrand && dataBrand?.brands?.length === 0) {
    content = (
      <>
        <NoDataFound />
      </>
    );
  }
  return (
    <div className="py-10">
      <div className="text-center">
        <p className="text-secondary uppercase text-sm">OUR Partners</p>
        <h1 className="text-xl md:text-3xl font-semibold pt-2 text-gray-600">
          Some of Our Great Partners
        </h1>
      </div>
      <div>
        <div className="py-10 md:py-20">{content}</div>
      </div>
    </div>
  );
};

export default AllPartner;
