import React, { useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { RxCaretDown } from "react-icons/rx";
import { TbMessageCircle } from "react-icons/tb";
import { TfiSearch } from "react-icons/tfi";
import { VscFileSubmodule } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import other from "../../Assets/icons/other.png";
import { userLoggedOut } from "../../features/auth/authSlice";
import { toggleMenu } from "../../features/menu/menuSlice";

const Topbar = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const view = useSelector((state) => state.menu.view);
  const { user } = useSelector((state) => state.auth);
  // console.log(user);

  const handleLogout = () => {
    dispatch(userLoggedOut());
    localStorage.removeItem("auth");
  };
  return (
    <div
      onClick={() => setShow(false)}
      className={`px-4 py-2 right-0 bg-white flex items-center justify-between  shadow-sm border-b mb-2`}
    >
      <div
        onClick={() => dispatch(toggleMenu())}
        className={`${
          view && " p-1"
        } transition-all duration-300 cursor-pointer rounded-full`}
      >
        <HiMenuAlt1 className={`text-xl text-gray-500 `} />
      </div>

      <div className="flex items-center gap-4">
        <div className="w-full md:w-80 flex items-center px-4 py-1 bg-white border rounded-md">
          <input
            type="text"
            className="w-full outline-none "
            placeholder="Search"
          />
          <span className="">
            <TfiSearch />
          </span>
        </div>
        <div className="hidden md:block">
          <Link to={"/candidate-dashboard/contact"}>
            <TbMessageCircle className="text-gray-500 text-2xl" />
          </Link>
        </div>
        <div className="relative">
          <IoIosNotificationsOutline className="text-2xl text-gray-500" />
          <span className="w-5 h-5 flex justify-center items-center text-white absolute bottom-[50%] left-[50%] text-[9px] rounded-full bg-red-500 border border-white">
            0
          </span>
        </div>
        <div className=" rounded-md flex items-center justify-between gap-2 px-4 py-1 relative cursor-pointer">
          <img
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
            className="w-7 h-7 object-cover rounded-full"
            src={user?.photo?.url ? user?.photo?.url : other}
            alt=""
          />
          <h1
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
            className="text-sm font-normal hidden md:block"
          >
            {user?.name}
          </h1>
          <RxCaretDown
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
            className={`${
              show && "rotate-180"
            } text-lg transition-all duration-500 cursor-pointer`}
          />

          <div
            onClick={(e) => e.stopPropagation()}
            className={`${
              show ? "translate-x-0" : "translate-x-[120%]"
            } transition-all duration-500 absolute top-[102%] right-0 w-40 rounded-md  bg-white shadow-md flex flex-col gap-2 p-2 z-[10]`}
          >
            <Link
              to={`/candidate-dashboard`}
              className="px-2 py-1 text-sm text-gray-400 whitespace-pre rounded-md  font-normal flex items-center gap-1"
            >
              <VscFileSubmodule /> Applied Jobs
            </Link>
            <Link
              to={`/candidate-dashboard/account-setting`}
              className="px-2 py-1 text-sm text-gray-400 whitespace-pre rounded-md  font-normal flex items-center gap-1"
            >
              <IoSettingsOutline />
              Account Setting
            </Link>

            <span
              onClick={handleLogout}
              className="px-2 py-1 text-sm text-gray-400 whitespace-pre rounded-md  font-normal flex items-center gap-1"
            >
              <IoLogOutOutline /> Sign Out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
