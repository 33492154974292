import React from "react";

const NetWorkUpperComponent = () => {
  return (
    <div className="  w-full md:w-[80%] mx-0 md:mx-auto  ">
      <div className="  flex md:items-center flex-col  md:flex-row justify-between  gap-6 my-10">
        <div className=" space-y-3 flex flex-col  justify-center">
          <h1 className="text-center md:text-left text-xl md:text-2xl lg:text-4xl font-bold text-secondary ">
            1500+
          </h1>
          <p className="text-center md:text-left text-sm md:text-base text-textColor break-words">
            Publishers App And Web
          </p>
        </div>
        <div className=" space-y-3 flex flex-col  justify-center">
          <h1 className="text-center md:text-left   text-xl md:text-2xl lg:text-4xl  font-bold text-secondary ">
            10,000+
          </h1>
          <p className="text-center md:text-left text-sm md:text-base text-textColor break-words ">
            Database of publishers
          </p>
        </div>
        <div className=" space-y-3 flex flex-col   justify-center">
          <h1 className="text-center md:text-left  text-xl md:text-2xl lg:text-4xl  font-bold text-secondary ">
            $100K+
          </h1>
          <p className="text-center md:text-left text-sm md:text-base text-textColor break-words">
            Monthly Sale
          </p>
        </div>
      </div>
      <div className=" container my-12 text-center space-y-6">
        <p className="hidden md:block text-sm md:text-base font-medium text-textColor space-y-2">
          MK ADS DIGITAL BRINGS{" "}
          <span className=" text-secondary px-2">QUALITY TRAFFIC</span> THAT
          MAKES <br />
          <span className=" text-secondary px-2">CONVERSION</span>
          EASIER.
        </p>
        <p className="md:hidden text-sm md:text-base font-medium text-textColor space-y-2">
          MK ADS DIGITAL BRINGS{" "}
          <span className=" text-secondary px-2">QUALITY TRAFFIC</span> THAT
          MAKES
          <span className=" text-secondary px-2">CONVERSION</span>
          EASIER.
        </p>
        <div>
          <a
            href="http://mkadsdigital.com/"
            target="_blank"
            rel="noreferrer"
            className=" bg-secondary py-2 text-sm px-4 text-white rounded-md whitespace-pre
             shadow-[4px_4px_1px_1px_#000000] hover:shadow-[1px_1px_1px_1px_#000000] duration-300 border-t border-black border-l "
          >
            MKADSDIGITAL.COM
          </a>
        </div>
      </div>
    </div>
  );
};

export default NetWorkUpperComponent;
