import Tippy from "@tippyjs/react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { BiHappyAlt } from "react-icons/bi";
import { BsCheckLg, BsEye, BsEyeSlash, BsFillStarFill } from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { TfiReload } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import InterViewCommentTooltip from "../../Components/Admin/applications/InterViewCommentTooltip";
import ApplicationView from "../../Components/Admin/popup/ApplicationView";
import Loading from "../../Components/Loading/Loading";
import {
  changeVisitAPI,
  deleteApplicantsAPI,
  getApplicantsOfPostFilterAPI,
  updateNewCVAPI,
} from "../../api/applyJobAPI";

const ApplicationsPostwise = () => {
  const [totalApplication, setTotalApplication] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [query, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const { access_token: token } = useSelector((state) => state.auth);
  const [applicationView, setApplicationView] = useState({});
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(0);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteApplicantsAPI(id, token); // api call
        if (res && res.status === 200) {
          Swal.fire(res.data.message);
          setReload(reload + 1);
        }
      }
    });
  };

  const handleShow = async (item) => {
    let viewStatus = true;
    setApplicationView({ ...item });
    setShow(true);
    const res = await changeVisitAPI(item._id, token, { viewStatus }); // api call
    if (res && res.status === 200) {
      setReload(reload + 1);
    }
  };

  useEffect(() => {
    const updateNewCvToFalse = async () => {
      await updateNewCVAPI(token, {
        newApply: false,
      }); // api call
    };
    if (state?.newApply === true) {
      updateNewCvToFalse();
    }
  }, []);

  useEffect(() => {
    const getApplicantsOfPostFilter = async () => {
      setLoading(true);

      const res = await getApplicantsOfPostFilterAPI(
        token,
        query.get("jobID"),
        state?.newApply,
        state?.viewStatus,
        state?.cvShortList,
        state?.interviewCall,
        page
      );
      if (res && res.status === 200) {
        setLoading(false);
        setTotalApplication(res?.data?.resData[0]?.total[0]?.count);
        if (page === 1) {
          setData([...res?.data?.resData[0]?.data]);
        } else {
          setData([...data, ...res?.data?.resData[0]?.data]);
        }
      }
    };
    getApplicantsOfPostFilter();
  }, [reload, page]);

  // search functionality
  useEffect(() => {
    if (search) {
      navigate(`/admin/application?search=${search}`, { state: search });
    }
  }, [search]);

  // infinity scrolling
  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >=
  //     document.documentElement.scrollHeight
  //   ) {
  //     setPage((prev) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  console.log(data);
  return (
    <>
      {loading && <Loading loading={true} />}
      <div className="w-full mx-auto bg-white py-4 rounded-lg custom-font">
        <div className="container flex flex-col gap-y-2">
          <h1 className="text-lg font-semibold text-gray-600 flex items-center gap-x-2">
            <BsFillStarFill className="text-secondary" />
            {data[0]?.jobpostname}
          </h1>
          <div className={` flex flex-col gap-4 mt-4 `}>
            {data?.length !== 0 && (
              <div className="w-full h-full overflow-x-auto ">
                <table
                  className=" whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
                >
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                        Applicant
                      </th>
                      <th className="hidden md:block text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                        Application Date
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                        View
                      </th>
                      <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                        CV
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.map((item, index) => (
                        <tr key={index} className="border-t border-gray-300">
                          <td className="text-sm min-w-[250px] w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div className=" flex  gap-x-2">
                              <img
                                src={item?.photo?.url}
                                className="w-10 h-10 rounded-full ring ring-green-500 object-contain"
                                alt=""
                              />
                              <div className=" flex flex-col gap-2 ">
                                <div className=" ">
                                  <p className=" text-primaryColor font-medium flex items-center gap-1">
                                    {item.name}
                                  </p>
                                  <p className="text-xs">{item.email}</p>
                                </div>

                                <div className=" flex items-center gap-2 flex-wrap  ">
                                  {item.cvWaiting && (
                                    <span className="w-fit text-[10px] text-white bg-yellow-500 px-2 rounded-full flex items-center gap-1">
                                      <HiOutlineEmojiHappy
                                        size={16}
                                      ></HiOutlineEmojiHappy>
                                      Consideration
                                    </span>
                                  )}

                                  {item.cvShortList && (
                                    <span className="w-fit text-[10px] text-white bg-green-500 px-2 rounded-full flex items-center gap-1">
                                      <BiHappyAlt size={16}></BiHappyAlt>
                                      Shortlisted
                                    </span>
                                  )}
                                  {item.cvMark && item.cvMark > 0 ? (
                                    <span className="w-fit text-[10px] text-white bg-lime-500 px-2 rounded-full">
                                      cv mark: {item.cvMark}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.taskMark && item.taskMark > 0 ? (
                                    <span className="w-fit  text-[10px] text-white bg-violet-500 px-2 rounded-full">
                                      Task mark: {item.taskMark}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.interviewCall &&
                                  item.interviewCall > 0 ? (
                                    <span className="w-fit text-[10px] text-white bg-blue-500 px-2 rounded-full flex items-center gap-1">
                                      <BsCheckLg size={16} />
                                      interview Call {item.interviewCall}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.interViewMark &&
                                  item.interViewMark > 0 ? (
                                    <Tippy
                                      delay={300}
                                      placement="bottom-start"
                                      className="bg-[#a8a8a8] rounded-md p-1"
                                      content={
                                        <InterViewCommentTooltip
                                          interViewComment={
                                            item?.interViewComment
                                          }
                                        />
                                      }
                                    >
                                      <span className="w-fit text-[10px] text-white flex items-center bg-teal-400 px-2 rounded-full cursor-pointer">
                                        Interview mark: {item.interViewMark}
                                      </span>
                                    </Tippy>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hidden md:block text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            {moment(item.createdAt).format("LL")}
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div className="flex items-center gap-x-2 text-xl">
                              {item.viewStatus ? (
                                <BsEyeSlash
                                  onClick={() => {
                                    setApplicationView({ ...item });
                                    setShow(true);
                                  }}
                                  title="View"
                                  className={` cursor-pointer ${
                                    item.viewStatus
                                      ? "text-gray-400"
                                      : "text-green-500"
                                  }`}
                                  size={20}
                                />
                              ) : (
                                <BsEye
                                  onClick={() => handleShow(item)}
                                  title="View"
                                  className="text-green-500 cursor-pointer"
                                  size={20}
                                />
                              )}

                              {/* <RxCross2
                                title="Delete"
                                onClick={() => handleDelete(item._id)}
                                className="text-red-500 cursor-pointer"
                                size={20}
                              /> */}
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <a
                              href={item?.cv?.url}
                              download={`${item.name}.pdf`}
                              target="_blank"
                              className="text-center text-white px-2 py-1 border hover:border-primaryColor cursor-pointer rounded-md transition-all duration-[0.3s] hover:text-primaryColor bg-primaryColor hover:bg-transparent"
                              rel="noreferrer"
                            >
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {show && (
                  <ApplicationView
                    application={applicationView}
                    show={show}
                    setShow={setShow}
                    reload={reload}
                    setReload={setReload}
                  />
                )}
              </div>
            )}
            {loading && (
              <div className="w-full h-full overflow-x-auto ">
                <table
                  className=" whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
                >
                  <tbody>
                    {Array(5)
                      ?.fill()
                      ?.map((item, index) => (
                        <tr key={index} className="border-t border-gray-300">
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div className="flex items-center gap-x-2">
                              <div role="status" class="max-w-sm animate-pulse">
                                <svg
                                  class="text-gray-200 w-14 h-14 dark:text-gray-700"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                              <div>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    class="max-w-sm animate-pulse"
                                  >
                                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                  </div>
                                </p>
                                <p className="text-primaryColor font-medium flex items-center gap-1">
                                  <div
                                    role="status"
                                    class="max-w-sm animate-pulse"
                                  >
                                    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="text-sm hidden md:block w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div role="status" class="max-w-sm animate-pulse">
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div role="status" class="max-w-sm animate-pulse">
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                            </div>
                          </td>
                          <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                            <div role="status" class="max-w-sm animate-pulse">
                              <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {data?.length >= 10 && data?.length !== totalApplication && (
              <div
                className="w-full flex justify-center items-center"
                onClick={() => setPage(page + 1)}
              >
                <span
                  className={` cursor-pointer text-sm px-2 py-1 rounded-full bg-gray-500 text-white flex items-center gap-x-2 w-fit text-center`}
                >
                  <TfiReload /> Load More
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationsPostwise;
