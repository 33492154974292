import React, { useState } from "react";
import servicegif from "../../Assets/images/gifs/digital-marketing.gif";
import { digitalMarketing } from "../../Data/DigitalMarketingData";
const DigitalMarketing = ({ tab }) => {
  const [data] = useState(digitalMarketing);
  return (
    <div hidden={tab !== 3} className="transition-all duration-500">
      <div className=" flex items-center justify-between flex-wrap-reverse lg:flex-nowrap gap-6">
        <div className="mx-auto w-full lg:basis-[60%] flex flex-col   gap-y-12  md:gap-4">
          {data?.map((item, i) => (
            <div className="w-full flex  md:grid  md:grid-cols-5 flex-wrap  gap-4 ">
              <div
                className=" mx-auto md:mx-0 col-span-1 w-[250px] md:w-full   h-[125px]  shadow-[0px_4px_4px_#00000040]
           flex flex-col items-center justify-center gap-y-3 rounded-md text-center px-2  "
              >
                <img className=" w-12" src={item.img} alt="" />
                <p className=" text-textColor text-sm  w-full  ">
                  {item.title}
                </p>
              </div>
              <div
                className=" col-span-4  shadow-[0px_4px_4px_#00000040]
           flex  items-center  rounded-md text-center px-4 py-2 "
              >
                <p className=" w-full text-left">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className=" container  w-full lg:basis-[40%] md:ml-8">
          <img className="" src={servicegif} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
