import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: true,
};

export const menuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.view = !state.view;
    },
    showMenu: (state) => {
      state.view = true;
    },
    hideMenu: (state) => {
      state.view = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showMenu, hideMenu, toggleMenu } = menuSlice.actions;

export default menuSlice.reducer;
