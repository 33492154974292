import React from "react";
import { MdErrorOutline } from "react-icons/md";
const Error = () => {
  return (
    <div className="w-full px-4 py-6  flex justify-center items-center">
      <div className="flex flex-col items-center">
        <MdErrorOutline className="text-2xl text-red-500" />
        <h1 className="text-base font-normal text-red-500">
          Error! Something Went Wront!
        </h1>
      </div>
    </div>
  );
};

export default Error;
