import React from "react";
import Commonlayout from "../Components/layout/Commonlayout";
import error404 from "../Assets/images/error404.png";
const Error404 = () => {
  return (
    <Commonlayout>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-96">
          <img src={error404} className="w-full h-full object-cover " alt="" />
        </div>
      </div>
    </Commonlayout>
  );
};

export default Error404;
