import Tippy from "@tippyjs/react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiBlock, BiHappyAlt } from "react-icons/bi";
import { BsCheckLg, BsEye, BsEyeSlash } from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import InterViewCommentTooltip from "../../Components/Admin/applications/InterViewCommentTooltip";
import ApplicationView from "../../Components/Admin/popup/ApplicationView";
import Error from "../../error/Error";
import {
  useBlockExamAttemptUserMutation,
  useExamAttemptsUsersQuery,
} from "../../features/exam/examApiSlice";
import NoDataFound from "../../no-data/NoDataFound";

const ExamAttemptsUser = () => {
  const location = useLocation();
  const { pathname } = location;
  const [query] = useSearchParams();
  const jobName = query.get("jobName");
  const jobId = query.get("jobId");
  const { access_token } = useSelector((state) => state.auth);
  const [dataExamUser, setDataExamUser] = useState({});
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(0);
  const {
    data: dataUsers,
    isLoading,
    isSuccess,
    isError,
  } = useExamAttemptsUsersQuery(
    { access_token, jobName, jobId },
    { skip: pathname === "/admin/task-submited" }
  );

  const [
    blockExamAttemptUser,
    {
      isLoading: isLoadingBlock,
      isError: isErrorBlock,
      isSuccess: isSuccessBlock,
      error: errorBlock,
    },
  ] = useBlockExamAttemptUserMutation() || {};

  // const handleDelete = async (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const res = await deleteApplicantsAPI(id, access_token); // api call
  //       if (res && res.status === 200) {
  //         Swal.fire(res.data.message);
  //       }
  //     }
  //   });
  // };

  const handleShow = async (item) => {
    setDataExamUser({ ...item });
    setShow(true);
    // const res = await changeVisitAPI(item._id, access_token, { viewStatus }); // api call
  };

  // block a user who attend in the exam
  const handleBlockUser = (email, jobname, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Waiting Status update!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        blockExamAttemptUser({ access_token, email, jobname, id });
        // Swal.fire(`Waiting Status Update!`);
      }
    });
  };
  let content = null;
  if (isLoading && !isError) {
    content = (
      <>
        <tbody>
          {Array(5)
            ?.fill()
            ?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className="flex items-center gap-x-2">
                    <div role="status" class="max-w-sm animate-pulse">
                      <svg
                        class="text-gray-200 w-14 h-14 dark:text-gray-700"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-primaryColor font-medium flex items-center gap-1">
                        <div role="status" class="max-w-sm animate-pulse">
                          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                        </div>
                      </p>
                      <p className="text-primaryColor font-medium flex items-center gap-1">
                        <div role="status" class="max-w-sm animate-pulse">
                          <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                        </div>
                      </p>
                    </div>
                  </div>
                </td>
                <td className="hidden md:block text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-24"></div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  if (isSuccess && !isError && dataUsers?.attamtuser?.attampt?.length !== 0) {
    content = (
      <>
        <tbody>
          {dataUsers?.attamtuser?.attampt &&
            dataUsers?.attamtuser?.attampt?.map((item, index) => (
              <tr key={index} className="border-t border-gray-300">
                <td className="text-sm min-w-[250px] w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className=" flex  gap-x-2">
                    <img
                      src={item?.userDetails[0]?.photo?.url}
                      className="w-10 h-10 rounded-full ring ring-green-500 object-contain"
                      alt=""
                    />
                    <div className=" flex flex-col gap-2 ">
                      <div className=" ">
                        <p
                          className={`${
                            item?.attamptUserStatus === "Block" &&
                            "line-through"
                          } text-primaryColor font-medium flex items-center gap-1`}
                        >
                          {item?.attamptUserEmail}
                        </p>
                        <p
                          className={`text-xs ${
                            item?.attamptUserStatus === "Block" &&
                            "line-through"
                          }`}
                        >
                          {item?.attamptJobPostName}
                        </p>
                      </div>

                      {/*  */}
                      <div className=" flex items-center gap-2 flex-wrap  ">
                        {item?.userDetails[0]?.cvWaiting && (
                          <span className="w-fit text-[10px] text-white bg-yellow-500 px-2 rounded-full flex items-center gap-1">
                            <HiOutlineEmojiHappy
                              size={16}
                            ></HiOutlineEmojiHappy>
                            Consideration
                          </span>
                        )}

                        {item?.userDetails[0]?.cvShortList && (
                          <span className="w-fit text-[10px] text-white bg-green-500 px-2 rounded-full flex items-center gap-1">
                            <BiHappyAlt size={16}></BiHappyAlt>
                            Shortlisted
                          </span>
                        )}
                        {item?.userDetails[0]?.cvMark &&
                        item?.userDetails[0]?.cvMark > 0 ? (
                          <span className="w-fit text-[10px] text-white bg-lime-500 px-2 rounded-full">
                            cv mark: {item?.userDetails[0]?.cvMark}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.userDetails[0]?.taskMark &&
                        item?.userDetails[0]?.taskMark > 0 ? (
                          <span className="w-fit  text-[10px] text-white bg-violet-500 px-2 rounded-full">
                            Task mark: {item?.userDetails[0]?.taskMark}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.userDetails[0]?.interviewCall &&
                        item?.userDetails[0]?.interviewCall > 0 ? (
                          <span className="w-fit text-[10px] text-white bg-blue-500 px-2 rounded-full flex items-center gap-1">
                            <BsCheckLg size={16} />
                            interview Call {item?.userDetails[0]?.interviewCall}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.userDetails[0]?.interViewMark &&
                        item?.userDetails[0]?.interViewMark > 0 ? (
                          <Tippy
                            delay={300}
                            placement="bottom-start"
                            className="bg-[#a8a8a8] rounded-md p-1"
                            content={
                              <InterViewCommentTooltip
                                interViewComment={
                                  item?.userDetails[0]?.interViewComment
                                }
                              />
                            }
                          >
                            <span className="w-fit text-[10px] text-white flex items-center bg-teal-400 px-2 rounded-full cursor-pointer">
                              Interview mark:{" "}
                              {item?.userDetails[0]?.interViewMark}
                            </span>
                          </Tippy>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="hidden md:block text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  {moment(item?.createdAt).format("LLL")}
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <div className="flex items-center gap-x-2 text-xl">
                    {item?.viewStatus ? (
                      <BsEyeSlash
                        onClick={() => {
                          setDataExamUser({ ...item });
                          setShow(true);
                        }}
                        title="View"
                        className={` cursor-pointer ${
                          item.viewStatus ? "text-gray-400" : "text-green-500"
                        }`}
                        size={20}
                      />
                    ) : (
                      <BsEye
                        onClick={() => handleShow(item)}
                        title="View"
                        className="text-green-500 cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </td>
                <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                  <button
                    onClick={() =>
                      handleBlockUser(
                        item?.attamptUserEmail,
                        item?.attamptJobPostName,
                        item?.attamptJobPostID
                      )
                    }
                    className="flex items-center gap-1 px-2 py-1 text-xs rounded-sm bg-red-400 text-white"
                  >
                    Block <BiBlock />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
  if (isSuccess && !isError && dataUsers?.attamtuser?.attampt?.length === 0) {
    content = (
      <tbody>
        <tr>
          <td colSpan={4}>
            <NoDataFound />
          </td>
        </tr>
      </tbody>
    );
  }
  if (!isSuccess && isError) {
    content = (
      <>
        <tbody>
          <tr>
            <td colSpan={4}>
              <Error />
            </td>
          </tr>
        </tbody>
      </>
    );
  }

  // block exam attempt user
  // success message
  useEffect(() => {
    if (isSuccessBlock && !isErrorBlock) {
      toast.success("This candidate is blocked successfully!");
    }
  }, [isSuccessBlock, isErrorBlock]);

  // error message
  useEffect(() => {
    if (isErrorBlock && !isSuccessBlock) {
      toast.error(
        errorBlock?.data?.message || "This candidate is blocked failed!"
      );
    }
  }, [isErrorBlock, isSuccessBlock]);

  return (
    <>
      <div className="w-full mx-auto bg-white pb-4 rounded-lg">
        <div className="md:container flex flex-col gap-y-2">
          <div className={` flex flex-col gap-4 mt-4`}>
            <div className="w-full h-full overflow-x-auto ">
              <table
                className=" whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
              >
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Applicant
                    </th>
                    <th className="hidden md:block text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Exam Attempt Time
                    </th>
                    <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      View
                    </th>
                    <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                      Block User
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
              {show && (
                // <ExamAttemptUserModal
                //   dataExamUser={dataExamUser}
                //   show={show}
                //   setShow={setShow}
                // />
                <ApplicationView
                  application={dataExamUser?.userDetails[0]}
                  show={show}
                  setShow={setShow}
                  reload={reload}
                  setReload={setReload}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamAttemptsUser;
