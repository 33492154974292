import React, { useEffect, useState } from "react";
import { FaTrashAlt, FaUsers } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteTeamAPI, getAllTeamsAPI } from "../../api/teamAPI";

const AllTeams = () => {
  const [reload, setReload] = useState(0);
  const { access_token: token } = useSelector((state) => state.auth);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const getAllTeams = async () => {
      const res = await getAllTeamsAPI(); //api call
      if (res && res.status === 200) {
        setTeams([...res?.data?.teams]);
      }
    };
    getAllTeams();
  }, [reload]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteTeamAPI(id, token); // api call
        if (res && res.status === 200) {
          Swal.fire(res.data.message);
          setReload(reload + 1);
        }
      }
    });
  };

  return (
    <div className="container w-full mx-auto bg-white py-4 rounded-lg">
      <div className="flex justify-center items-center ">
        <h1 className="text-lg text-primaryColor font-semibold border-b flex gap-x-2 items-center">
          <FaUsers className="-mb-1" /> Teams
        </h1>
      </div>
      <div className="md:container flex flex-col gap-y-2">
        <div className={` flex flex-col gap-4 mt-4 `}>
          <div className="w-full h-full overflow-x-auto ">
            <table
              className="auto whitespace-nowrap w-full h-full p-[4rem]
         border-collapse rounded transition duration-700"
            >
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-sm py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Team
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Designation
                  </th>
                  <th className="text-sm  py-2 px-3 gap-x-0 text-primaryColor text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {teams &&
                  teams?.map((item, index) => (
                    <tr key={index} className="border-t border-gray-300">
                      <td className="text-sm min-w-[200px] w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        <div className="flex items-center gap-x-3">
                          <img
                            src={item?.photo?.url}
                            alt=""
                            className="w-10 h-10 rounded-full object-contain"
                          />

                          <h1 className="text-sm text-primaryColor font-semibold">
                            {item.name}
                          </h1>
                        </div>
                      </td>
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        {item.designation}
                      </td>
                      <td className="text-sm w-fit px-3 py-2 gap-x-0 text-textColor text-left">
                        <div className="flex items-center gap-x-2 text-xl">
                          <Link
                            to={`/admin/update-team/${item._id}`}
                            state={item}
                          >
                            <FiEdit
                              title="Edit"
                              className="text-green-400 cursor-pointer"
                              size={16}
                            />
                          </Link>
                          <FaTrashAlt
                            title="Delete"
                            onClick={() => handleDelete(item._id)}
                            className="text-red-400 cursor-pointer"
                            size={16}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTeams;
