import axios from "axios";
import { toast } from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;

export const createTeamAPI = async (data, token) => {
  try {
    return await axios.post(`${baseURL}/api/v1/createteam`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getAllTeamsAPI = async () => {
  try {
    return await axios.get(`${baseURL}/api/v1/getallteams`);
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateTeamAPI = async (id, data, token) => {
  try {
    return await axios.put(`${baseURL}/api/v1/updateteam/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteTeamAPI = async (id, token) => {
  try {
    return await axios.delete(`${baseURL}/api/v1/deleteteam/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json;charset=UTF-8",
      },
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
