import React from "react";
import aboutCompanyImg from "../../Assets/images/gifs/about_us.gif";
import rightImg from "../../Assets/images/gifs/rightGif.gif";
const AboutCompany = () => {
  return (
    <div className=" container my-6">
      <div className="flex  justify-between mt-16 flex-wrap-reverse md:flex-nowrap gap-2 gap-y-5">
        <div className=" space-y-4 w-full  md:basis-1/2 mt-6 mx-auto">
          <p className=" text-secondary">About Company</p>
          <h1 className=" text-2xl md:text-3xl  lg:text-4xl font-semibold text-textColor tracking-wide">
            We believe, one idea begets others, but one great idea stays
            stronger above all.{" "}
          </h1>
          <p className="  text-left text-base ">
            We provide AI-powered marketing-tech solutions. 360-degree solutions
            to simplify your business experience. Our solutions are
            purpose-driven, performance-oriented, and advised by respected
            industry experts. We stand out by launching the correct technology
            with proper communication integration.
          </p>
          <ul className="  text-base ">
            <li className=" flex items-center gap-4">
              <img className=" w-4" src={rightImg} alt="" />
              Product Engineering
            </li>
            <li className=" flex items-center gap-4">
              <img className=" w-4" src={rightImg} alt="" />
              IT Consultancy
            </li>
            <li className=" flex items-center gap-4">
              <img className=" w-4" src={rightImg} alt="" />
              Automated Software
            </li>
            <li className=" flex items-center gap-4">
              <img className=" w-4" src={rightImg} alt="" />
              Digital Services
            </li>
          </ul>
        </div>
        <div className=" w-full  md:basis-1/2 p-4 px-8  ">
          <img className=" w-full" src={aboutCompanyImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
