import React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const Search = ({ search, setSearch }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-16 p-4 flex justify-center md:justify-between">
      <div className="w-full md:w-96 bg-white flex items-center p-2 rounded-full border ">
        <AiOutlineSearch size={20} className="text-gray-500" />
        <input
          type="text"
          name="search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            e.target.value === "" && navigate("/admin");
          }}
          placeholder="Search Applicants"
          className="w-full text-xs outline-none border-none px-1 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default Search;
