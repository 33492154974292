import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DatePICK = ({ startDate, setStartDate, styleClass }) => {
  return (
    <DatePicker
      className={styleClass}
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
      }}
    />
  );
};

export default DatePICK;
