import BussinessERP from "../Assets/images/servicesicons/Asset 15.svg";
import BussinessHR from "../Assets/images/servicesicons/Asset 14.svg";
import BussinessRest from "../Assets/images/servicesicons/Asset 12.svg";
import BussinessSchool from "../Assets/images/servicesicons/Asset 11.svg";
export const bussinessPageData = [
  {
    img: BussinessERP,
    title: "ERP Software",
    description:
      "ERP systems support all aspects of financial management, human resources, supply chain management, and manufacturing with your core accounting function.",
  },
  {
    img: BussinessHR,
    title: "HR Software",
    description:
      "Recruitment, payroll, resource planning. At each step we make your process most user friendly and easier. It makes your HR process most efficient and achieve HR goals.",
  },
  {
    img: BussinessRest,
    title: "Restaurant Management",
    description:
      "The secret sauce to a restaurant’s success lies in analytics. Restaurant management is made easy by our food-tech solutions.",
  },
  {
    img: BussinessSchool,
    title: "School Software",
    description:
      "This software can automate and optimize teacher workload, helps schools to simplify enrollment processing, and enables teachers to monitor students’ academic progress. Corporate data management application",
  },
];
