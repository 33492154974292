import moment from "moment";

// exam duration as minutes
export const getExamFormattedTime = (starttime, endtime) => {
  const startTime = moment(starttime);
  const endTime = moment(endtime);
  const duration = moment.duration(endTime.diff(startTime));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  let timeFormat = "";
  if (days > 0) {
    timeFormat = `${days}d-${hours}h-${minutes}m-${seconds}s`;
  } else {
    timeFormat = `${hours}h-${minutes}m-${seconds}s`;
  }
  return timeFormat;
};
